import React from 'react'
import sortBy from 'lodash/sortBy'
import { AttributeType } from '../types'
import { entity_instance_hue } from '.'
import { NewRelationInstanceDialog, EditRelationInstanceDialog, useEntityInstanceIndex } from '.'
import { useObservable, watch } from '../Firebase'
import { data_collection_name, find_entity } from '../Schema'
import { DataTable, color } from '../UI'
import { prepAttribute, makeAttributeColumn } from './utils'

//--------------------------------------------------------------------------------------------------

export function RelationTable({ project, relation, entity_ids, instance }) {
  const subj_entity = find_entity(project, relation.subj_id)
  const subjs_index = useEntityInstanceIndex(project, subj_entity)

  const dobj_entity = find_entity(project, relation.dobj_id)
  const dobjs_index = useEntityInstanceIndex(project, dobj_entity)

  const data = useObservable(watch(data_collection_name(project.id, relation.id)), null)

  function subj_instance_name(relation_instance) {
    const subj_instance = subjs_index[relation_instance.subj_id]

    if (subj_entity && subj_instance) {
      return subj_instance._name
    }

    return `<instance ${relation_instance.subj_id}>`
  }

  function dobj_instance_name(relation_instance) {
    const dobj_instance = dobjs_index[relation_instance.dobj_id]

    if (dobj_entity && dobj_instance) {
      return dobj_instance._name
    }

    return `<instance ${relation_instance.dobj_id}>`
  }

  const attrs = (relation.attrs || []).filter(a => !a.hidden)

  let filtered = data || []
  if (entity_ids && instance) {
    if (entity_ids.includes(relation.subj_id)) {
      filtered = filtered.filter(r => r.subj_id === instance.id)
    } else if (entity_ids.includes(relation.dobj_id)) {
      filtered = filtered.filter(r => r.dobj_id === instance.id)
    }
  }

  const sort_attrs = []

  if (entity_ids) {
    sort_attrs.push(
      ...attrs
        .filter(a => a.is_order)
        .map(a => instance => instance[a.id] && Number(instance[a.id]))
    )

    if (entity_ids.includes(relation.subj_id)) {
      sort_attrs.push(dobj_instance_name)
    } else if (entity_ids.includes(relation.dobj_id)) {
      sort_attrs.push(subj_instance_name)
    }
    sort_attrs.push(...attrs.filter(a => !a.is_order).map(a => a.id))
  } else {
    sort_attrs.push(subj_instance_name, dobj_instance_name, ...attrs.map(a => a.id))
  }

  const sorted = sortBy(filtered, ...sort_attrs).map(r => ({
    ...r,
    subj: subjs_index[r.subj_id],
    dobj: dobjs_index[r.dobj_id]
  }))

  const cols = []
  if (!entity_ids || !entity_ids.includes(subj_entity.id)) {
    cols.push({
      label: subj_entity.name,
      cell: instance => subj_instance_name(instance),
      hue: subj_entity.hue,
      style: instance => ({ background: color(entity_instance_hue(project, instance.subj), 5, 3) })
    })
  }
  if (!entity_ids || !entity_ids.includes(dobj_entity.id) || entity_ids.includes(subj_entity.id)) {
    cols.push({
      label: dobj_entity.name,
      cell: instance => dobj_instance_name(instance),
      hue: dobj_entity.hue,
      style: instance => ({ background: color(entity_instance_hue(project, instance.dobj), 5, 3) })
    })
  }
  cols.push(
    ...attrs
      .filter(a => a.type !== AttributeType.Text)
      .map(prepAttribute)
      .map(makeAttributeColumn)
  )

  let empty

  if (entity_ids && instance) {
    if (entity_ids.includes(subj_entity.id)) {
      empty = { subj_id: instance.id }
    } else if (entity_ids.includes(dobj_entity.id)) {
      empty = { dobj_id: instance.id }
    }
  }

  return (
    <>
      <DataTable
        {...{ project }}
        type={relation}
        AddDialog={NewRelationInstanceDialog}
        addProps={{ project, type: relation, empty }}
        addTip="Create new relationship"
        EditDialog={EditRelationInstanceDialog}
        editProps={{ project, type: relation }}
        editTip="Edit this relationship"
        showTip="Show hidden relationship"
        hideTip="this relationship"
        cols={cols}
        objs={sorted}
        isData
      />
      {data ? null : <p>Loading...</p>}
    </>
  )
}
