import React, { useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import generate from 'nanoid/generate'

import { collectionData } from 'rxfire/firestore'
import pickBy from 'lodash/pickBy'
import mapValues from 'lodash/mapValues'
import { map } from 'rxjs/operators'

firebase.initializeApp({
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID
})

export default class Firebase {
  static Context = React.createContext(null)

  constructor() {
    // firebase.initializeApp({
    //   apiKey: process.env.API_KEY,
    //   authDomain: process.env.AUTH_DOMAIN,
    //   databaseURL: process.env.DATABASE_URL,
    //   projectId: process.env.PROJECT_ID,
    //   storageBucket: process.env.STORAGE_BUCKET,
    //   messagingSenderId: process.env.MESSAGING_SENDER_ID
    // })

    /* Helper */

    this.fieldValue = firebase.firestore.FieldValue
    this.emailAuthProvider = firebase.auth.EmailAuthProvider

    /* Firebase APIs */

    this.auth = firebase.auth()
    this.db = firebase.firestore()
    // this.db.settings({ timestampsInSnapshots: true })

    /* Social Sign In Method Provider */

    // this.googleProvider = new app.auth.GoogleAuthProvider()
    // this.facebookProvider = new app.auth.FacebookAuthProvider()
    // this.twitterProvider = new app.auth.TwitterAuthProvider()
  }

  makeID() {
    return generate('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 16)
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  // doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider)

  // doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider)

  // doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider)

  doSignOut = () => this.auth.signOut()

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email)

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      // url: 'http://localhost:8080'
      url: 'https://clrvsn-two-alpha.firebaseapp.com/home'
    })

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password)

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        // console.log(authUser)
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data()

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = []
            }

            // merge auth and db user
            authUser = {
              id: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser
            }

            next(authUser)
          })
      } else {
        fallback && fallback()
      }
    })

  // *** User API ***

  user = id => this.db.doc(`users/${id}`)

  users = () => this.db.collection('users')

  // *** Project API ***

  project = id => this.db.doc(`projects/${id}`)

  project_data = (pid, eid) =>
    this.db
      .collection('projects')
      .doc(pid)
      .collection('data-' + eid)

  // projects = () => this.db.collection('projects')

  // user_projects = uid => this.db.collection('projects').where('owner_id', '==', uid)
}

export function useFirebase() {
  return useContext(Firebase.Context)
}

// export function useCollection(name, where) {
//   const firebase = useFirebase()
//   const [loading, setLoading] = useState(false)
//   const [list, setList] = useState([])
//   const [index, setIndex] = useState({})

//   let collection = firebase.db.collection(name)

//   if (where) {
//     collection = collection.where(...where)
//   }

//   useEffect(() => {
//     setLoading(true)

//     return collection.onSnapshot(snapshot => {
//       // const changes = snapshot.docChanges()
//       // console.log(changes)
//       const list = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
//       // console.log(name, list)
//       const index = {}
//       list.forEach(doc => (index[doc.id] = doc))

//       setLoading(false)
//       setList(list)
//       setIndex(index)
//     })
//   }, [])

//   return [list, loading, index]
// }

export function useDatum(coll, id) {
  const firebase = useFirebase()
  const [loading, setLoading] = useState(false)
  const [datum, setDatum] = useState()

  let doc = firebase.db.collection(coll).doc(id)

  useEffect(() => {
    setLoading(true)

    return doc.onSnapshot(snapshot => {
      setLoading(false)
      setDatum({ ...snapshot.data(), id })
    })
  }, [])

  return [datum, loading, doc.update.bind(doc)]
}

//--------------------------------------------------------------------------------------------------

export const db = firebase.firestore()
export const NULL = firebase.firestore.FieldValue.delete()

export { combineLatest } from 'rxjs'

export function findAndUpdate(coll, datum) {
  const { id, ...data } = datum
  const doc = db.collection(coll).doc(id)
  doc.update(data)
}

/**
 * Initiate creation of a new document
 * @param {string} coll Collection name
 * @param {Object.<string, any>} obj Document fields
 */
export function create(coll, obj) {
  return db.collection(coll).add(pickBy(obj, v => !!v))
}

/**
 * Initiate update of a document
 * @param {string} coll Collection name
 * @param {string} id Document ID
 * @param {Object.<string, any>} obj Updated fields
 */
export function update(coll, id, obj) {
  return db
    .collection(coll)
    .doc(id)
    .update(mapValues(obj, v => v || NULL))
}

/**
 * Initiate update of a document
 * @param {string} coll Collection name
 * @param {string} id Document ID
 */
export function erase(coll, id) {
  return db
    .collection(coll)
    .doc(id)
    .delete()
}

/**
 * View a Collection as an Observable
 * @param {string} coll Collection name
 */
export function watch(coll) {
  const query = db.collection(coll)
  return collectionData(query, 'id')
}

/**
 * Create observable map of ID-able items in an observable array
 * @param {import("rxjs").Observable<T[]>} item$ Observable array
 * @returns {import("rxjs").Observable<{[string]: T}>}
 */
export function index_of(item$) {
  return item$.pipe(
    map(items => {
      const index = {}
      for (let item of items) {
        if (!item.hidden) {
          index[item.id] = item
        }
      }
      return index
    })
  )
}

/**
 * React hook for subsribing to an Observable
 * @template T
 * @param {import("rxjs").Observable<T> | import("rxjs").OperatorFunction<any, any>} value$
 * @param {T} initial_value
 */
export function useObservable(value$, initial_value) {
  const [value, setValue] = useState(initial_value)

  useEffect(() => {
    const subscription = value$.subscribe(new_value => setValue(new_value))
    return () => subscription.unsubscribe()
  }, [])

  return value
}
