import React, { useState } from 'react'
import useRouter from 'use-react-router'
import { Button, FormInput, FormButtons, FormError, FormPassword, Panel } from '../UI'

import { useFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

//------------------------------------------------------------------------------

export function SignInForm() {
  const firebase = useFirebase()
  const { history } = useRouter()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null) //{message:'An account with this E-Mail address already exists. Try to login with that account instead.'})

  const isInvalid = password === '' || email === ''

  return (
    <Panel>
      <FormInput
        label="Email Address"
        info="(required)"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />

      <FormPassword value={password} onChange={setPassword} />

      <FormButtons>
        <Button disabled={isInvalid} onClick={submit}>
          Sign In
        </Button>
      </FormButtons>

      <FormError error={error ? error.message : null} />
    </Panel>
  )

  function clear() {
    setEmail('')
    setPassword('')
    setError(null)
  }

  function submit(event) {
    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        clear()
        history.push(ROUTES.HOME)
      })
      .catch(error => {
        setError(error)
      })

    event.preventDefault()
  }
}
