// // @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '../Parts'
import { AttributesUI } from '.'
import { PickHue, Verb, Desc, CancelOrSave, EntityID, FormError } from '../UI'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for editing a relation
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {import("../types").Updater} props.update
 * @param {import("../types").Relation} props.object
 * @param {(open:boolean)=>void} props.setOpen
 */

export function EditRelationDialog({ project, update, object, setOpen }) {
  const [error, setError] = useState(null)
  const [subj_id, setSubjID] = useState(object.subj_id)
  const [verb, setVerb] = useState(object.verb)
  const [dobj_id, setDobjID] = useState(object.dobj_id)
  const [desc, setDesc] = useState(object.desc || null)
  const [hue, setHue] = useState(object.hue || null)
  const [attrs, setAttrs] = useState(object.attrs || [])

  const isInvalid = subj_id.length < 1 || verb.length < 1 || dobj_id.length < 1

  return (
    <Dialog
      title="Edit Type of Relationship"
      icon="edit"
      isOpen={true}
      onClose={close}
      canEscapeKeyClose={false}
    >
      <DialogBody>
        <EntityID label="From Entity" id={subj_id} setID={setSubjID} entities={project.entities} />
        <Verb value={verb} setValue={setVerb} />
        <EntityID label="To Entity" id={dobj_id} setID={setDobjID} entities={project.entities} />
        <Desc value={desc} setValue={setDesc} />
        <PickHue value={hue} setValue={setHue} />
        <AttributesUI value={attrs} setValue={setAttrs} showIsOrder />
      </DialogBody>

      <DialogFooter>
        <CancelOrSave isInvalid={isInvalid} onCancel={close} onSave={save} />
        <FormError error={error} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    object.subj_id = subj_id
    object.verb = verb
    object.dobj_id = dobj_id
    object.desc = desc
    object.hue = hue
    object.attrs = attrs
    update({ relations: project.relations })
      .then(close)
      .catch(setError)
  }
}

//--------------------------------------------------------------------------------------------------
