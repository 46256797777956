import React, { useRef } from 'react'
import styled from 'styled-components'
import { CubicBezierCurve3, Vector3, BufferGeometry } from 'three'
import { Canvas, useFrame, extend, useThree } from 'react-three-fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { hierarchy, pack } from 'd3-hierarchy'

extend({ OrbitControls })

//--------------------------------------------------------------------------------------------------

function Controls() {
  const ref = useRef()
  const { camera, gl } = useThree()
  useFrame(() => ref.current && ref.current.update())
  return <orbitControls ref={ref} args={[camera, gl.domElement]} />
}

//--------------------------------------------------------------------------------------------------

function Lights() {
  return (
    <>
      <ambientLight intensity={0.1} />
      <directionalLight color="white" intensity={0.425} position={[5, 2, 6]} name="Back Light" />
      <directionalLight color="white" intensity={0.8575} position={[-4, -2, 0]} name="Key Light" />
      <directionalLight color="white" intensity={0.895} position={[6, 6, 4]} name="Fill Light" />
    </>
  )
}

//--------------------------------------------------------------------------------------------------

const CanvasHolder = styled.div`
  background: white;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
`

/**
 * @param {object} props
 * @param {number} props.size
 * @param {number} props.sep
 * @param {any[][]} props.layers
 * @param {number} props.relations

 * @param {any} props.discColor
 * @param {any} props.discOver
 * @param {any} props.discLeave
 * @param {any} props.discClick
 * @param {any} props.linkColor
 */

export function DiscsDiagram({
  size,
  sep = 3,
  layers = [],
  relations = [],
  discColor,
  discOver,
  discLeave,
  discClick,
  linkColor
}) {
  const y_max = ((layers.length - 1) * sep) / 2
  const packings = layers.map((layer, i) => pack_discs(layer, y_max - i * sep))
  const linkings = relations.map((relation, i) => [
    make_links(relation.type, relation.instances, packings[i], packings[i + 1])
  ])

  return (
    <>
      <CanvasHolder size={size}>
        <Canvas camera={{ fov: 41, position: [0, 4, 12] }}>
          <Controls />
          <Lights />

          {packings.map((packing, index) => (
            <PackedDiscsLayer
              key={packing.id}
              {...{ index, packing, discColor, discOver, discLeave, discClick }}
            />
          ))}

          {linkings.map((linkage, i) =>
            linkage.map((links, j) =>
              (links.curves || []).map(link => {
                const geometry = new BufferGeometry().setFromPoints(link.points)
                return (
                  <line key={`${i}:${j}:${link.id}`} {...{ geometry }}>
                    <lineBasicMaterial attach="material" {...linkColor(link, i)} />
                  </line>
                )
              })
            )
          )}
        </Canvas>
      </CanvasHolder>
    </>
  )
}

//--------------------------------------------------------------------------------------------------

const SIZE = 8
const UNIT = 0.12

/**
 * @param {object[]} layer
 * @param {number} y
 */

function pack_discs(layer, y) {
  const d3_root = hierarchy({
    level: 0,
    name: 'Name',
    children: layer,
    levels: 1
  })
  d3_root.sum(() => 1)
  d3_root.sort()

  const d3_layout = pack()
    .size([SIZE, SIZE])
    .padding(UNIT / 2)
  d3_layout(d3_root)

  return d3_root
    .descendants()
    .filter(node => node.data != null)
    .map(node => ({
      id: node.data.id,
      x: node.x - SIZE / 2,
      y,
      z: node.y - SIZE / 2,
      radius: node.r,
      datum: node.data,
      depth: node.depth
    }))
}

//--------------------------------------------------------------------------------------------------

function make_links(relation_type, relation_instances, source_discs, target_discs) {
  return {
    id: relation_type.id,
    curves: (relation_instances || [])
      .map(instance => {
        const src = source_discs.find(disc => disc.id === instance.subj_id)
        const tgt = target_discs.find(disc => disc.id === instance.dobj_id)

        if (src && tgt) {
          const y_mid = tgt.y + (src.y - tgt.y) / 2
          const curve = new CubicBezierCurve3(
            new Vector3(src.x, src.y - UNIT / 2, src.z),
            new Vector3(src.x, y_mid, src.z),
            new Vector3(tgt.x, y_mid, tgt.z),
            new Vector3(tgt.x, tgt.y + UNIT / 2, tgt.z)
          )
          const points = curve.getPoints(30)

          return {
            id: `${src.id}:${tgt.id}`,
            datum: instance,
            points
          }
        }

        return null
      })
      .filter(link => !!link)
  }
}

//--------------------------------------------------------------------------------------------------

function PackedDiscsLayer({ index, packing, discColor, discOver, discLeave, discClick }) {
  return (packing || [])
    .filter(({ id, depth }) => id != null && depth > 0)
    .map(({ id, x, y, z, radius, datum, depth }, i) => (
      <PackedDisc
        key={`${x}:${y}:${z}`}
        position={[x, y, z]}
        {...{ radius, datum, index, depth, discColor, discOver, discLeave, discClick }}
      />
    ))
}

//--------------------------------------------------------------------------------------------------

function PackedDisc({
  index,
  datum,
  radius,
  height,
  depth,
  discColor,
  discOver,
  discLeave,
  discClick,
  ...props
}) {
  // const mesh = useRef()

  return (
    <mesh
      {...props}
      // ref={mesh}
      scale={[1, 1, 1]}
      onClick={e => {
        e.stopPropagation()
        discClick && discClick(datum, index)
      }}
      onPointerMove={e => {
        e.stopPropagation()
        discOver && discOver({ x: e.clientX, y: e.clientY }, datum)
      }}
      onPointerLeave={e => discLeave && discLeave()}
    >
      <cylinderBufferGeometry attach="geometry" args={[radius, radius, UNIT, 32]} />
      <meshStandardMaterial attach="material" {...discColor(datum, index, depth)} />
    </mesh>
  )
}
