import React, { useState } from 'react'
import { Node } from './Node'
import { Link } from './Link'
import { IsA } from './IsA'
import { mk_arrow_def } from './mk_arrow_def'

//--------------------------------------------------------------------------------------------------

export function SchemaGraph({
  project,
  simulation,
  nodes,
  links,
  // entities,
  // relations,
  width,
  height,
  selection_id,
  setSelectionID,
  // instances_id,
  setInstancesID,
  scale,
  setScale,
  pan,
  setPan
  // update
}) {
  // const [project, index, update] = useDatum('projects', )
  // const [simulation, setSimulation] = useState()
  const [ticks, setTicks] = useState(0)
  const [mouse_down, setMouseDown] = useState(false)
  const [dragging, setDragging] = useState(false)
  const [start, setStart] = useState({ x: 0, y: 0 })
  // const [nodes, setNodes] = useState([])
  // const [links, setLinks] = useState([])

  if (simulation) {
    simulation.on('tick', () => {
      // console.log('tick:', ticks + 1)
      setTicks(ticks + 1)
    })
  }

  //------------------------------------------------------------------------------------------------

  const link_index = {}
  for (let link of links) {
    link_index[link.id] = link
  }

  const relation_symbols = nodes
    .filter(node => node.label === 'relation' && node.verb !== 'isa')
    .map(node => (
      <g key={node.id} className="relation">
        <Link link={node.link1} selected={node.id === selection_id} />
        <Link link={node.link2} selected={node.id === selection_id} />
        <Node
          node={node}
          r={8}
          onDragStart={onNodeDragStart}
          onDrag={onNodeDrag}
          onDragEnd={onNodeDragEnd}
          onClick={onNodeClick}
          selection_id={selection_id}
        />
      </g>
    ))

  const isa_symbols = links
    .filter(link => link.verb === 'isa')
    .map(link => <IsA key={link.id} link={link} />)

  const entity_symbols = nodes
    .filter(node => node.label === 'entity')
    .map(node => (
      <Node
        key={node.id}
        node={node}
        r={25}
        onDragStart={onNodeDragStart}
        onDrag={onNodeDrag}
        onDragEnd={onNodeDragEnd}
        onClick={onNodeClick}
        selection_id={selection_id}
      />
    ))

  // const w2 = 100 //width // 2
  // const h2 = 100 //height // 2
  // // const axes = (
  //   <g stroke="magenta">
  //     <line x1={-w2} y1={0} x2={w2} y2={0} />
  //     <line x1={0} y1={-h2} x2={0} y2={h2} />
  //   </g>
  // )

  //------------------------------------------------------------------------------------------------

  // https://blog.rapid7.com/2016/05/25/building-svg-maps-with-react/
  const matrix = [scale, 0, 0, scale, width / 2 + pan[0], height / 2 + pan[1]]

  return (
    <svg
      style={{ background: 'white' }}
      width={width}
      height={height}
      onMouseDown={onDragStart}
      onTouchStart={onDragStart}
      onMouseMove={onDragMove}
      onTouchMove={onDragMove}
      onMouseUp={onDragEnd}
      onTouchEnd={onDragEnd}
      onWheel={onWheel}
    >
      <defs>
        {mk_arrow_def(40)}
        {mk_arrow_def(40, 'isa-arrow')}
      </defs>
      <g transform={`matrix(${matrix.join(' ')})`}>
        {/* {axes} */}
        {isa_symbols}
        {relation_symbols}
        {entity_symbols}
      </g>
    </svg>
  )

  //------------------------------------------------------------------------------------------------

  function onWheel(e) {
    e.preventDefault()
    e.stopPropagation()

    if (e.deltaY < 0) {
      setScale(scale * 1.1)
    } else {
      setScale(scale * 0.9)
    }
  }

  function onDragStart(e) {
    // Find start position of drag based on touch/mouse coordinates.
    const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX
    const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY

    setStart({ x, y })
    setMouseDown(true)
  }

  function onDragMove(e) {
    if (!mouse_down) {
      return
    }

    setDragging(true)

    // Get the new x and y coordinates
    const x = typeof e.clientX === 'undefined' ? e.changedTouches[0].clientX : e.clientX
    const y = typeof e.clientY === 'undefined' ? e.changedTouches[0].clientY : e.clientY

    // Take the delta where we are minus where we came from.
    const dx = x - start.x
    const dy = y - start.y

    // Pan using the deltas
    setPan([pan[0] + dx, pan[1] + dy])

    // Update the new startX and startY position
    // because a drag is likely a continuous movement
    setStart({ x, y })
  }

  function onDragEnd(e) {
    if (dragging) {
      setDragging(false)
    } else {
      setSelectionID(null)
      setInstancesID(null)
    }
    setMouseDown(false)
  }

  // https://bl.ocks.org/mbostock/2675ff61ea5e063ede2b5d63c08020c7
  function onNodeDragStart(e, node) {
    // console.log('onNodeDragStart', node)
    simulation.alpha(0.3).restart()
    node.fx = node.x
    node.fy = node.y
  }

  function onNodeDrag(e, node, dx, dy) {
    node.fx -= dx / matrix[0]
    node.fy -= dy / matrix[0]
  }

  function onNodeDragEnd(e, node) {
    saveNode(node)
    // simulation.alpha(0)
    simulation
      .alpha(0.3)
      .alphaTarget(0)
      .restart()
  }

  function onNodeClick(e, node) {
    // console.log('onNodeClick', node)
    e.preventDefault()
    e.stopPropagation()

    if (e.shiftKey) {
      if (node.fx != null) {
        node.fx = null
        node.fy = null
        simulation
          .alpha(0.3)
          .alphaTarget(0)
          .restart()
      } else {
        node.fx = node.x
        node.fy = node.y
      }
      saveNode(node)
    } else if (e.ctrlKey) {
      setInstancesID(node.id)
    } else {
      setSelectionID(node.id)
    }
  }

  function saveNode(node) {
    if (node.label === 'entity') {
      const entity = project.entities.find(e => e.id === node.id)
      entity.fx = node.fx
      entity.fy = node.fy
      // update({ entities })
    } else {
      const relation = project.relations.find(r => r.id === node.id)
      relation.fx = node.fx
      relation.fy = node.fy
      // update({ relations })
    }
  }
}
