import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//--------------------------------------------------------------------------------------------------

interface IconProps {
  icon: string
  color?: string
  small?: boolean
  big?: boolean
  padding?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export function Icon({ icon, color, small, big, padding, onClick }: IconProps) {
  // const { className, styles } = css.resolve`
  //   svg {
  //     ${padding ? `padding: ${padding};` : ''}
  //   }
  // `
  let qual_icon = ['fas', icon]

  if (icon.includes('.')) {
    qual_icon = icon.split('.')
  }

  return (
    <>
      <FontAwesomeIcon
        {...{ color }}
        icon={qual_icon}
        // size={small ? 'xs' : big ? '' : 'sm'}
        size={small ? 'sm' : big ? 'lg' : undefined}
        // fixedWidth
        onClick={onClick}
      />
      {/* {styles} */}
    </>
  )
}
