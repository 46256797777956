import React from 'react'
import styled from 'styled-components'
import { Row, gray } from '.'

//------------------------------------------------------------------------------

const Outside = styled.div`
  border-radius: 4px;
  background: white;
  width: 20px;
  height: 20px;
  transition: 0.3s;
  cursor: pointer;
`

const Toggle = styled.div<{ checked: boolean }>`
  background: ${() => gray(3)};
  height: 12px;
  width: 12px;
  margin: 4px;
  border-radius: 2px;
  visibility: ${p => (p.checked ? 'visible' : 'hidden')};
  transition: 0.3s;
`

const Label = styled.div<{ right?: boolean }>`
  ${p => (p.right ? 'margin-right' : 'margin-left')}: 5px;
  cursor: pointer;
`

interface FormSwitchProps {
  checked: boolean
  label: string
  full?: boolean
  right?: boolean
  onChange: (value: boolean) => void
}

export function FormSwitch({ label, checked, onChange, full, right }: FormSwitchProps) {
  return (
    <Row padding="2px 0 10px 0" width={full ? '100%' : ''} onClick={() => onChange(!checked)}>
      {right ? (
        <>
          <Label right>{label}</Label>
          <Outside>
            <Toggle checked={checked} />
          </Outside>
        </>
      ) : (
        <>
          <Outside>
            <Toggle checked={checked} />
          </Outside>
          <Label>{label}</Label>
        </>
      )}
    </Row>
  )
}
