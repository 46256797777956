import type { Hue } from '../UI/types'

export type ID = string

export enum AttributeType {
  String = 'string',
  Text = 'text',
  Date = 'date',
  Bool = 'bool',
  Choice = 'enum'
}

export enum AttributeStruct {
  One = 'one',
  Set = 'set',
  List = 'list'
}

export interface PlainAttribute {
  id: ID
  name: string
  type: AttributeType
  struct: AttributeStruct
  desc?: string
  is_name?: boolean
  is_order?: boolean
  is_required?: boolean
  is_sorter?: boolean
  choices?: string[]
  hidden?: boolean
}

export type Updater = (obj: { [field: string]: any }) => Promise<any> // ???

export interface PlainEntity {
  id: ID
  name: string
  desc?: string
  hue?: Hue
  attrs?: PlainAttribute[]
  isa_ids: ID[]
  hidden?: boolean
}

export interface PlainRelation {
  id: ID
  verb: string
  subj_id: ID
  dobj_id: ID
  desc?: string
  hue?: Hue
  attrs?: PlainAttribute[]
  hidden?: boolean
}

export interface PlainProject {
  id: ID
  name: string
  owner_id: ID
  desc?: string
  collab_ids?: ID[]
  entities?: PlainEntity[]
  relations?: PlainRelation[]
  // queries?: PlainQuery[]
}
