import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'

//--------------------------------------------------------------------------------------------------
// https://blog.logrocket.com/learn-react-portals-by-example/

export function Portal({ children }: { children: React.ReactNode }) {
  const mount = document.getElementById('portal-1')
  const el = document.createElement('div')

  useEffect(() => {
    if (mount) {
      mount.appendChild(el)
      return () => {
        mount.removeChild(el)
      }
    }
  }, [el, mount])

  return createPortal(children, el)
}
