import React from 'react'
import styled from 'styled-components'
import sortBy from 'lodash/sortBy'
import ReactMarkdown from 'react-markdown'
import { AttributeType } from '../types'
import { useObservable, watch, index_of } from '../Firebase'
import { entity_instance_name, entity_instance_name_attrs, RelationTable } from '.'
import {
  data_collection_name,
  entity_attrs,
  relation_name,
  entity_relations,
  entity_supertypes
} from '../Schema'
import { Col } from '../UI'

//--------------------------------------------------------------------------------------------------

const Heading = styled.h3`
  margin: 2px 0 8px 0;
  height: 28px;
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Info = styled.div`
  background: white;
  min-width: 400px;
  padding: 4px;
  max-height: 800px;
  overflow-y: scroll;

  b {
    font-size: 16px;
    margin-bottom: 4px;
    display: block;
  }
`

const Label = styled.td`
  vertical-align: top;
  font-weight: bold;
  padding-right: 2px;
`

const Value = styled.td`
  vertical-align: top;

  ul,
  ol {
    padding-inline-start: 20px;
    margin-top: 0;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 5px;
  }
`

const Relation = styled.p`
  p {
    margin: 10px 2px 0 2px;
  }
`

//--------------------------------------------------------------------------------------------------

export function EntityInstanceInfo({ project, entity, id }) {
  const index = useObservable(index_of(watch(data_collection_name(project.id, entity.id))), null)

  const relations = sortBy(entity_relations(project, entity), r => relation_name(project, r))
  const entity_ids = [entity.id, ...entity_supertypes(project, entity).map(e => e.id)]

  if (index == null) {
    return 'Loading...'
  }

  const instance = index[id]

  if (instance == null) {
    return 'No data available.'
  }

  const attrs = entity_attrs(project, entity).filter(a => !a.hidden)

  function fmt(a) {
    switch (a.type) {
      case AttributeType.Bool:
        return <p>{instance[a.id] ? 'true' : ''}</p>
      case AttributeType.Text:
        return <ReactMarkdown source={instance[a.id]} />
      default:
        return <p>{instance[a.id]}</p>
    }
  }

  return (
    <Col>
      <Heading>
        {entity_instance_name(entity, instance, entity_instance_name_attrs(project, entity))}
      </Heading>

      <Info>
        {attrs.length > 0 ? (
          <table>
            <tbody>
              {attrs.map(a => (
                <tr key={a.id}>
                  <Label>{a.name}:</Label>
                  <Value>{fmt(a)}</Value>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}

        {relations.map(r => (
          <Relation key={r.id}>
            <b>{relation_name(project, r)}</b>
            <RelationTable
              project={project}
              relation={r}
              entity_ids={entity_ids}
              instance={instance}
            />
          </Relation>
        ))}
      </Info>
    </Col>
  )
}
