// @ts-check

import React from 'react'
import { EntityTable } from '../Data/EntityTable'
import { RelationTable } from '../Data/RelationTable'
import { Dialog } from '@blueprintjs/core'
import { DialogBody } from '../Parts'
import { entity_name } from '../Schema'

//--------------------------------------------------------------------------------------------------

/**
 * A popup showing instances
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {string} props.instances_id
 * @param {(open:boolean)=>void} props.setOpen
 */

export function DataModal({ project, instances_id, setOpen }) {
  const entity = (project.entities || []).find(e => e.id == instances_id)
  const relation = (project.relations || []).find(r => r.id == instances_id)

  const title = entity
    ? `Instances of ${entity.name}`
    : relation
    ? `Instances of ${entity_name(project, relation.subj_id)} ${relation.verb} ${entity_name(
        project,
        relation.dobj_id
      )}`
    : ''

  return (
    <Dialog title={title} isOpen={true} onClose={close}>
      <DialogBody>
        {entity ? (
          <EntityTable key={entity.id} project={project} entity={entity} />
        ) : relation ? (
          <RelationTable key={relation.id} project={project} relation={relation} />
        ) : null}
      </DialogBody>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }
}
