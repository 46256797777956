import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { update } from '../Firebase'
import { DialogBody, DialogFooter, Error } from '../Parts'
import { CancelOrSave } from '../UI'
import { AttributeValueUI } from '../Schema/AttributeValueUI'
import { data_collection_name, entity_attrs } from '../Schema'

//--------------------------------------------------------------------------------------------------

export function EditEntityInstanceDialog({ project, type, object, setOpen }) {
  const [error, setError] = useState(null)
  const [data, updateData] = useState(object)

  function setData(key, value) {
    updateData(data => ({ ...data, [key]: value }))
  }

  let isInvalid = false

  const attrs = entity_attrs(project, type).filter(a => !a.hidden)

  for (const attr of attrs) {
    const value = data[attr.id]
    if (attr.is_required && (value == null || value.length < 1)) {
      isInvalid |= true
    }
  }

  return (
    <Dialog title={'Edit ' + type.name} icon="edit" isOpen={true} onClose={close} usePortal>
      <DialogBody>
        {attrs.map(attr => (
          <AttributeValueUI key={attr.id} attr={attr} data={data} setData={setData} />
        ))}
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrSave isInvalid={isInvalid} onCancel={close} onSave={save} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    update(data_collection_name(project.id, type.id), object.id, data)
      .then(close)
      .catch(setError)
  }
}
