import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
// import '@blueprintjs/icons/lib/css/blueprint-icons.css'
// import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
// import '@blueprintjs/table/lib/css/table.css'
import '@blueprintjs/select/lib/css/blueprint-select.css'
// import '@blueprintjs/timezone/lib/css/blueprint-timezone.css'
import './index.css'
import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(far)
library.add(fas)

//--------------------------------------------------------------------------------------------------
// https://github.com/facebook/react/issues/14856

const EVENTS_TO_MODIFY = ['touchstart', 'touchmove', 'touchend', 'touchcancel', 'wheel']

const originalAddEventListener = document.addEventListener.bind()
document.addEventListener = (type, listener, options, wantsUntrusted) => {
  let modOptions = options
  if (EVENTS_TO_MODIFY.includes(type)) {
    if (typeof options === 'boolean') {
      modOptions = {
        capture: options,
        passive: false
      }
    } else if (typeof options === 'object') {
      modOptions = {
        passive: false,
        ...options
      }
    }
  }

  return originalAddEventListener(type, listener, modOptions, wantsUntrusted)
}

const originalRemoveEventListener = document.removeEventListener.bind()
document.removeEventListener = (type, listener, options) => {
  let modOptions = options
  if (EVENTS_TO_MODIFY.includes(type)) {
    if (typeof options === 'boolean') {
      modOptions = {
        capture: options,
        passive: false
      }
    } else if (typeof options === 'object') {
      modOptions = {
        passive: false,
        ...options
      }
    }
  }
  return originalRemoveEventListener(type, listener, modOptions)
}

//--------------------------------------------------------------------------------------------------
import whyDidYouRender from '@welldone-software/why-did-you-render'

if (process.env.NODE_ENV === 'development') {
  whyDidYouRender(React)
}

import Firebase from './components/Firebase'
import App from './components/App'

ReactDOM.render(
  <Firebase.Context.Provider value={new Firebase()}>
    <App />
  </Firebase.Context.Provider>,
  document.getElementById('root')
)
