import React from 'react'
import styled from 'styled-components'
import { FormItem } from '.'

//------------------------------------------------------------------------------

export interface SelectOption {
  label: string
  value: string
}

const Select = styled.select<{ width?: string }>`
  border-radius: 4px;
  border: none;
  outline: none;
  padding: 4px;
  ${p => (p.width ? `width: ${p.width}` : '')};
`

interface FormSelectProps {
  label: string
  value: string
  options: SelectOption[]
  onChange: (value: string) => void
  info?: string
  help?: string
  width?: string
}

export function FormSelect<T>({
  label,
  value,
  options,
  onChange,
  info,
  help,
  width
}: FormSelectProps) {
  return (
    <FormItem {...{ label, info, help }}>
      <Select width={width} value={value} onChange={e => onChange(e.target.value)}>
        {(options || []).map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormItem>
  )
}
