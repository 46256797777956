// @ts-check

import React from 'react'
import { map } from 'rxjs/operators'
import { entity_name, find_entity } from '../../Schema'
import { entity_item$ } from '../../Data'
import { EntityID } from '../../UI'

//--------------------------------------------------------------------------------------------------

export default {
  /**
   * @param {import("../../types").Project} project
   * @param {import("../types").QueryStep} step
   * @param {number} idx
   * @param {import("../types").QueryResult} result
   */

  execute(project, step, idx, result) {
    if (step && step.start_entity_id) {
      const start_entity = find_entity(project, step.start_entity_id)
      // const name_attr = entity_instance_name_attr(project, src_entity)

      result.cols = [
        {
          id: 'col' + idx,
          entity_ids: [step.start_entity_id],
          name: step.column_name || entity_name(project, step.start_entity_id),
          // render: item => (name_attr ? item.value[name_attr.id] : `<${item.value.id || '?'}>`)
          hue: start_entity ? start_entity.hue || 0 : 0,
          render: item => item.value._name || `<${item.value.id || '?'}>`,
          hidden: step.column_hide
        }
      ]

      result.item$ = entity_item$(project, step.start_entity_id).pipe(
        map(items => items.map(item => [{ i: 0, value: item }]))
      )
    }
  },

  show(project, step, type) {
    return (
      <>
        {type.label}:
        <ul style={{ margin: 0 }}>
          <li>{entity_name(project, step.start_entity_id)}</li>
        </ul>
      </>
    )
  },

  edit(project, step, setStepProp) {
    return (
      <EntityID
        label="Start Entity"
        id={step.start_entity_id}
        setID={id => setStepProp('start_entity_id', id)}
        entities={project.entities}
      />
    )
  }
}
