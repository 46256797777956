import React from 'react'
import sortBy from 'lodash/sortBy'
import { AttributeType } from '../types'
import { entity_attrs } from '../Schema'
import { Table } from '../UI'

//--------------------------------------------------------------------------------------------------

export function EntityImportTable({ project, entity, data }) {
  const attrs = entity_attrs(project, entity).filter(a => !a.hidden)

  const sorted =
    attrs.length > 0
      ? sortBy(data || [], ...attrs.filter(a => !!a.is_sorter).map(a => a.id))
      : data || []

  const cols = attrs
    .filter(a => a.type !== AttributeType.Text)
    .map(a => ({
      label: a.name,
      cell: entity => {
        // console.log(entity[a.id])
        switch (a.type) {
          case AttributeType.Bool:
            return entity[a.id] ? '✔' : ''
          default:
            return entity[a.id]
        }
      },
      style: entity => {
        switch (a.type) {
          case AttributeType.Date:
            return { whiteSpace: 'nowrap' }
          default:
            return {}
        }
      }
    }))

  return <Table {...{ project }} type={entity} cols={cols} objs={sorted} isData />
}
