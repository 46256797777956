// @ts-check

import React from 'react'
import { Intent, Button, Tooltip } from '@blueprintjs/core'

//--------------------------------------------------------------------------------------------------

/**
 * A button that deletes a step
 * @param {object} props
 * @param {import("./types").QueryStep} props.step
 * @param {(f:(steps:import("./types").QueryStep[])=>import("./types").QueryStep[])=>void} props.setSteps
 */

export function StepDeleteButton({ step, setSteps }) {
  return (
    <>
      <Tooltip content="Delete step">
        <Button icon="delete" minimal small intent={Intent.DANGER} onClick={del} />
      </Tooltip>
    </>
  )

  function del() {
    setSteps(steps => steps.filter(a => a.id !== step.id))
  }
}
