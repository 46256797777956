import React, { useState, useEffect } from 'react'
// import { Name, Desc, Panel, IsPublic, FormError } from '../UI'
import { Name, Desc, Panel, FormError } from '../UI'
import { useObservable, watch } from '../Firebase'
// import * as ROLES from '../../constants/roles'
// import { useAuthUser } from '../Users/Session'
import { CollabIDs } from '../Parts'

export function AdminPanel({ project, update }) {
  const [error, setError] = useState(null)
  const users = useObservable(watch('users'), [])

  const [name, setName] = useState(project.name || '')
  const [desc, setDesc] = useState(project.desc || '')
  // const [is_public, setIsPublic] = useState(project.is_public || false)
  const [collab_ids, setCollabIDs] = useState(project.collab_ids || [])
  // const user = useAuthUser()

  useEffect(() => {
    update({ name, desc, collab_ids }).catch(setError)
  }, [name, desc, collab_ids])
  //   update({ name, desc, is_public, collab_ids }).catch(setError)
  // }, [name, desc, is_public, collab_ids])

  return (
    <Panel width="40em">
      <Name value={name} setValue={setName} />
      <Desc value={desc} setValue={setDesc} />
      {/* <IsPublic value={is_public} setValue={setIsPublic} /> */}
      {/* <FormSwitch label="Is Public" value={is_public} onChange={value => setIsPublic(value)} /> */}
      {/* {user && user.roles.includes(ROLES.SUPER) ? ( */}
      <CollabIDs users={users} collab_ids={collab_ids} setCollabIDs={setCollabIDs} />
      {/* ) : null} */}
      <FormError error={error} />
    </Panel>
  )
}
