// @ts-check

import React from 'react'
import { StepType } from './types'
import { FormSelect } from '../UI'

//--------------------------------------------------------------------------------------------------

export const step_types = [
  { label: 'Start Entity', value: StepType.StartEntity },
  { label: 'Follow Relations', value: StepType.FollowRelations }
  // { label: 'Union', value: StepType.Union }
]

/**
 * A query step type selector
 * @param {object} props
 * @param {import("./types").StepType} props.value
 * @param {(new_type:import("./types").StepType) => void} props.setValue
 */

export function StepTypeUI({ value, setValue }) {
  return <FormSelect label="Type" value={value} options={step_types} onChange={setValue} />
}
