// @ts-check

import { execute_step } from './steps'

//--------------------------------------------------------------------------------------------------

/**
 * Execute a Query
 * @param {import("../types").Project} project
 * @param {import("./types").Query} query
 */

export function run_query(project, query) {
  /** @type {import("./types").QueryResult} */
  const result = {
    cols: []
  }

  query.steps.forEach((step, idx) => {
    execute_step(project, step, idx, result)
  })

  return result
}
