// @ts-check

import React from 'react'
import { FormGroup, Button, MenuItem } from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'

//--------------------------------------------------------------------------------------------------

export function SelectIDs({ label, ids, setIDs, items, item_name }) {
  return (
    <FormGroup label={label}>
      <MultiSelect
        items={items}
        itemRenderer={(item, { handleClick, modifiers, query }) => {
          return modifiers.matchesPredicate ? (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={item.id}
              onClick={handleClick}
              text={item_name(item)}
            />
          ) : null
        }}
        selectedItems={ids}
        onItemSelect={item => {
          // console.log(ids)
          if (ids.includes(item.id)) {
            setIDs(ids.filter(id => id !== item.id))
          } else {
            setIDs([...ids, item.id])
          }
        }}
        tagRenderer={id => {
          const item = items.find(item => item.id === id)
          return item ? item_name(item) : `<${id}>`
        }}
        tagInputProps={{
          // tagProps: { minimal: true },
          onRemove: tag => {
            const item = items.find(item => item_name(item) === tag)
            if (item) {
              setIDs(ids.filter(id => id !== item.id))
            }
          },
          rightElement:
            ids.length > 0 ? (
              <Button icon="cross" minimal={true} onClick={() => setIDs([])} />
            ) : (
              undefined
            )
        }}
      />
    </FormGroup>
  )
}
