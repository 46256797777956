import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
// import {} from 'nanoid'
import { create } from '../Firebase'
import { DialogBody, DialogFooter, Error } from '../Parts'
import { AttributeValueUI } from '../Schema/AttributeValueUI'
import { data_collection_name, entity_attrs } from '../Schema'
import { CancelOrCreate } from '../UI'

//--------------------------------------------------------------------------------------------------

export function NewEntityInstanceDialog({ project, type, setOpen }) {
  const [error, setError] = useState(null)
  const [data, updateData] = useState({})

  function setData(key, value) {
    updateData(data => ({ ...data, [key]: value }))
  }

  let isInvalid = false

  const attrs = entity_attrs(project, type).filter(a => !a.hidden)

  for (const attr of attrs) {
    const value = data[attr.id]
    if (attr.is_required && (value == null || value.length < 1)) {
      isInvalid |= true
    }
  }

  return (
    <Dialog title={'Create New ' + type.name} icon="add" isOpen={true} onClose={close}>
      <DialogBody>
        {attrs.map(attr => (
          <AttributeValueUI
            key={attr.id}
            attr={attr}
            data={data}
            setData={setData}
            // value={data[attr.id]}
            // setValue={value => setData({ ...data, [attr.id]: value })}
          />
        ))}
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={on_create} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function on_create() {
    create(data_collection_name(project.id, type.id), data)
      .then(close)
      .catch(setError)
  }
}
