import React, { useState } from 'react'
import { Callout, Button, Intent, Classes } from '@blueprintjs/core'

import { useAuthUser } from './context'
import { useFirebase } from '../../Firebase'

export const withEmailVerification = Component => props => {
  const firebase = useFirebase()
  const [isSent, setIsSent] = useState(false)
  const authUser = useAuthUser()

  return needsEmailVerification(authUser) ? (
    <Callout
      title={isSent ? 'E-Mail confirmation sent' : 'Verify your E-Mail'}
      intent={Intent.WARNING}
      style={{ maxWidth: '25em', margin: '0 auto' }}
    >
      <p>
        Check your E-Mails (Spam folder included) for a confirmation E-Mail. Refresh this page once
        you have confirmed your E-Mail.
      </p>

      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button intent={Intent.PRIMARY} onClick={onSendEmailVerification} disabled={isSent}>
          Send confirmation E-Mail
        </Button>
      </div>
    </Callout>
  ) : (
    <Component {...props} />
  )

  function needsEmailVerification() {
    return (
      authUser &&
      !authUser.emailVerified &&
      authUser.providerData.map(provider => provider.providerId).includes('password')
    )
  }

  function onSendEmailVerification() {
    firebase.doSendEmailVerification().then(() => setIsSent({ isSent: true }))
  }
}
