class Point {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

function point(x, y) {
  if (x instanceof Point) {
    return x
  }
  if (Array.isArray(x) && Number.isFinite(x[0]) && Number.isFinite(x[1])) {
    return new Point(x[0], x[1])
  }
  if (Number.isFinite(x.x) && Number.isFinite(x.y)) {
    return new Point(Number(x.x), Number(x.y))
  } else {
    return new Point(Number(x), Number(y))
  }
}

//--------------------------------------------------------------------------------------------------

export class Line {
  constructor(p0, p1) {
    this.p0 = point(p0)
    this.p1 = point(p1)
  }

  lerp(t) {
    return point(this.p0.x + (this.p1.x - this.p0.x) * t, this.p0.y + (this.p1.y - this.p0.y) * t)
  }
}
