import React from 'react'
import { ribbon as d3_ribbon } from 'd3-chord'
import { arc as d3_arc } from 'd3-shape'

export function ChordDiagram({
  size,
  data,
  arcColor,
  chordColor,
  style,
  arcOver,
  arcLeave,
  arcClick,
  chordOver,
  chordLeave,
  chordClick
}) {
  const render_arc = ({ id, arc }) => {
    return (
      <path
        key={id}
        d={arc}
        stroke="white"
        fill={arcColor(id)}
        onMouseMove={e => {
          if (arcOver) {
            e.stopPropagation()
            arcOver({ x: e.clientX + window.scrollX, y: e.clientY + window.scrollY }, id)
          }
        }}
        onMouseLeave={() => arcLeave && arcLeave(id)}
        onClick={e => {
          if (arcClick) {
            e.stopPropagation()
            arcClick(id)
          }
        }}
      />
    )
  }

  const render_ribbon = ({ source_id, target_id, ribbon }) => {
    const { color, opacity } = chordColor(source_id, target_id)

    return (
      <path
        key={`${source_id}:${target_id}`}
        d={ribbon}
        opacity={opacity}
        fill={color}
        strokeWidth="1.2"
        onMouseEnter={() => chordOver && chordOver(source_id, target_id)}
        onMouseLeave={() => chordLeave && chordLeave(source_id, target_id)}
        onClick={e => {
          if (chordClick) {
            e.stopPropagation()
            chordClick(source_id, target_id)
          }
        }}
      />
    )
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox={`${-size / 2} ${-size / 2} ${size} ${size}`}
      style={style}
      onClick={() => arcClick && arcClick(null)}
    >
      {make_ribbons(size, data).map(render_ribbon)}
      {make_arcs(size, data).map(render_arc)}
    </svg>
  )
}

const R = 2.5

function make_arcs(size, { chords, ids }) {
  const arc = d3_arc()

  return (chords.groups || []).map(g => {
    return {
      id: ids[g.index],
      arc: arc({
        innerRadius: size / R,
        outerRadius: size / R + 20,
        startAngle: g.startAngle,
        endAngle: g.endAngle
      })
    }
  })
}

function make_ribbons(size, { chords, ids }) {
  const ribbon = d3_ribbon()

  return chords.map(c => {
    return {
      source_id: ids[c.source.index],
      target_id: ids[c.target.index],
      ribbon: ribbon({
        source: { startAngle: c.source.startAngle, endAngle: c.source.endAngle, radius: size / R },
        target: { startAngle: c.target.startAngle, endAngle: c.target.endAngle, radius: size / R }
      })
    }
  })
}
