import React from 'react'
import sortBy from 'lodash/sortBy'
import { useAuthUser } from '../Users/Session'
import { NewEntityDialog, EditEntityDialog } from '.'
import { Table } from '../UI'

//--------------------------------------------------------------------------------------------------

export function EntityList({ project, update, selection_id, setSelectionID }) {
  const user = useAuthUser()
  const entities = sortBy(project.entities || [], 'name')

  return (
    <Table
      ident="entities"
      width="350px"
      {...{ project, user, selection_id, setSelectionID, update }}
      showHue
      AddDialog={NewEntityDialog}
      EditDialog={EditEntityDialog}
      addTip="Create new type of entity"
      editTip="Edit this entity type"
      showTip="Show hidden entity types"
      hideTip="this entity type"
      cols={[{ label: 'Entity Type', cell: entity => entity.name }]}
      objs={entities}
    />
  )
}
