import React from 'react'
import { gray, color } from '../../UI'
import { Line } from './Line'

//--------------------------------------------------------------------------------------------------

export const Link = ({ link, selected }) => {
  const line = new Line(link.source, link.target)
  const x1 = link.source.x
  const y1 = link.source.y
  const x2 = link.target.x
  const y2 = link.target.y
  const pp = line.lerp(0.65)
  return (
    <g>
      <path
        d={`M${x1},${y1}L${pp.x},${pp.y}L${x2},${y2}`}
        stroke={selected ? 'cyan' : 'white'}
        strokeWidth="16px"
        fill="none"
      />
      <path
        d={`M${x1},${y1}L${pp.x},${pp.y}L${x2},${y2}`}
        markerMid={`url(#${link.role === 'isa' ? 'isa-' : ''}arrow)`}
        stroke={link.hue ? color(link.hue | 0, 5) : gray(5)}
        strokeWidth="8px"
        fill="none"
      />
    </g>
  )
}
