import React from 'react'
import { gray } from '../../UI'
import { Line } from './Line'

//--------------------------------------------------------------------------------------------------

export function IsA({ link }) {
  const line = new Line(link.source, link.target)
  const x1 = link.source.x
  const y1 = link.source.y
  const x2 = link.target.x
  const y2 = link.target.y
  const pp = line.lerp(0.65)
  return (
    <g key={link.id} className="isa">
      <path
        d={`M${x1},${y1}L${pp.x},${pp.y}L${x2},${y2}`}
        stroke="white"
        strokeWidth="16px"
        fill="none"
      />
      <path
        d={`M${x1},${y1}L${pp.x},${pp.y}L${x2},${y2}`}
        markerMid={'url(#isa-arrow)'}
        stroke={gray(4)}
        strokeWidth="8px"
        fill="none"
      />
    </g>
  )
}
