// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { Error, DialogBody, DialogFooter } from '../Parts'
import { StepListUI } from '.'
import { Name, CancelOrSave } from '../UI'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for editing a query
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {import("../types").Updater} props.update
 * @param {import("./types").Query} props.object
 * @param {(open:boolean) => void} props.setOpen
 */

export function EditQueryDialog({ project, object, update, setOpen }) {
  const [error, setError] = useState(null)

  const [name, setName] = useState(object.name)
  const [steps, setSteps] = useState([...object.steps])

  const isInvalid = name.length == 0 || steps.length == 0

  return (
    <Dialog title="Edit Query" icon="edit" isOpen={true} onClose={close} canEscapeKeyClose={false}>
      <DialogBody>
        <Name value={name} setValue={setName} />
        <StepListUI value={steps} setValue={setSteps} project={project} />
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrSave isInvalid={isInvalid} onCancel={close} onSave={save} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    object.name = name
    object.steps = [...steps]
    update({ queries: project.queries })
      .then(close)
      .catch(setError)
  }
}
