// export const LANDING = '/'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const HOME = '/' //home'
export const ACCOUNT = '/account'
export const PASSWORD_FORGET = '/pw-forget'
export const ADMIN = '/admin'
export const ADMIN_DETAILS = '/admin/:id'
export const PROJECT = '/project'
export const PROJECT_INFO = '/project/:id'
// export const PROJECT_ADMIN = '/project/:id/admin'
// export const PROJECT_SCHEMA = '/project/:id/schema'
// export const PROJECT_DATA = '/project/:id/data'
