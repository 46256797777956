import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { DialogBody, DialogFooter, IsAs } from '../Parts'
import { AttributesUI, entity_attrs } from '.'
import { PickHue, Name, Desc, CancelOrSave, FormError } from '../UI'
import type { PlainAttribute, PlainEntity, PlainProject, Updater } from '.'

//--------------------------------------------------------------------------------------------------

interface EditEntityDialogProps {
  project: PlainProject
  update: Updater
  object: PlainEntity
  setOpen: (open: boolean) => void
}

export function EditEntityDialog({ project, update, object, setOpen }: EditEntityDialogProps) {
  const [error, setError] = useState(null)

  const [name, setName] = useState(object.name)
  const [desc, setDesc] = useState(object.desc || '')
  const [hue, setHue] = useState(object.hue)
  const [attrs, setAttrs] = useState((object.attrs || []).map((attr:PlainAttribute) => ({ ...attr })))
  const [isa_ids, setIsaIds] = useState(object.isa_ids || [])

  // const all_attrs = [attrs, ...entity_attrs(project, object).filter(a => !a.hidden)]

  const isInvalid = name.length == 0 //|| all_attrs.length == 0 || all_attrs.filter(a => a.is_name).length < 1

  return (
    <Dialog
      title="Edit Type of Entity"
      icon="edit"
      isOpen={true}
      onClose={close}
      canEscapeKeyClose={false}
    >
      <DialogBody>
        <Name value={name} setValue={setName} />
        <Desc value={desc} setValue={setDesc} />
        <PickHue value={hue} setValue={setHue} />
        <IsAs value={isa_ids} setValue={setIsaIds} entities={project.entities} />
        <AttributesUI value={attrs} setValue={setAttrs} showIsName={true} />
      </DialogBody>

      <DialogFooter>
        <CancelOrSave isInvalid={isInvalid} onCancel={close} onSave={save} />
        <FormError error={error} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    object.name = name
    object.attrs = attrs
    object.desc = desc
    object.hue = hue
    object.isa_ids = isa_ids
    update({ entities: project.entities })
      .then(close)
      .catch(setError)
  }
}
