// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '../Parts'
import { Name, Desc, IsName, IsRequired, IsOrder, IsSorter, Choices, CancelOrSave } from '../UI'
import { AttributeTypeUI, AttributeStructUI } from '.'
import { AttributeType, AttributeStruct } from '../types'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for editing an attribute
 * @param {object} props
 * @param {import("../types").Attribute} props.object
 * @param {(f:(attrs:import("../types").Attribute[])=>import("../types").Attribute[])=>void} props.setAttrs
 * @param {(open:boolean)=>void} props.setOpen
 * @param {boolean} props.showIsName
 * @param {boolean} props.showIsOrder
 */

export function EditAttributeDialog({ object: attr, setAttrs, setOpen, showIsName, showIsOrder }) {
  const id = attr.id
  const hidden = !!attr.hidden
  const [name, setName] = useState(attr.name || '')
  const [is_name, setIsName] = useState(attr.is_name || false)
  const [desc, setDesc] = useState(attr.desc || null)
  const [is_order, setIsOrder] = useState(attr.is_order || false)
  const [is_sorter, setIsSorter] = useState(attr.is_sorter || false)
  const [type, setType] = useState(attr.type || 'string')
  const [struct, setStruct] = useState(attr.struct || AttributeStruct.One)
  const [is_required, setIsRequired] = useState(attr.is_required || false)
  const [choices, setChoices] = useState([...(attr.choices || [])])

  const isInvalid = name.length == 0

  return (
    <Dialog
      title="Edit Attribute"
      icon="edit"
      isOpen
      onClose={close}
      usePortal={false}
      style={{ textAlign: 'left' }}
    >
      <DialogBody>
        <Name value={name} setValue={setName} />

        <Desc value={desc} setValue={setDesc} />

        <AttributeTypeUI value={type} setValue={setType} />

        <IsRequired value={is_required} setValue={setIsRequired} />

        {(type === AttributeType.String ||
          type === AttributeType.Choice ||
          type === AttributeType.Date) &&
        showIsName ? (
          <IsName value={is_name} setValue={setIsName} />
        ) : null}

        <IsSorter value={is_sorter} setValue={setIsSorter} />

        {(type === AttributeType.String ||
          type === AttributeType.Choice ||
          type === AttributeType.Date) &&
        showIsOrder ? (
          <IsOrder value={is_order} setValue={setIsOrder} />
        ) : null}

        {type === AttributeType.Choice ? (
          <AttributeStructUI value={struct} setValue={setStruct} />
        ) : null}

        {type === AttributeType.Choice ? <Choices value={choices} setValue={setChoices} /> : null}
      </DialogBody>

      <DialogFooter>
        <CancelOrSave isInvalid={isInvalid} onCancel={close} onSave={save} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    setAttrs(attrs =>
      attrs.map(a =>
        a.id === id
          ? {
              id,
              name,
              desc,
              is_name,
              is_order,
              is_sorter,
              is_required,
              type,
              struct,
              choices,
              hidden
            }
          : a
      )
    )
    close()
  }
}
