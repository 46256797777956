import React from 'react'
import { Link } from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import { Table } from '../UI'
import { useObservable, watch, combineLatest } from '../Firebase'
import * as ROUTES from '../../constants/routes'
import { NewProjectDialog } from '.'

export function ProjectList({ user, title, add = true }) {
  const projects = useObservable(
    combineLatest(watch('projects'), watch('users'), (ps, us) => {
      return ps.map(p => ({
        ...p,
        owner: us.find(u => u.id === p.owner_id) || {}
        // collabs: p.collab_ids.map(cid => us.find(u => u.id === cid) || {id: cid})
      }))
    }),
    []
  )

  const cols = [
    {
      label: 'Name',
      cell: project => <Link to={`${ROUTES.PROJECT}/${project.id}`}>{project.name}</Link>
    },
    // { label: 'Public', cell: project => project.is_public && <Icon icon="check" /> },
    { label: 'Description', cell: project => project.desc },
    { label: 'Owner', cell: project => project.owner.username || '??' }
  ]

  return (
    <div>
      {title == null || title.length > 0 ? (
        <h2>{title ? title : user ? 'Your Projects' : 'Projects'}</h2>
      ) : null}

      {add ? (
        <Table
          AddDialog={NewProjectDialog}
          addProps={{ user }}
          addTip="Create new project"
          cols={cols}
          objs={sortBy(
            projects.filter(
              project =>
                !project.hidden &&
                (!user || project.owner_id === user.id || project.collab_ids.includes(user.id))
            ),
            'name'
          )}
        />
      ) : (
        <Table
          cols={cols}
          objs={sortBy(
            projects.filter(
              project =>
                !project.hidden &&
                (!user || project.owner_id === user.id || project.collab_ids.includes(user.id))
            ),
            'name'
          )}
        />
      )}
    </div>
  )
}
