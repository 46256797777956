//-- @ts-check

import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { AttributeType } from '../types'
import { FormSelect } from '../UI'

//--------------------------------------------------------------------------------------------------

const types = [
  { label: 'String', value: AttributeType.String },
  { label: 'Text', value: AttributeType.Text },
  { label: 'Date', value: AttributeType.Date },
  { label: 'On/Off', value: AttributeType.Bool },
  { label: 'Choice', value: AttributeType.Choice }
]

export function AttributeTypeUI({ value, setValue }) {
  return <FormSelect label="Type" value={value} options={types} onChange={setValue} />
}
