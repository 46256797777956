import React, { ChangeEvent } from 'react'
import styled from 'styled-components'
import { FormItem, Row } from '.'

//------------------------------------------------------------------------------

const Right = styled.div`
  margin: 2px 0 2px 4px;
`

const TextArea = styled.textarea`
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 4px;
  width: 100%;
  resize: vertical;
`

const TextBox = styled.input.attrs(({ type }) => ({ type: type || 'text' }))`
  border-radius: 4px;
  outline: none;
  border: none;
  padding: 4px;
  width: 100%;
  height: 28px;
`

interface FormInputProps {
  type?: string
  value: string
  label: string
  info?: string
  help?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  rightElement: React.ReactNode
}

export function FormInput({
  type,
  value,
  onChange,
  label,
  info,
  help,
  rightElement
}: FormInputProps) {
  const rght = rightElement ? <Right>{rightElement}</Right> : null

  const inpt =
    type !== 'textarea' ? (
      <TextBox type={type} value={value} onChange={onChange} />
    ) : (
      <TextArea value={value} onChange={onChange} rows={5} />
    )

  return (
    <FormItem label={label} info={info} help={help}>
      <Row>
        {inpt}
        {rght}
      </Row>
    </FormItem>
  )
}
