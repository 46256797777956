// @ts-check

import React from 'react'
import { combineLatest } from 'rxjs'
import {
  entity_name,
  find_entity,
  relation_name,
  find_relation,
  entity_supertypes
} from '../../Schema'
import { relation_item$ } from '../../Data'
import { FormSelect } from '../../UI'
import { SetOfRelationsUI } from '..'

//--------------------------------------------------------------------------------------------------

export default {
  /**
   * @param {import("../../types").Project} project
   * @param {import("../types").QueryStep} step
   * @param {number} idx
   * @param {import("../types").QueryResult} result
   */

  execute(project, step, idx, result) {
    if (
      idx > 0 &&
      result.item$ &&
      step &&
      step.follow_relation_ids &&
      step.follow_relation_ids.length > 0
    ) {
      const prev_entity_ids = idx > 0 ? result.cols[idx - 1].entity_ids : []
      const prev_entities = prev_entity_ids
        .map(id => find_entity(project, id))
        .filter(e => e != null)
      const prev_supertype_ids = new Set(
        [].concat(...prev_entities.map(e => [e, ...entity_supertypes(project, e)].map(s => s.id)))
      )
      const dst_entity_ids = Array.from(
        new Set(
          step.follow_relation_ids.map(rel_id => {
            const relation = find_relation(project, rel_id)
            if (prev_supertype_ids.has(relation.subj_id)) {
              return relation.dobj_id
            } else if (prev_supertype_ids.has(relation.dobj_id)) {
              return relation.subj_id
            } else {
              // ERROR
              console.error('follow_relations_step')
            }
          })
        )
      )

      const dst_entities = dst_entity_ids.map(ent_id => find_entity(project, ent_id))
      // const name_attrs = dst_entities.map(ent => entity_instance_name_attr(project, ent))
      const entity_names = [...new Set(dst_entity_ids).values()].map(ent_id =>
        entity_name(project, ent_id)
      )

      result.cols.push({
        id: 'col' + idx,
        entity_ids: dst_entity_ids,
        name: step.column_name || entity_names.join(' | '),
        hue: dst_entities.length == 1 && dst_entities[0] ? dst_entities[0].hue || 0 : 0,
        render: item => {
          // console.log(item)
          // return item
          //   ? (entity_names.length > 1 ? entity_names[item.i] + ': ' : '') +
          //       (name_attrs[item.i] ? item.value[name_attrs[item.i].id] : `<${item.value.id || '?'}>`)
          //   : '<null>'
          return item
            ? (entity_names.length > 1 ? entity_names[item.i] + ': ' : '') +
                (item.value._name || `<${item.value.id || '?'}>`)
            : '<null>'
        },
        hidden: step.column_hide
      })

      const followed_rels_data = step.follow_relation_ids.map(rel_id => {
        return relation_item$(project, rel_id)
      })

      result.item$ = combineLatest(result.item$, ...followed_rels_data, combiner)

      /**
       * @param {import("../types").QueryResultItem[][]} items
       * @param  {import("../../types").RelationInstance[][]} rels
       */
      function combiner(items, ...rels) {
        /** @type {import("../types").QueryResultItem[][]} */
        const res = []

        for (let item of items) {
          rels.forEach((rel, i) => {
            for (let dst_item of rel) {
              const item_id = item.slice(-1)[0].value.id
              if (item_id === dst_item.subj_id && step.follow_relation_dir !== 'bak') {
                res.push([...item, { i, value: dst_item.dobj }])
              } else if (item_id === dst_item.dobj_id && step.follow_relation_dir !== 'fwd') {
                res.push([...item, { i, value: dst_item.subj }])
              }
            }
          })
        }
        // console.table(res)
        return res
      }
    }
  },

  show(project, step, type) {
    return (
      <>
        {type.label}:
        <ul style={{ margin: 0 }}>
          {step.follow_relation_ids.map(id => {
            const relation = find_relation(project, id)
            return <li key={id}>{relation_name(project, relation)}</li>
          })}
        </ul>
      </>
    )
  },

  edit(project, step, setStepProp) {
    return (
      <>
        <SetOfRelationsUI
          value={step.follow_relation_ids || []}
          setValue={ids => setStepProp('follow_relation_ids', ids)}
          project={project}
        />
        <FormSelect
          label="Restrict Direction"
          value={step.follow_relation_dir || null}
          options={[
            { value: null, label: '' },
            { value: 'fwd', label: 'Forward only' },
            { value: 'bak', label: 'Backward only' }
          ]}
          onChange={value => setStepProp('follow_relation_dir', value)}
        />
      </>
    )
  }
}
