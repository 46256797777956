import React, { useState } from 'react'
import { combineLatest } from 'rxjs'
import { Dialog } from '@blueprintjs/core'
import { watch, useObservable, create } from '../Firebase'
import { DialogBody, DialogFooter, Error } from '../Parts'
import { AttributeValueUI } from '../Schema/AttributeValueUI'
import { relation_name, data_collection_name, find_entity, entity_subtypes } from '../Schema'
import { CancelOrCreate, EntityInstanceID } from '../UI'

//--------------------------------------------------------------------------------------------------

export function NewRelationInstanceDialog({ project, type, setOpen, empty }) {
  const subj_class = find_entity(project, type.subj_id)
  const subj_classes = [subj_class, ...entity_subtypes(project, subj_class)]
  const subj_data = subj_classes.map(({ id }) => watch(data_collection_name(project.id, id), []))

  const dobj_class = find_entity(project, type.dobj_id)
  const dobj_classes = [dobj_class, ...entity_subtypes(project, dobj_class)]
  const dobj_data = dobj_classes.map(({ id }) => watch(data_collection_name(project.id, id), []))

  const subjs = useObservable(combineLatest(...subj_data, combiner))
  const dobjs = useObservable(combineLatest(...dobj_data, combiner))

  function combiner(...lists) {
    return [].concat(...lists)
  }

  const [error, setError] = useState(null)
  const [attrs, setAttrs] = useState({ ...(empty && empty.attrs) })
  const [subj_id, setSubjID] = useState((empty && empty.subj_id) || '')
  const [dobj_id, setDobjID] = useState((empty && empty.dobj_id) || '')

  function setAttr(key, value) {
    setAttrs(data => ({ ...data, [key]: value }))
  }

  let isInvalid = subj_id == null || subj_id.length < 1 || dobj_id == null || dobj_id.length < 1

  const fields = (type.attrs || []).filter(a => !a.hidden)

  for (const field of fields) {
    const value = attrs[field.id]
    if ((field.is_required || field.is_name) && (value == null || value.length < 1)) {
      isInvalid |= true
    }
  }

  return (
    <Dialog
      title={'Create New ' + relation_name(project, type)}
      icon="add"
      isOpen={true}
      onClose={close}
    >
      <DialogBody>
        <EntityInstanceID
          project={project}
          entity_id={type.subj_id}
          id={subj_id}
          setID={setSubjID}
          instances={subjs}
        />
        <EntityInstanceID
          project={project}
          entity_id={type.dobj_id}
          id={dobj_id}
          setID={setDobjID}
          instances={dobjs}
        />
        {fields.map(field => (
          <AttributeValueUI key={field.id} attr={field} data={attrs} setData={setAttr} />
        ))}
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={on_create} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function on_create() {
    create(data_collection_name(project.id, type.id), { subj_id, dobj_id, ...attrs })
      .then(close)
      .catch(setError)
  }
}
