import React from 'react'
import { gray } from '../../UI'

//--------------------------------------------------------------------------------------------------

export function mk_arrow_def(len, id = 'arrow') {
  return (
    <marker
      id={id}
      viewBox={`-${len} -${len / 2} ${len} ${len}`} // "-20 -10 20 20"
      markerWidth={0.6 * len}
      markerHeight={0.6 * len}
      refX={0}
      refY={0}
      orient="auto"
      markerUnits="userSpaceOnUse"
    >
      <path
        fill={id === 'arrow' ? '#ddd' : gray(4)}
        d={`M-${len},-${0.4 * len}L0,0L-${len},${0.4 * len}`}
      />
    </marker>
  )
}
