import React from 'react'
import {
  Intent,
  Callout,
  FormGroup,
  InputGroup,
  TextArea,
  Switch,
  MenuItem,
  Button,
  Classes,
  HTMLSelect
} from '@blueprintjs/core'
import { MultiSelect } from '@blueprintjs/select'
// import { HUE_NAME } from '../UI'

//--------------------------------------------------------------------------------------------------

// export function Col({ children, style }) {
//   return <div style={{ display: 'flex', flexDirection: 'column', ...style }}>{children}</div>
// }

// export function Row({ children, style }) {
//   return <div style={{ display: 'flex', flexDirection: 'row', ...style }}>{children}</div>
// }

// const BRK = 16
// export const W = w => w * BRK - BRK / 2

// export function Block({ w, children, style }) {
//   if (w) {
//     return <div style={{ width: `${W(w)}px`, padding: '4px', ...style }}>{children}</div>
//   }
//   return <div style={{ padding: '4px', ...style }}>{children}</div>
// }

//--------------------------------------------------------------------------------------------------

/**
 * @param {object} props
 * @param {any} props.children
 * @param {any} [props.style]
 */

export function DialogBody({ children, style }) {
  return (
    <div className={Classes.DIALOG_BODY} style={style}>
      {children}
    </div>
  )
}

/**
 * @param {object} props
 * @param {any} props.children
 * @param {any} [props.style]
 */

export function DialogFooter({ children, style }) {
  return (
    <div className={Classes.DIALOG_BODY} style={style}>
      {children}
    </div>
  )
}

//--------------------------------------------------------------------------------------------------

// export function Name({ value, setValue }) {
//   return (
//     <FormGroup label="Name" labelInfo="(required)">
//       <InputGroup value={value} onChange={e => setValue(e.target.value)} type="text" />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function Desc({ value, setValue }) {
//   return (
//     <FormGroup label="Description">
//       <TextArea
//         value={value || ''}
//         onChange={e => setValue(e.target.value === '' ? null : e.target.value)}
//         growVertically
//         fill
//       />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function Choices({ value, setValue }) {
//   return (
//     <FormGroup label="Choices" labelInfo="(one per line)">
//       <TextArea
//         value={(value || []).join('\n')}
//         onChange={e => setValue(e.target.value.split('\n'))}
//         growVertically
//         fill
//       />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function IsPublic({ value, setValue }) {
//   return <Switch label="Is Public" checked={value} onChange={e => setValue(e.target.checked)} />
// }

//--------------------------------------------------------------------------------------------------

export function Error({ error }) {
  return (
    error && (
      <Callout intent={Intent.DANGER} style={{ marginTop: '1em' }}>
        {error.message}
      </Callout>
    )
  )
}

//--------------------------------------------------------------------------------------------------

export function CollabIDs({ collab_ids, setCollabIDs, users }) {
  return (
    <FormGroup label="Collaborators">
      <MultiSelect
        items={users}
        itemRenderer={(user, { handleClick, modifiers, query }) => {
          return modifiers.matchesPredicate ? (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={user.id}
              onClick={handleClick}
              text={user.username}
            />
          ) : null
        }}
        selectedItems={collab_ids}
        onItemSelect={user => {
          console.log(collab_ids)
          if (collab_ids.includes(user.id)) {
            setCollabIDs(collab_ids.filter(id => id !== user.id))
          } else {
            setCollabIDs([...collab_ids, user.id])
          }
        }}
        tagRenderer={id => {
          const user = users.find(user => user.id === id)
          return user ? user.username : `<user: ${id}>`
        }}
        tagInputProps={{
          // tagProps: { minimal: true },
          onRemove: tag => {
            const user = users.find(user => user.username === tag)
            if (user) {
              setCollabIDs(collab_ids.filter(id => id !== user.id))
            }
          },
          rightElement:
            collab_ids.length > 0 ? (
              <Button icon="cross" minimal={true} onClick={() => setCollabIDs([])} />
            ) : (
              undefined
            )
        }}
      />
    </FormGroup>
  )
}

//--------------------------------------------------------------------------------------------------

export function IsAs({ value, setValue, entities }) {
  entities = entities || []

  return (
    <FormGroup label="Is A">
      <MultiSelect
        items={entities}
        itemRenderer={(entity, { handleClick, modifiers, query }) => {
          return modifiers.matchesPredicate ? (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={entity.id}
              onClick={handleClick}
              text={entity.name}
            />
          ) : null
        }}
        selectedItems={value}
        onItemSelect={entity => {
          // console.log(value)
          if (value.includes(entity.id)) {
            setValue(value.filter(id => id !== entity.id))
          } else {
            setValue([...value, entity.id])
          }
        }}
        tagRenderer={id => {
          const entity = entities.find(entity => entity.id === id)
          return entity ? entity.name : `<entity: ${id}>`
        }}
        tagInputProps={{
          // tagProps: { minimal: true },
          onRemove: tag => {
            const entity = entities.find(entity => entity.name === tag)
            if (entity) {
              setValue(value.filter(id => id !== entity.id))
            }
          },
          rightElement:
            value.length > 0 ? (
              <Button icon="cross" minimal={true} onClick={() => setValue([])} />
            ) : (
              undefined
            )
        }}
      />
    </FormGroup>
  )
}

//--------------------------------------------------------------------------------------------------

export function ChoiceSetFieldPart({ label, value, setValue, options }) {
  options = options || []

  return (
    <FormGroup label={label}>
      <MultiSelect
        items={options}
        itemRenderer={(option, { handleClick, modifiers, query }) => {
          return modifiers.matchesPredicate ? (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={option.value}
              onClick={handleClick}
              text={option.label}
            />
          ) : null
        }}
        selectedItems={value}
        onItemSelect={option => {
          // console.log(value)
          if (value.includes(option.value)) {
            setValue(value.filter(id => id !== option.value))
          } else {
            setValue([...value, option.value])
          }
        }}
        tagRenderer={id => {
          const option = options.find(option => option.value === id)
          return option ? option.label : `<option: ${id}>`
        }}
        tagInputProps={{
          // tagProps: { minimal: true },
          onRemove: tag => {
            const option = options.find(option => option.label === tag)
            if (option) {
              setValue(value.filter(id => id !== option.value))
            }
          },
          rightElement:
            value.length > 0 ? (
              <Button icon="cross" minimal={true} onClick={() => setValue([])} />
            ) : (
              undefined
            )
        }}
      />
    </FormGroup>
  )
}

//--------------------------------------------------------------------------------------------------

// export function Verb({ value, setValue }) {
//   return (
//     <FormGroup label="Relationship" labelInfo="(required)">
//       <InputGroup value={value} onChange={e => setValue(e.target.value)} type="text" />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function EntityID({ label, id, setID, entities }) {
//   entities = entities || []

//   return (
//     <FormGroup label={label} labelInfo="(required)">
//       <HTMLSelect
//         value={id}
//         options={[{ label: 'Select an Entity...', value: '' }].concat(
//           entities.map(e => ({ label: e.name, value: e.id }))
//         )}
//         onChange={e => setID(e.currentTarget.value)}
//       />
//     </FormGroup>
//   )
// }

// //--------------------------------------------------------------------------------------------------

// export function PickHue({ value, setValue }) {
//   return (
//     <FormGroup label="Colour">
//       <HTMLSelect
//         value={value || undefined}
//         options={[{ label: 'Select a Colour...', value: '' }].concat(
//           HUE_NAME.map(h => ({ label: h.name, value: h.val }))
//         )}
//         onChange={e => setValue(e.currentTarget.value)}
//       />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function CancelOrCreate({ onCancel, onCreate, isInvalid }) {
//   return (
//     <div className={Classes.DIALOG_FOOTER_ACTIONS}>
//       <Button onClick={onCancel}>Cancel</Button>{' '}
//       <Button onClick={onCreate} intent={Intent.PRIMARY} disabled={isInvalid}>
//         Create
//       </Button>
//     </div>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function CancelOrSave({ onCancel, onSave, isInvalid }) {
//   return (
//     <div className={Classes.DIALOG_FOOTER_ACTIONS}>
//       <Button onClick={onCancel}>Cancel</Button>{' '}
//       <Button onClick={onSave} intent={Intent.PRIMARY} disabled={isInvalid}>
//         Save
//       </Button>
//     </div>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function IsName({ value, setValue }) {
//   return <Switch label="Is Name" checked={value} onChange={e => setValue(e.target.checked)} />
// }

//--------------------------------------------------------------------------------------------------
// import sortBy from 'lodash/sortBy'
// import { find_entity } from '../Schema'
// import { entity_instance_name_attrs, entity_instance_name } from '../Data'

// export function EntityInstanceID({ project, entity_id, id, setID, instances }) {
//   if (!instances) {
//     return null
//   }

//   const entity = find_entity(project, entity_id)
//   const name_attrs = entity_instance_name_attrs(project, entity)

//   const entity_name = entity ? entity.name : `<Entity ${entity_id}>`
//   const a_an = 'aeiou'.includes(entity_name[0].toLowerCase()) ? 'an' : 'a'
//   const options = [{ label: `Select ${a_an} ${entity_name}...`, value: '' }].concat(
//     sortBy(
//       (instances || []).map(instance => ({
//         label: entity_instance_name(entity, instance, name_attrs),
//         value: instance.id
//       })),
//       'label'
//     )
//   )

//   return (
//     <FormGroup label={entity_name} labelInfo="(required)" fill>
//       <HTMLSelect fill value={id} options={options} onChange={e => setID(e.currentTarget.value)} />
//     </FormGroup>
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function RecycleButton({ state, setState }) {
//   return (
//     <Button
//       intent="warning"
//       minimal
//       rightIcon="trash"
//       active={state}
//       onClick={() => setState(!state)}
//       style={{ float: 'right' }}
//     >
//       Show Removed
//     </Button>
//   )
// }

// function Name({ project }) {
//   const firebase = useFirebase()
//   const [error, setError] = useState(null)

//   // use of key in EditableText is workaround - https://github.com/palantir/blueprint/pull/1068
//   return (
//     <>
//       <EditableText key={project.name} defaultValue={project.name} onConfirm={save} />
//       <Alert intent={Intent.DANGER} isOpen={!!error} onClose={() => setError(null)} icon="error">
//         <p>{error && error.message}</p>
//       </Alert>
//     </>
//   )

//   function save(new_name) {
//     firebase
//       .project(project.id)
//       .update({ name: new_name })
//       .catch(setError)
//   }
// }
