import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { create, useObservable, watch } from '../Firebase'
// import { Name, Desc, IsPublic, Error, CollabIDs } from '../Parts'
import { DialogBody, DialogFooter, CollabIDs } from '../Parts'
// import { IsPublic, Name, Desc, CancelOrCreate, FormError } from '../UI'
import { Name, Desc, CancelOrCreate, FormError } from '../UI'

// export const AddProjectButton = ({ user }) => {
//   const [error, setError] = useState(null)
//   const users = useObservable(watch('users'), [])

//   const [open, setOpen] = useState(false)
//   const [name, setName] = useState('')
//   const [desc, setDesc] = useState('')
//   const [is_public, setIsPublic] = useState(false)
//   const [collab_ids, setCollabIDs] = useState([])

//   const isInvalid = !user || name.length < 1

//   return (
//     <>
//       <Tooltip content="Create new project">
//         <Button icon="add" minimal intent={Intent.SUCCESS} onClick={() => setOpen(true)} />
//       </Tooltip>

//       {/* {open ? (
//       ) : null} */}
//     </>
//   )

// }

export function NewProjectDialog({ user, setOpen }) {
  const [error, setError] = useState(null)
  const users = useObservable(watch('users'), [])

  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [is_public, setIsPublic] = useState(false)
  const [collab_ids, setCollabIDs] = useState([])

  const isInvalid = !user || name.length < 1

  return (
    <Dialog title="Create New Project" icon="add" isOpen={true} onClose={close}>
      <DialogBody>
        <Name value={name} setValue={setName} />
        <Desc value={desc} setValue={setDesc} />
        {/* <IsPublic value={is_public} setValue={setIsPublic} /> */}
        <CollabIDs users={users} collab_ids={collab_ids} setCollabIDs={setCollabIDs} />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={on_create} />
        <FormError error={error} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
    setName('')
    setDesc(null)
    setIsPublic(false)
    setCollabIDs([])
  }

  function on_create() {
    create('projects', { name, desc, owner_id: user.id, is_public, collab_ids })
      .then(close)
      .catch(setError)
  }
}
