import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { withAuthorization, withEmailVerification } from '../Users/Session'
import { UserList, UserItem } from '../Users'
import { ProjectList } from '../Projects'
import * as ROLES from '../../constants/roles'
import * as ROUTES from '../../constants/routes'

export const AdminPage = withEmailVerification(
  withAuthorization(authUser => authUser && authUser.roles.includes(ROLES.ADMIN))(() => (
    <>
      <h1>Admin</h1>

      <Switch>
        <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
        <Route exact path={ROUTES.ADMIN} component={Admin} />
      </Switch>
    </>
  ))
)

function Admin() {
  return (
    <>
      <UserList />
      <ProjectList add={false} />
    </>
  )
}
