import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'
import { Dialog } from '@blueprintjs/core'
import { find_entity, entity_subtypes } from '../Schema'
import { entity_instance_name_attrs, entity_instance_name } from '../Data'
import { DialogBody, DialogFooter } from '../Parts'
import { HUE_NAME } from '.'
import {
  Button,
  FormInput,
  FormSwitch,
  FormButtons,
  FormSelect,
  // FormMultiSelect,
  Hue,
  Tooltip,
  color
} from '.'

//--------------------------------------------------------------------------------------------------

export function RecycleButton({ state, setState, tip }) {
  return (
    <Tooltip content={tip || 'Show hidden'}>
      <Button
        hue="orange"
        bright
        icon="eye"
        active={state}
        onClick={() => setState(!state)}
        alignRight
      />
    </Tooltip>
  )
}

//--------------------------------------------------------------------------------------------------

type PartProps<T> = {
  value: T
  setValue: (t: T) => void
}

export function Name({ value, setValue }: PartProps<string>) {
  return (
    <FormInput
      type="text"
      label="Name"
      info="(required)"
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  )
}

//--------------------------------------------------------------------------------------------------

export function Desc({ value, setValue }: PartProps<string>) {
  return (
    <FormInput
      type="textarea"
      label="Description"
      value={value || ''}
      onChange={e => setValue(e.target.value === '' ? null : e.target.value)}
    />
  )
}

//--------------------------------------------------------------------------------------------------

export function Choices({ value, setValue }: PartProps<string[]>) {
  return (
    <FormInput
      type="textarea"
      label="Choices"
      info="(one per line)"
      value={(value || []).join('\n')}
      onChange={e => setValue(e.target.value.split('\n'))}
    />
  )
}

//--------------------------------------------------------------------------------------------------

export function Verb({ value, setValue }: PartProps<string>) {
  return (
    <FormInput
      type="text"
      label="Relationship"
      info="(required)"
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  )
}

//--------------------------------------------------------------------------------------------------

export function IsPublic({ value, setValue }: PartProps<boolean>) {
  return <FormSwitch label="Is Public" checked={value} onChange={setValue} full />
}

//--------------------------------------------------------------------------------------------------

export function IsRequired({ value, setValue }: PartProps<boolean>) {
  // @ts-ignore
  return <FormSwitch label="Is Required" checked={value} onChange={setValue} full />
}

//--------------------------------------------------------------------------------------------------

export function IsName({ value, setValue }: PartProps<boolean>) {
  return <FormSwitch label="Is Used as Part of Name" checked={value} onChange={setValue} full />
}

//--------------------------------------------------------------------------------------------------

export function IsOrder({ value, setValue }: PartProps<boolean>) {
  return (
    <FormSwitch label="Is Used to Order Relationships" checked={value} onChange={setValue} full />
  )
}

//--------------------------------------------------------------------------------------------------

export function IsSorter({ value, setValue }: PartProps<boolean>) {
  return <FormSwitch label="Is Used to Sort Instances" checked={value} onChange={setValue} full />
}

//--------------------------------------------------------------------------------------------------

export function PickHue({ value, setValue }: PartProps<Hue>) {
  return (
    <FormSelect
      label="Colour"
      value={value ? Number(value) : undefined}
      options={[{ label: 'Select a Colour...', value: '' }].concat(
        HUE_NAME.map(h => ({ label: h.name, value: h.val }))
      )}
      onChange={setValue}
      width="150px"
    />
  )
}

//--------------------------------------------------------------------------------------------------

type CancelOrCreateProps = {
  onCancel: () => void
  onCreate: () => void
  isInvalid?: boolean
}

export function CancelOrCreate({ onCancel, onCreate, isInvalid = false }: CancelOrCreateProps) {
  return (
    <FormButtons>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onCreate} hue={Hue.Green} disabled={isInvalid} margin="0 0 0 5px">
        Create
      </Button>
    </FormButtons>
  )
}

//--------------------------------------------------------------------------------------------------

type CancelOrSaveProps = {
  onCancel: () => void
  onSave: () => void
  isInvalid?: boolean
}

export function CancelOrSave({ onCancel, onSave, isInvalid }: CancelOrSaveProps) {
  return (
    <FormButtons>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onSave} hue={Hue.Green} disabled={isInvalid} margin="0 0 0 5px">
        Save
      </Button>
    </FormButtons>
  )
}

//--------------------------------------------------------------------------------------------------

type DeleteCancelOrSaveProps = {
  onDelete: () => void
  onCancel: () => void
  onSave: () => void
  isInvalid: boolean
  willDelete: string
}

export function DeleteCancelOrSave({
  onDelete,
  onCancel,
  onSave,
  isInvalid,
  willDelete
}: DeleteCancelOrSaveProps) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <FormButtons>
        <Button onClick={() => setOpen(true)} hue={Hue.Red}>
          Delete
        </Button>
        <Button onClick={onCancel} margin="0 0 0 5px">
          Cancel
        </Button>
        <Button onClick={onSave} hue={Hue.Green} disabled={isInvalid} margin="0 0 0 5px">
          Save
        </Button>
      </FormButtons>

      <Dialog title="Confirm Delete" isOpen={open}>
        <DialogBody style={{ background: color(Hue.Red, 4), padding: '5px', textAlign: 'center' }}>
          <h3>This will permanently delete {willDelete}!</h3>
          <p>Are you sure you want to continue?</p>
        </DialogBody>

        <DialogFooter>
          <FormButtons>
            <Button onClick={cancel}>Cancel</Button>
            <Button onClick={confirm} hue={Hue.Red} margin="0 0 0 5px">
              Delete
            </Button>
          </FormButtons>
        </DialogFooter>
      </Dialog>
    </>
  )

  function cancel() {
    setOpen(false)
    onCancel()
  }

  function confirm() {
    setOpen(false)
    onDelete()
  }

  // function confirm_delete() {
  //   if (confirm('Are you sure?')) {
  //     onDelete()
  //   }
  // }
}

//--------------------------------------------------------------------------------------------------

// export function CollabIDs({ collab_ids, setCollabIDs, users }) {
//   const value = (collab_ids || []).map(id => {
//     const user = users.find(user => user.id === id)

//     return {
//       value: id,
//       label: user ? user.username : id
//     }
//   })
//   const options = (users || []).map(user => ({ value: user.id, label: user.username }))

//   function onChange(value) {
//     const ids = (value || []).map(val => val.value)
//     setCollabIDs(ids)
//   }

//   return (
//     <FormMultiSelect label="Collaborators" values={value} options={options} onChange={onChange} />
//   )
// }

//--------------------------------------------------------------------------------------------------

// export function IsAs({ value, setValue, entities }) {
//   const ids = (value || []).map(id => {
//     const entity = entities.find(entity => entity.id === id)

//     return {
//       value: id,
//       label: entity ? entity.name : id
//     }
//   })
//   const options = (entities || []).map(entity => ({ value: entity.id, label: entity.name }))

//   function onChange(value) {
//     const ids = (value || []).map(val => val.value)
//     setValue(ids)
//   }

//   return <FormMultiSelect label="Is A" values={ids} options={options} onChange={onChange} />
// }

//--------------------------------------------------------------------------------------------------

export function EntityID({ label, id, setID, entities }) {
  entities = entities || []

  return (
    <FormSelect
      label={label}
      info="(required)"
      value={id}
      options={[{ label: 'Select an Entity...', value: '' }].concat(
        sortBy(entities.filter(e => !e.hidden), 'name').map(e => ({ label: e.name, value: e.id }))
      )}
      onChange={setID}
    />
  )
}

//--------------------------------------------------------------------------------------------------

export function EntityInstanceID({ project, entity_id, id, setID, instances }) {
  if (!instances) {
    return null
  }

  const entity = find_entity(project, entity_id)
  const entities = [entity, ...entity_subtypes(project, entity)]
  const name_attrs = Array.from(
    new Set([].concat(...entities.map(e => entity_instance_name_attrs(project, e))))
  )

  const entity_name = entity ? entity.name : `<Entity ${entity_id}>`
  const a_an = 'aeiou'.includes(entity_name[0].toLowerCase()) ? 'an' : 'a'
  const options = [{ label: `Select ${a_an} ${entity_name}...`, value: '' }].concat(
    sortBy(
      (instances || []).map(instance => ({
        label: entity_instance_name(entity, instance, name_attrs),
        value: instance.id
      })),
      'label'
    )
  )

  return (
    <FormSelect
      label={entity_name}
      info="(required)"
      value={id}
      options={options}
      onChange={setID}
      width="100%"
    />
  )
}
