import React, { useState, useEffect } from 'react'
import { useFirebase } from '../Firebase'
import { ProjectList } from '../Projects'

export function UserItem({ match: { params }, location }) {
  const firebase = useFirebase()

  const [loading, setLoading] = useState(false)
  const [user, setUser] = useState(location.state || null)

  if (!user) {
    useEffect(() => {
      setLoading(true)

      return firebase.user(params.id).onSnapshot(snapshot => {
        setUser(snapshot.data())
        setLoading(false)
      })
    }, [])
  }

  return (
    <div>
      <h2>User: {user ? user.user.username : this.props.match.params.id}</h2>
      {loading ? <div>Loading ...</div> : user ? <User /> : null}
    </div>
  )

  function User() {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Username:</strong>
              </td>
              <td>{user.user.username}</td>
            </tr>
            <tr>
              <td>
                <strong>E-Mail:</strong>
              </td>
              <td>{user.user.email}</td>
            </tr>
            <tr>
              <td>
                <strong>ID:</strong>
              </td>
              <td>{user.user.id}</td>
            </tr>
            <tr>
              <td>
                <strong>Roles:</strong>
              </td>
              <td>{(user.user.roles || []).join(', ')}</td>
            </tr>
            <tr>
              <td style={{ verticalAlign: 'top' }}>
                <strong>Projects:</strong>
              </td>
              <td>
                <ProjectList user={user.user} title="" add={false} />
              </td>
            </tr>
          </tbody>
        </table>

        <button type="button" onClick={() => firebase.doPasswordReset(user.user.email)}>
          Send Password Reset
        </button>
      </div>
    )
  }
}
