import React, { useState } from 'react'
import { combineLatest } from 'rxjs'
import { Dialog } from '@blueprintjs/core'
import { useObservable, watch, update, erase } from '../Firebase'
import { DialogBody, DialogFooter, Error } from '../Parts'
import { DeleteCancelOrSave, EntityInstanceID } from '../UI'
import { AttributeValueUI } from '../Schema/AttributeValueUI'
import { relation_name, data_collection_name, find_entity, entity_subtypes } from '../Schema'

//--------------------------------------------------------------------------------------------------

export function EditRelationInstanceDialog({ project, type, object, setOpen }) {
  const subj_class = find_entity(project, type.subj_id)
  const subj_classes = [subj_class, ...entity_subtypes(project, subj_class)]
  const subj_data = subj_classes.map(({ id }) => watch(data_collection_name(project.id, id), []))

  const dobj_class = find_entity(project, type.dobj_id)
  const dobj_classes = [dobj_class, ...entity_subtypes(project, dobj_class)]
  const dobj_data = dobj_classes.map(({ id }) => watch(data_collection_name(project.id, id), []))

  const subjs = useObservable(combineLatest(...subj_data, combiner))
  const dobjs = useObservable(combineLatest(...dobj_data, combiner))

  function combiner(...lists) {
    return [].concat(...lists)
  }

  const [error, setError] = useState(null)
  const [data, updateData] = useState(object)

  function setData(key, value) {
    updateData(data => ({ ...data, [key]: value }))
  }
  function setSubjID(value) {
    updateData(data => ({ ...data, subj_id: value }))
  }
  function setDobjID(value) {
    updateData(data => ({ ...data, dobj_id: value }))
  }

  let isInvalid =
    data.subj_id == null ||
    data.subj_id.length < 1 ||
    data.dobj_id == null ||
    data.dobj_id.length < 1

  const attrs = (type.attrs || []).filter(a => !a.hidden)

  for (const attr of attrs) {
    const value = data[attr.id]
    if ((attr.is_required || attr.is_name) && (value == null || value.length < 1)) {
      isInvalid |= true
    }
  }

  return (
    <Dialog
      title={'Edit ' + relation_name(project, type)}
      icon="edit"
      isOpen={true}
      onClose={close}
    >
      <DialogBody>
        <EntityInstanceID
          project={project}
          entity_id={type.subj_id}
          id={data.subj_id}
          setID={setSubjID}
          instances={subjs}
        />
        <EntityInstanceID
          project={project}
          entity_id={type.dobj_id}
          id={data.dobj_id}
          setID={setDobjID}
          instances={dobjs}
        />
        {attrs.map(attr => (
          <AttributeValueUI key={attr.id} attr={attr} data={data} setData={setData} />
        ))}
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <DeleteCancelOrSave
          isInvalid={isInvalid}
          onCancel={close}
          onSave={save}
          onDelete={remove}
          willDelete="this relationship"
        />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
    updateData({})
  }

  function save() {
    update(data_collection_name(project.id, type.id), object.id, data)
      .then(close)
      .catch(setError)
  }

  function remove() {
    erase(data_collection_name(project.id, type.id), object.id)
      .then(close)
      .catch(setError)
  }
}
