import React, { useEffect } from 'react'
import useReactRouter from 'use-react-router'

import { useAuthUser } from './context'
import { useFirebase } from '../../Firebase'
import * as ROUTES from '../../../constants/routes'

export const withAuthorization = condition => Component => props => {
  const { history } = useReactRouter()
  const firebase = useFirebase()
  const user = useAuthUser()

  useEffect(() => {
    if (!condition(user)) {
      history.push(ROUTES.SIGN_IN)
    }
    firebase.onAuthUserListener(authUser => {
      if (!condition(authUser)) {
        history.push(ROUTES.SIGN_IN)
      }
    })
  }, [])

  return condition(user) ? <Component {...props} user={user} /> : 'Go to signin page.'
}
