import React, { ReactNode } from 'react'
import { Row } from './Row'

//------------------------------------------------------------------------------

export function FormButtons({ children }: { children: ReactNode }) {
  return (
    <Row padding="10px 0 0 0" justify="right">
      {children}
    </Row>
  )
}
