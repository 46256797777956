// @ts-check

import React from 'react'
import { NewStepDialog, EditStepDialog } from '.'
import { useAuthUser } from '../Users/Session'
import { Table, FormItem } from '../UI'
import { show_step } from './steps'

//--------------------------------------------------------------------------------------------------

/**
 * A table of query steps
 * @param {object} props
 * @param {import("./types").QueryStep[]} props.value
 * @param {React.Dispatch<React.SetStateAction<import("./types").QueryStep[]>>} props.setValue
 * @param {import("../types").Project} props.project
 */

export function StepListUI({ value, setValue, project }) {
  const user = useAuthUser()

  return (
    <FormItem
      label="Steps"
      info="(required)"
      // help="Drag a row to change order of attributes."
    >
      <Table
        // ident="entities"
        width="100%"
        {...{ user }}
        // showHue
        AddDialog={NewStepDialog}
        addProps={{ steps: value, setSteps: setValue, project }}
        addTip="Create new step"
        EditDialog={EditStepDialog}
        editProps={{ setSteps: setValue, project }}
        editTip="Edit this step"
        // showTip="Show hidden attributes"
        // hideTip="this attribute"
        cols={[{ label: 'Name', cell: step => show_step(project, step) }]}
        objs={value}
        update={() => setValue(value)}
        isItem
        // onDragEnd={onDragEnd}
      />
    </FormItem>
  )
}
