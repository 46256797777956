import React, { Component } from 'react'
import { gray, color } from '../../UI'

//--------------------------------------------------------------------------------------------------

function stack_texts(x, y, txt, dy, sep = ' ') {
  let txts = txt.split(sep)
  return txts.map((txt, i) => (
    <text key={i} x={x} y={y + 4 + i * dy - ((txts.length - 1) * dy) / 2} textAnchor="middle">
      {txt}
    </text>
  ))
}

//--------------------------------------------------------------------------------------------------

// export class NodeText extends Component {
//   render() {
//     const { node } = this.props
//     const txts = stack_texts(node.x, node.y, node.name || node.verb, 16)
//     return (
//       <g className={node.label}>
//         <g className="casing">{txts}</g>
//         <g>{txts}</g>
//       </g>
//     )
//   }
// }

//--------------------------------------------------------------------------------------------------

export class Node extends Component {
  mouse_down = false
  dragging = false

  render() {
    const { node, r, selection_id } = this.props
    const txts = stack_texts(node.x, node.y, node.name || node.verb, 16)
    const circle_fill =
      node.label === 'relation' ? '#ddd' : node.hue ? color(node.hue | 0, 4, 4) : gray(4)
    const text_fill = node.label === 'relation' ? '#888' : 'black'
    const weight = node.label === 'relation' ? 'normal' : 'bold'

    return (
      <g
        key={node.id}
        onMouseDown={this.handleMouseDown}
        fontFamily="'Nunito Sans', sans-serif"
        fontSize="20px"
      >
        {node.id === selection_id ? (
          <circle cx={node.x} cy={node.y} r={r + 8} fill="#0ff" stroke="none" />
        ) : null}
        <circle
          cx={node.x}
          cy={node.y}
          r={r}
          fill={circle_fill}
          stroke={node.label === 'entity' ? '#fff' : 'none'}
          strokeWidth="7px"
        />
        <g stroke="#fff" strokeWidth="5px" cursor="pointer">
          {txts}
        </g>
        <g fill={text_fill} fontWeight={weight} cursor="pointer">
          {txts}
        </g>
      </g>
    )
  }

  handleMouseDown = e => {
    // console.log('handleMouseDown', this)

    e.preventDefault()
    e.stopPropagation()

    this.coords = {
      x: e.pageX,
      y: e.pageY
    }
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('mouseup', this.handleMouseUp)

    this.mouse_down = true
    // this.props.onDragStart(this.props.node)
  }

  handleMouseUp = e => {
    // console.log('handleMouseUp', this)

    e.preventDefault()
    e.stopPropagation()

    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('mouseup', this.handleMouseUp)
    this.coords = {}

    if (this.dragging) {
      this.props.onDragEnd(e, this.props.node)
      this.dragging = false
    } else {
      // console.log('onClick', this.props.node)
      this.props.onClick(e, this.props.node)
    }
  }

  handleMouseMove = e => {
    // console.log('handleMouseMove', this)

    e.preventDefault()
    e.stopPropagation()

    const dx = this.coords.x - e.pageX
    const dy = this.coords.y - e.pageY

    if (this.mouse_down && !this.dragging && (dx || dy)) {
      this.dragging = true
      this.props.onDragStart(e, this.props.node)
    }

    if (this.dragging) {
      this.coords.x = e.pageX
      this.coords.y = e.pageY

      this.props.onDrag(e, this.props.node, dx, dy)
    }
  }
}
