import React, { useState } from 'react'
import styled from 'styled-components'
import { entity_instance_hue } from '../../Data'
import { gray, color, gray3, color3, Col, Row } from '../../UI'
import { DiscsDiagram, Tooltip } from '..'

//--------------------------------------------------------------------------------------------------

const Top = styled.div`
  width: ${props => props.width}px;
  padding: 6px;
  color: white;
  height: 30px;
  text-align: center;
`

const LayerName = styled(Top)`
  background: ${props =>
    props.layer.entity.hue ? color(Number(props.layer.entity.hue), 2, 4) : gray(3)};
`

const Sep = styled.div`
  background: white;
  width: 2px;
`

const Selection = styled(Top)`
  background: ${props => (props.selected ? '#aff' : 'white')};
  color: black;
  text-align: center;
  width: 100%;
  transition: 0.3s;
`

//--------------------------------------------------------------------------------------------------

export function DiscsView({
  project,
  layer_defs,
  link_defs,
  selection_id,
  setSelectionID,
  setSelectionEntity,
  caption,
  fadeOrphans
}) {
  const [hovered, setHovered] = useState(null)
  const [linked_ids, setLinkedIDs] = useState(new Set())
  const [selected_layer_index, setSelectedLayerIndex] = useState(-1)

  const layers = layer_defs.map(def => {
    let index = def.index

    if (!def.index) {
      index = {}

      for (const instance of def.instances) {
        index[instance.id] = instance
      }
    }

    return { name: def.name, entity: def.entity, instances: def.instances, index }
  })

  const links = link_defs.map(def => {
    return { relation: def.relation, instances: def.instances }
  })

  //................................................................................................

  function instance_name(index, id) {
    const instance = index[id]

    if (instance) {
      return instance._name
    }

    return `<instance ${id.subj_id}>`
  }

  function hovered_name() {
    if (hovered) {
      for (const layer of layers) {
        if (hovered.id in layer.index) {
          return instance_name(layer.index, hovered.id)
        }
      }
    }
    return ''
  }

  function selected_name() {
    if (selection_id) {
      for (const layer of layers) {
        if (selection_id in layer.index) {
          return `${layer.name}: ${instance_name(layer.index, selection_id)}`
        }
      }
    }
    return ''
  }

  function discOver(pt, entity_instance) {
    setHovered({
      id: entity_instance.id,
      coords: {
        left: pt.x,
        top: pt.y - 5
      }
    })
  }

  function discLeave() {
    setHovered(null)
  }

  function discColor(entity_instance, layer_index, depth) {
    const foo = linked_ids.has(`${layer_index}:${entity_instance.id}`)

    if (foo && layer_index !== selected_layer_index) {
      return { color: 0xdddd, opacity: 1, transparent: false }
    }

    if (entity_instance.id === selection_id && layer_index === selected_layer_index) {
      return { color: 0xffff, opacity: 1, transparent: false }
    }

    let opacity = fadeOrphans ? 0.5 : 1
    let transparent = fadeOrphans

    for (let i = 0; i < layers.length; i++) {
      if (layer_index === i && entity_instance.id in layers[i].index) {
        if (i < links.length && links[i].instances.find(r => r.subj_id === entity_instance.id)) {
          opacity = 1
          transparent = false
        }
        if (
          i > 0 &&
          i - 1 < links.length &&
          links[i - 1].instances.find(r => r.dobj_id === entity_instance.id)
        ) {
          opacity = 1
          transparent = false
        }

        return {
          color: color3(entity_instance_hue(project, entity_instance), 4, 4),
          opacity,
          transparent
        }
      }
    }
    return { color: gray3(4), opacity, transparent }
  }

  function linkColor(link, links_index) {
    if (
      ((link.datum.subj_id === selection_id && selected_layer_index === links_index) ||
        linked_ids.has(`${links_index}:${link.datum.subj_id}`)) &&
      ((link.datum.dobj_id === selection_id && selected_layer_index === links_index + 1) ||
        linked_ids.has(`${links_index + 1}:${link.datum.dobj_id}`))
    ) {
      return {
        color: 0xffff,
        opacity: 1,
        transparent: false
      }
    }

    const relation = links[links_index].relation
    return {
      color: relation.hue ? color3(relation.hue, 4, 6) : gray3(4),
      opacity: 0.5,
      transparent: true
    }
  }

  function union(a, b) {
    return new Set([...a, ...b])
  }

  function findLinkedIDsAbove(layer_index, entity_instance_id) {
    let ids = new Set()

    if (layer_index > 0) {
      links[layer_index - 1].instances
        .filter(r => r.dobj_id === entity_instance_id)
        .forEach(r => {
          ids.add(`${layer_index - 1}:${r.subj_id}`)
          if (layer_index > 1) {
            ids = union(ids, findLinkedIDsAbove(layer_index - 1, r.subj_id))
          }
        })
    }

    return ids
  }

  function findLinkedIDsBelow(layer_index, entity_instance_id) {
    let ids = new Set()

    if (layer_index < layers.length - 1) {
      links[layer_index].instances
        .filter(r => r.subj_id === entity_instance_id)
        .forEach(r => {
          ids.add(`${layer_index + 1}:${r.dobj_id}`)
          if (layer_index < layers.length - 2) {
            ids = union(ids, findLinkedIDsBelow(layer_index + 1, r.dobj_id))
          }
        })
    }

    return ids
  }

  function discClick(entity_instance, layer_index) {
    if (selection_id === entity_instance.id) {
      setSelectionID(null)
      setSelectionEntity(null)
      setLinkedIDs(new Set())
      setSelectedLayerIndex(-1)
    } else {
      setSelectionID(entity_instance.id)
      setSelectedLayerIndex(layer_index)

      if (layer_index < layers.length) {
        const layer = layers[layer_index]

        if (entity_instance.id in layer.index) {
          setSelectionEntity(layer.entity)

          const ids = union(
            findLinkedIDsAbove(layer_index, entity_instance.id),
            findLinkedIDsBelow(layer_index, entity_instance.id)
          )
          setLinkedIDs(ids)
        }
      }
    }
  }

  const width = 800
  const n = layers.length

  //................................................................................................

  return (
    <Col>
      <Row>
        <LayerName width={width / n - 1} layer={layers[0]}>
          {layers[0].name}
        </LayerName>
        {layers.slice(1).map((layer, i) => (
          <div key={i}>
            <Sep />
            <LayerName width={width / n - 1} layer={layer}>
              {layer.name}
            </LayerName>
          </div>
        ))}
      </Row>
      <Selection selected={!!selection_id}>{selected_name()}</Selection>

      <DiscsDiagram
        size={width}
        layers={layers.map(layer => Object.values(layer.index))}
        relations={links.map(l => ({ type: l.relation, instances: l.instances }))}
        {...{ discColor, discOver, discLeave, discClick, linkColor }}
      />

      {caption}
      {hovered && <Tooltip coords={hovered.coords} content={hovered_name()} />}
    </Col>
  )
}
