// @ts-check

import React from 'react'
import sortBy from 'lodash/sortBy'
import { relation_name } from '../Schema'
import { SelectIDs } from '.'

//--------------------------------------------------------------------------------------------------

/**
 * A set of relation IDs
 * @param {object} props
 * @param {import("../types").ID[]} props.value
 * @param {(ids:import("../types").ID[]) => void} props.setValue
 * @param {import("../types").Project} props.project
 * @param {import("../types").Relation[]} [props.relations]
 */

export function SetOfRelationsUI({ value, setValue, project, relations }) {
  return (
    <SelectIDs
      label="Relations"
      ids={value}
      setIDs={setValue}
      items={sortBy(relations || project.relations, rel_name)}
      item_name={rel_name}
    />
  )

  function rel_name(relation) {
    return relation_name(project, relation)
  }
}
