import { entity_attrs, entity_hue, entity_subtypes, find_entity } from '../Schema'

export function entity_instance_name_attr(project, entity) {
  return entity_instance_name_attrs(project, entity)[0]
}

export function entity_instance_name_attrs(project, entity) {
  return entity_attrs(project, entity).filter(a => a.is_name)
}

export function entity_instance_name(entity, instance, attrs) {
  attrs = attrs || (entity.attrs || []).filter(a => a.is_name)
  if (attrs.length < 1) {
    return `<${entity.name} ${instance.id}>`
  }
  return attrs
    .map(a => instance[a.id])
    .filter(n => n != null && n.length > 0)
    .join(' : ')
}

export function entity_instance_sort_attrs(project, entity) {
  const attrs = entity_attrs(project, entity).filter(a => a.is_sort)
  if (attrs.length > 0) {
    return attrs
  }
  return entity_instance_name_attrs(project, entity)
}

// export function entity_instance_sort_name(entity, instance, attrs) {
//   attrs = attrs || (entity.attrs || []).filter(a => a.is_name)
//   if (attrs.length < 1) {
//     return `<${entity.name} ${instance.id}>`
//   }
//   return attrs
//     .map(a => instance[a.id])
//     .filter(n => n != null && n.length > 0)
//     .join(' : ')
// }

// export function entity_instance_name(entity, instance) {
//   const attr = (entity.attrs || []).find(a => a.is_name)
//   return attr ? instance[attr.id] : `<${entity.name} ${instance.id}>`
// }

export function entity_instance_hue(project, instance) {
  if (project && instance && instance.entity) {
    return entity_hue(project, instance.entity)
  }
  return 0
}

//--------------------------------------------------------------------------------------------------
import { combineLatest } from 'rxjs'
import { watch } from '../Firebase'
import { data_collection_name, find_relation } from '../Schema'

export function entity_item$(project, entity_id) {
  const entity = find_entity(project, entity_id)
  const entities = entity ? [entity, ...entity_subtypes(project, entity)] : []
  const entity_name_attrs = entities.map(e => entity_instance_name_attrs(project, e))
  const entity_sort_attrs = entities.map(e => entity_instance_sort_attrs(project, e))
  const data = entities.map(({ id }) => watch(data_collection_name(project.id, id), []))

  return combineLatest(...data, (...lists) => {
    const result = []

    lists.forEach((list, i) => {
      const entity = entities[i]

      result.push(
        ...list.map(d => ({
          ...d,
          entity,
          _name: entity_instance_name(entity, d, entity_name_attrs[i]),
          _sort: entity_instance_name(entity, d, entity_sort_attrs[i])
        }))
      )
    })

    return result
  })
}

export function relation_item$(project, relation_id, opts) {
  // opts = opts || {get_subjs: true, get_dobjs: true, reverse: false}

  const relation = find_relation(project, relation_id)

  return combineLatest(
    watch(data_collection_name(project.id, relation_id)),
    entity_item$(project, relation.subj_id),
    entity_item$(project, relation.dobj_id),
    (rels, subjs = [], dobjs = []) => {
      const subj_index = {}
      const dobj_index = {}

      subjs.forEach(subj => (subj_index[subj.id] = subj))
      dobjs.forEach(dobj => (dobj_index[dobj.id] = dobj))

      return rels.map(rel => ({
        ...rel,
        subj: subj_index[rel.subj_id],
        dobj: dobj_index[rel.dobj_id]
      }))
    }
  )
}

export function entities_item$(project, entity_ids) {
  return combineLatest(
    ...entity_ids.map(entity_id => entity_item$(project, entity_id)),
    (...ents_items) => ents_items
  )
}

//--------------------------------------------------------------------------------------------------
import { useObservable, index_of } from '../Firebase'

export function useEntityInstanceIndex(project, entity) {
  return useObservable(index_of(entity_item$(project, entity.id)), {})
}
