import React from 'react'

import { withAuthorization, withEmailVerification } from './Session'
import { ProjectList } from '../Projects'

export const HomePage = withEmailVerification(
  withAuthorization(authUser => authUser != null)(({ user }) => {
    return (
      <>
        <h1>Welcome {user.username}</h1>
        <ProjectList user={user} />
      </>
    )
  })
)
