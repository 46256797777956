import React, { useState } from 'react'
import { FormInput, Button } from '.'

//------------------------------------------------------------------------------

interface FormPasswordProps {
  value: string
  label: string
  info?: string
  help?: string
  onChange: (new_value: string) => void
}

export function FormPassword({ value, onChange, label, info, help }: FormPasswordProps) {
  // from https://github.com/palantir/blueprint/blob/develop/packages/docs-app/src/examples/core-examples/inputGroupExample.tsx
  const [showPassword, setShowPassword] = useState(false)

  const lockButton = (
    <Button
      icon={showPassword ? 'eye-slash' : 'eye'}
      outline
      onClick={() => setShowPassword(!showPassword)}
    />
  )

  return (
    <FormInput
      label={label || 'Password'}
      info={info || '(required)'}
      help={help}
      value={value}
      onChange={e => onChange(e.target.value)}
      type={showPassword ? 'text' : 'password'}
      rightElement={lockButton}
    />
  )
}
