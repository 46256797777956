import React from 'react'
import { entities_item$ } from '../Data'
import { find_entity } from '../Schema'
import { useObservable } from '../Firebase'
import { DiscsView } from '.'

export function QueryDiscs({
  project,
  result,
  items = [],
  selection_id,
  setSelectionID,
  setSelectionEntity,
  caption,
  fadeOrphans
}) {
  const cols = result.cols || [] //.filter(c => !c.hidden && c.entity_ids)

  if (cols.length < 2) {
    return <p>Not enough columns</p>
  }

  if (items.length === 0) {
    return <p>Data not available.</p>
  }

  const col_items = useObservable(entities_item$(project, cols.map(col => col.entity_ids[0])), [])

  if (col_items.length === 0) {
    return <p>Loading...</p>
  }

  const layer_defs = cols.map((col, i) => {
    const entity = find_entity(project, col.entity_ids[0])

    return { name: col.name || entity.name, entity, instances: col_items[i] }
  })

  const link_defs = cols.slice(1).map((col, i) => ({
    relation: {},
    instances: items.map(item => ({
      id: item[i].value.id + ':' + item[i + 1].value.id,
      subj_id: item[i].value.id,
      dobj_id: item[i + 1].value.id
    }))
  }))

  //................................................................................................

  return (
    <DiscsView
      {...{
        project,
        layer_defs,
        link_defs,
        selection_id,
        setSelectionID,
        setSelectionEntity,
        caption,
        fadeOrphans
      }}
    />
  )
}
