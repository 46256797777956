import React, { useState } from 'react'
import { Button, FormInput, FormButtons, FormError, Panel } from '../UI'

import { useFirebase } from '../Firebase'

//------------------------------------------------------------------------------

export const PasswordForgetForm = props => {
  const firebase = useFirebase()

  const [email, setEmail] = useState(props.email || '')
  const [error, setError] = useState(null)

  const isInvalid = email === ''

  return (
    <Panel>
      <FormInput
        label="Email Address"
        info="(required)"
        help="This should be the email address you use to sign-in with."
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />

      <FormButtons>
        <Button disabled={isInvalid} onClick={onSubmit}>
          Request Password Reset
        </Button>
      </FormButtons>

      <FormError error={error ? error.message : null} />
    </Panel>
  )

  function onSubmit(event) {
    firebase.doPasswordReset(email).catch(setError)

    event.preventDefault()
  }
}
