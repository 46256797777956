import React from 'react'
import { Icon, Row, Col } from '.'

//------------------------------------------------------------------------------

export function FormError({ error }: { error: string }) {
  return error ? (
    <Col padding="10px 0 0 0">
      <Row padding="0.5em" color="#f44336" border="1px solid #f44336" radius="4px">
        <Icon icon="exclamation-triangle" color="#f44336" big padding="2px 8px 0 0" />
        {error}
      </Row>
    </Col>
  ) : null
}
