import React, { useEffect, useState } from 'react'

import { AuthUserContext } from './context'
import { useFirebase } from '../../Firebase'

export const withAuthentication = Component => props => {
  const firebase = useFirebase()
  const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem('authUser')))

  useEffect(
    () =>
      firebase.onAuthUserListener(
        authUser => {
          localStorage.setItem('authUser', JSON.stringify(authUser))
          setAuthUser(authUser)
        },
        () => {
          localStorage.removeItem('authUser')
          setAuthUser(null)
        }
      ),
    []
  )

  return (
    <AuthUserContext.Provider value={authUser}>
      <Component {...props} />
    </AuthUserContext.Provider>
  )
}
