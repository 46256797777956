import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useFirebase } from '../Firebase'
import { Table } from '../UI'
import * as ROUTES from '../../constants/routes'

export function UserList() {
  const firebase = useFirebase()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    return firebase.users().onSnapshot(snapshot => {
      setUsers(snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
      setLoading(false)
    })
  }, [])

  return (
    <div>
      <h2>Users</h2>
      {loading ? <div>Loading ...</div> : users.length > 0 ? <UserTable users={users} /> : null}
    </div>
  )
}

function UserTable({ users }) {
  const cols = [
    {
      label: 'User Name',
      cell: user => (
        <Link
          to={{
            pathname: `${ROUTES.ADMIN}/${user.id}`,
            state: { user }
          }}
        >
          {user.username}
        </Link>
      )
    },
    { label: 'E-Mail Address', cell: user => user.email }
  ]

  return <Table cols={cols} objs={users} />
}
