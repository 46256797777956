export enum Hue {
  Gray = 0,
  // Red: 15,
  // Orange: 45,
  // Yellow: 75,
  // Lime: 105,
  // Green: 135,
  // Teal: 165,
  // Cyan: 195,
  // Sky: 225,
  // Blue: 255,
  // Purple: 285,
  // Magenta: 315,
  // Pink: 345
  Red = 10,
  Orange = 30,
  Amber = 45,
  Yellow = 55,
  Lime = 73,
  Green = 95,
  Opal = 160,
  Cyan = 185,
  Azure = 200,
  Blue = 230,
  Violet = 270,
  Purple = 290,
  Pink = 320
}
