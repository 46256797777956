import React, { useState } from 'react'
import styled from 'styled-components'
import { findAndUpdate } from '../Firebase'
import { data_collection_name } from '../Schema'
import { gray, color, Tooltip, Icon } from '.'

import './Table.css'

//--------------------------------------------------------------------------------------------------

// const Edit = styled.div`
//   cursor: pointer;
//   text-align: center;
//   padding: 2px;
//   color: ${() => gray(3)};
//   width: 22px;
//   height: 22px;

//   div:hover {
//     color: white;
//     background: ${() => gray(3)};
//     transition: 0.2s;
//   }
// `
const RowBtn = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  color: ${() => gray(3)};
  width: 22px;
  height: 22px;

  div:hover {
    color: white;
    background: ${() => gray(3)};
    transition: 0.2s;
  }
`

const EditBtn = React.memo(({ datum, editTip, setEditObject, setEditDialogIsOpen }) => {
  return (
    <Tooltip content={editTip}>
      <RowBtn
        onClick={() => {
          setEditObject(datum)
          setEditDialogIsOpen(true)
        }}
      >
        <Icon icon="edit" />
      </RowBtn>
    </Tooltip>
  )
})

//--------------------------------------------------------------------------------------------------

const TblCell = styled.td`
  border-bottom: 1px solid ${() => gray(5)};
  vertical-align: top;
  padding: 4px 4px 4px 4px;
  border-right: 1px solid ${() => gray(5)};

  td:first-child {
    padding-left: 4px;
  }
  td:last-child {
    padding-right: 4px;
  }
`

const Cell = React.memo(({ children, datum, style, selection_id, setSelectionID }) => {
  return (
    <TblCell
      style={style}
      onClick={() => setSelectionID && setSelectionID(datum.id === selection_id ? null : datum.id)}
    >
      {children}
    </TblCell>
  )
})

//--------------------------------------------------------------------------------------------------

const TblRow = styled.tr.attrs(({ datum, selection_id }) => ({
  style: {
    background: datum.id && datum.id === selection_id ? '#aff' : 'none'
  }
}))`
  border-bottom: 1px solid ${() => gray(5)};

  td {
    padding: 4px 4px 0 4px;
    vertical-align: top;
  }
  td:first-child {
    padding-left: 4px;
  }
  td:last-child {
    padding-right: 4px;
  }
`

const RowAction = styled.td`
  text-align: center;
  width: 22px;
  border: none;
  border-bottom: 1px solid ${() => gray(5)};
  padding-top: 2px;
`

function HideBtn({ project, type, datum, hideTip }) {
  // const [hidden, setHidden] = useState(datum.hidden)

  return (
    <Tooltip content={(datum.hidden ? 'Show ' : 'Hide ') + hideTip}>
      <RowBtn
        onClick={() => {
          datum.hidden = !datum.hidden
          findAndUpdate(data_collection_name(project.id, type.id), datum)
          // const { id, ...data } = datum
          // type && dbUpdate(type.id, id, data)
          // setHidden(!hidden)
        }}
      >
        <Icon icon={datum.hidden ? 'eye' : 'eye-slash'} />
      </RowBtn>
    </Tooltip>
  )
}

const Row = React.memo(
  ({
    datum,
    project,
    type,
    selection_id,
    setSelectionID,
    cols,
    AddDialog,
    EditDialog,
    editTip,
    setEditObject,
    setEditDialogIsOpen,
    hideTip
  }) => {
    const style = { background: datum.id && datum.id === selection_id ? '#aff' : 'none' }
    const className = datum.hidden ? 'hidden' : ''

    return (
      <TblRow {...{ className, style, datum, selection_id }}>
        {(cols || []).map((col, i) => (
          <Cell
            key={col.label}
            style={col.style && col.style(datum, i)}
            {...{ datum, selection_id, setSelectionID }}
          >
            {col.cell(datum, i)}
          </Cell>
        ))}

        {AddDialog ? (
          <RowAction>
            {EditDialog ? (
              <EditBtn {...{ datum, editTip, setEditObject, setEditDialogIsOpen }} />
            ) : null}
          </RowAction>
        ) : null}

        {hideTip ? (
          <RowAction>
            <HideBtn {...{ project, type, datum, hideTip }} />
          </RowAction>
        ) : null}
      </TblRow>
    )
  }
)

//--------------------------------------------------------------------------------------------------

const Body = React.memo(
  ({
    data,
    selection_id,
    setSelectionID,
    cols,
    AddDialog,
    EditDialog,
    editTip,
    setEditObject,
    setEditDialogIsOpen,
    hideTip,
    project,
    type
  }) => {
    return (
      <tbody>
        {data.map(datum => (
          <Row
            key={datum.id}
            {...{
              datum,
              project,
              type,
              selection_id,
              setSelectionID,
              cols,
              AddDialog,
              EditDialog,
              editTip,
              setEditObject,
              setEditDialogIsOpen,
              hideTip
            }}
          />
        ))}
      </tbody>
    )
  }
)
// Body.whyDidYouRender = true

//--------------------------------------------------------------------------------------------------

// function checkPropsChange(props, nextProps) {
//   return props.selection_id !== nextProps.selection_id || props.objs !== nextProps.objs
// }

const Wrapper = styled.div`
  display: inline-block;
  overflow-y: scroll;
  max-height: 800px;
  ${({ width }) => (width ? `width: ${width}` : '')};
`

const Table = styled.table`
  width: 100%;
  background: white;
  border-collapse: collapse;

  th {
    position: sticky;
    top: 0px;
  }
  th {
    color: white;
    background: ${() => gray(3)};
    font-weight: 400;
    text-align: left;
    padding: 4px 4px;
  }
  th:first-child {
    padding-left: 4px;
  }
  th:last-child {
    padding-right: 4px;
  }
`

const TblAction = styled.th`
  border: none;
  width: 22px;
`

const Add = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  width: 22px;
  height: 22px;

  div:hover {
    background: white;
    color: ${() => gray(3)};
    transition: 0.2s;
  }
`
const Show = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  width: 22px;
  height: 22px;
  background: ${({ showHidden }) => (showHidden ? gray(5) : gray(3))};
  color: ${({ showHidden }) => (showHidden ? gray(3) : 'white')};

  &:hover {
    background: white;
    color: ${() => gray(3)};
    transition: 0.2s;
  }
`

/**
 * @param {object} props
 * @param {string} [props.width]
 * @param {string} [props.selection_id]
 * @param {any} [props.setSelectionID]
 * @param {import("../types").Project} [props.project]
 * @param {any} [props.AddDialog]
 * @param {any} [props.addProps]
 * @param {string} [props.addTip]
 * @param {any} [props.EditDialog]
 * @param {any} [props.editProps]
 * @param {string} [props.editTip]
 * @param {string} [props.showTip]
 * @param {string} [props.hideTip]
 * @param {any} [props.update]
 * @param {string} [props.ident]
 * @param {any} [props.type]
 * @param {any} [props.cols]
 * @param {any} [props.objs]
 */

export const DataTable = React.memo(
  ({
    width,
    selection_id,
    setSelectionID,
    project,
    AddDialog,
    addProps,
    addTip,
    EditDialog,
    editProps,
    editTip,
    showTip,
    hideTip,
    update,
    type,
    cols,
    objs
  }) => {
    const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
    const [editObject, setEditObject] = useState(null)
    const [showHidden, setShowHidden] = useState(false)

    const AddBtn = () => (
      <Tooltip content={addTip}>
        <Add onClick={() => setAddDialogIsOpen(true)}>
          <Icon icon="plus-square" />
        </Add>
      </Tooltip>
    )

    const ShowBtn = () => (
      <Tooltip content={showTip}>
        <Show onClick={() => setShowHidden(!showHidden)} {...{ showHidden }}>
          <Icon icon="eye" />
        </Show>
      </Tooltip>
    )

    return (
      <>
        <Wrapper width={width}>
          <Table>
            <thead>
              <tr>
                {(cols || []).map(col => (
                  <th
                    key={col.label}
                    style={{ background: col.hue ? color(Number(col.hue), 2) : gray(3) }}
                  >
                    {col.label}
                  </th>
                ))}
                {AddDialog ? (
                  <TblAction>
                    <AddBtn />
                  </TblAction>
                ) : null}
                {showTip ? (
                  <TblAction>
                    <ShowBtn />
                  </TblAction>
                ) : null}
              </tr>
            </thead>
            <Body
              {...{
                data: objs.filter(obj => showHidden || !obj.hidden),
                selection_id,
                setSelectionID,
                cols,
                AddDialog,
                EditDialog,
                editTip,
                setEditObject,
                setEditDialogIsOpen,
                hideTip,
                project,
                type
              }}
            />
          </Table>
        </Wrapper>

        {addDialogIsOpen && (
          <AddDialog setOpen={setAddDialogIsOpen} {...addProps || { project, type }} />
        )}

        {editDialogIsOpen && editObject && (
          <EditDialog
            {...editProps || { project, update }}
            object={editObject}
            setOpen={setEditDialogIsOpen}
          />
        )}
      </>
    )
  }
)
