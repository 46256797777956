import React from 'react'
import styled from 'styled-components'
import { Col } from '.'

//------------------------------------------------------------------------------

const Lbl = styled.span`
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  margin-bottom: 4px;
`

const Info = styled.span`
  display: inline-block;
  padding-right: 4px;
  color: #888;
`

const Help = styled.span`
  display: inline-block;
  padding-left: 4px;
  padding-right: 4px;
  color: #888;
  font-size: 12px;
  margin-top: 4px;
`

interface FormItemProps {
  children: React.ReactNode
  label: string
  info?: string
  help?: string
}

export function FormItem({ children, label, info, help }: FormItemProps) {
  const lbl = label ? <Lbl>{label}</Lbl> : null
  const inf = info ? <Info>{info}</Info> : null
  const hlp = help ? <Help>{help}</Help> : null

  return (
    <Col padding="0 0 10px 0">
      <div>
        {lbl} {inf}
      </div>

      {children}

      {hlp}
    </Col>
  )
}
