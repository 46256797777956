// @ts-check

import React from 'react'
import sortBy from 'lodash/sortBy'
import { useAuthUser } from '../Users/Session'
import { Table } from '../UI'
import { NewQueryDialog, EditQueryDialog } from '.'

//--------------------------------------------------------------------------------------------------

export function QueryList({ project, update, selection_id, setSelectionID }) {
  const user = useAuthUser()

  const queries = sortBy(project.queries || [], 'name')

  return (
    <Table
      ident="queries"
      width="30em"
      {...{ project, user, selection_id, setSelectionID, update }}
      AddDialog={NewQueryDialog}
      EditDialog={EditQueryDialog}
      addTip="Create new query"
      editTip="Edit this query"
      showTip="Show hidden queries"
      hideTip="this query"
      cols={[{ label: 'Query Name', cell: query => query.name }]}
      objs={queries}
    />
  )
}
