/**
 * @typedef {object} QueryResultColumn
 * @property {import("../types").ID} id
 * @property {import("../types").ID[]} entity_ids
 * @property {string} name
 * @property {any} hue
 * @property {(item:QueryResultItem) => string} render
 * @property {boolean} [hidden]
 */

/**
 * @typedef {object} QueryResultItem
 * @property {number} i
 * @property {import("../types").EntityInstance} value
 */

/**
 * @typedef {object} QueryResult
 * @property {QueryResultColumn[]} cols
 * @property {import("rxjs").Observable<QueryResultItem[][]>} [item$]
 */

//--------------------------------------------------------------------------------------------------

/** @enum {string} */
export const StepType = {
  StartEntity: 'start-ent',
  FollowRelations: 'follow-rels'
  // Union: 'union'
}

/** @enum {string} */
export const SetOpSrcType = {
  Reletion: 'rel',
  Query: 'query'
}

/**
 * @typedef {object} SetOpSrc
 * @property {SetOpSrcType} type
 * @property {ID} id
 */

/**
 * @typedef {object} QueryStep
 * @property {ID} id
 * @property {StepType} type
 * @property {string} [column_name]
 * @property {boolean} [column_hide]
 * @property {ID} [start_entity_id]
 * @property {ID[]} [follow_relation_ids]
 * @property {string} [follow_relation_dir]
 * @property {SetOpSrc[]} [set_op_srcs]
 * @property {boolean} [hidden]
 */

/**
 * @typedef {object} Query
 * @property {ID} id
 * @property {string} name
 * @property {QueryStep[]} steps
 * @property {boolean} [hidden]
 */
