import React, { useState } from 'react'
import { Button, FormButtons, FormError, Panel, FormPassword } from '../UI'

import { useFirebase } from '../Firebase'

export const PasswordChangeForm = () => {
  const firebase = useFirebase()

  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const [error, setError] = useState(null)

  const isInvalid = passwordOne !== passwordTwo || passwordOne === ''

  return (
    <Panel>
      <FormPassword value={passwordOne} onChange={setPasswordOne} />
      <FormPassword label="Confirm Password" value={passwordTwo} onChange={setPasswordTwo} />

      <FormButtons>
        <Button disabled={isInvalid} onClick={submit}>
          Change My Password
        </Button>
      </FormButtons>

      <FormError error={error ? error.message : null} />
    </Panel>
  )

  function submit(e) {
    firebase
      .doPasswordUpdate(passwordOne)
      // .then(() => {
      //   setPasswordOne('')
      //   setPasswordTwo('')
      //   setError(null)
      // })
      .catch(setError)

    e.preventDefault()
  }
}
