import React from 'react'
import sortBy from 'lodash/sortBy'
import { useAuthUser } from '../Users/Session'
import { NewRelationDialog, EditRelationDialog } from '.'
import { Table } from '../UI'

//--------------------------------------------------------------------------------------------------

export function RelationList({ project, update, selection_id, setSelectionID }) {
  const user = useAuthUser()

  const entities = (project.entities || []).filter(e => !e.hidden)

  const entity_index = {}
  for (let entity of entities) {
    entity_index[entity.id] = entity
  }

  const relations = sortBy(
    (project.relations || []).filter(
      r => entity_index[r.subj_id] && entity_index[r.dobj_id]
      // r => entity_index[r.subj_id] && entity_index[r.dobj_id]
    ),
    r => entity_name(r.subj_id),
    r => entity_name(r.dobj_id)
  )

  function entity_name(id) {
    if (id in entity_index) {
      return entity_index[id].name
    }

    return `<entity ${id}>`
  }

  return (
    <Table
      ident="relations"
      width="350px"
      {...{ project, user, selection_id, setSelectionID, update }}
      showHue
      AddDialog={NewRelationDialog}
      EditDialog={EditRelationDialog}
      addTip="Create new type of relationship"
      editTip="Edit this relationship type"
      showTip="Show hidden relationship types"
      hideTip="this relationship type"
      cols={[
        { label: 'Source Entity', cell: relation => entity_name(relation.subj_id) },
        { label: 'Label', cell: relation => relation.verb },
        { label: 'Target Entity', cell: relation => entity_name(relation.dobj_id) }
      ]}
      objs={relations}
    />
  )
}
