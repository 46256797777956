/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { firestore } from 'firebase/app';
import { Observable } from 'rxjs';

function _fromRef(ref: any): Observable<any> {
  return new Observable(subscriber => {
    const unsubscribe = ref.onSnapshot(subscriber);
    return { unsubscribe };
  });
}

export function fromRef(ref: firestore.DocumentReference | firestore.Query) {
  return _fromRef(ref);
}

export function fromDocRef(
  ref: firestore.DocumentReference
): Observable<firestore.DocumentSnapshot> {
  return fromRef(ref);
}

export function fromCollectionRef(
  ref: firestore.Query
): Observable<firestore.QuerySnapshot> {
  return fromRef(ref);
}
