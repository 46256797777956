import { AttributeStruct, AttributeType } from '../types'

//--------------------------------------------------------------------------------------------------

export function prepAttribute(a) {
  if (a.type === AttributeType.Choice) {
    const choices = {}
    ;(a.choices || []).forEach(choice => {
      choice = choice.split(':').map(x => x.trim())
      choices[choice[0]] = choice[1] || choice[0]
    })
    return { ...a, choices }
  }
  return a
}

export function makeAttributeColumn(a) {
  return {
    label: a.name,
    cell: entity => {
      // console.log(entity[a.id])
      switch (a.type) {
        case AttributeType.Bool:
          return entity[a.id] ? '✔' : ''
        case AttributeType.Choice:
          if (a.struct === AttributeStruct.Set) {
            return (entity[a.id] || []).map(x => a.choices[x] || x).join(', ')
          } else {
            return a.choices[entity[a.id]] || entity[a.id]
          }
        default:
          return entity[a.id]
      }
    },
    style: entity => {
      switch (a.type) {
        case AttributeType.Date:
          return { whiteSpace: 'nowrap' }
        default:
          return {}
      }
    }
  }
}
