import React from 'react'
import useRouter from 'use-react-router'
import { Button } from '../UI'
import { useFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

//------------------------------------------------------------------------------

export function SignOutButton({ visible }) {
  const firebase = useFirebase()
  const { history } = useRouter()

  return visible ? (
    <Button
      onClick={() => {
        history.push(ROUTES.SIGN_IN)
        firebase.doSignOut()
      }}
    >
      Sign Out
    </Button>
  ) : null
}
