// @ts-check

import { chord as d3_chord } from 'd3-chord'

/**
 * @param {import("../../types").EntityInstance[]} subjects
 * @param {import("../../types").RelationInstance[]} relations
 * @param {import("../../types").EntityInstance[]} dobjects
 */

export function prepChordData(subjects, relations, dobjects) {
  const subjs = subjects
  const subj_ids = subjs.map(p => p.id)
  const rels = relations.filter(rel => subj_ids.includes(rel.subj_id))
  const dobj_ids = new Array(...new Set(rels.map(rel => rel.dobj_id)))

  const n = subj_ids.length + dobj_ids.length
  const m = new Array(n)
  for (let i = 0; i < n; i++) {
    m[i] = new Array(n)
    m[i].fill(0)
  }

  const o = dobj_ids.length
  for (let rel of rels) {
    let i = dobj_ids.indexOf(rel.dobj_id)
    let j = o + subj_ids.indexOf(rel.subj_id)
    m[i][j] = m[j][i] = 1 //rel.weight
  }

  const chord = d3_chord()
  chord.sortGroups()
  const c = chord(m)

  // const index = {}
  // subjs.forEach(p => {
  //   index[p.id] = p
  // })
  // dobj_ids.forEach(id => {
  //   index[id] = dobjects.get(id)
  // })

  return {
    chords: c,
    ids: dobj_ids.concat(subj_ids)
    // index
  }
}
