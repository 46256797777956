import React from 'react'
import styled from 'styled-components'
import { Portal } from '../UI'

const Content = styled.div`
  left: ${props => props.coords.left}px;
  top: ${props => props.coords.top - 5}px;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border: 0px solid white;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  transform: translate(-60px, -100%);

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`

export function Tooltip({ coords, content }) {
  return (
    <Portal>
      <Content coords={coords}>{content}</Content>{' '}
    </Portal>
  )
}
