// @ts-check

import { StepType } from '../types'
import start_entity from './StartEntity'
import follow_relations from './FollowRelations'
import { step_types } from '..'

const STEPS = {
  [StepType.StartEntity]: start_entity,
  [StepType.FollowRelations]: follow_relations
}

export function execute_step(project, step, idx, result) {
  const stepper = step && step.type ? STEPS[step.type] : null

  if (stepper && stepper.execute) {
    stepper.execute(project, step, idx, result)
  }
}

// /** @type {(step:import("../types").QueryStep) => JSX.Element | string} */
export function show_step(project, step) {
  const stepper = step && step.type ? STEPS[step.type] : null

  if (stepper && stepper.show) {
    const type = step_types.find(type => type.value === step.type)
    return stepper.show(project, step, type)
  }

  return `<${step.type}>`
}

export function edit_step(project, step, setStepProp) {
  const stepper = step && step.type ? STEPS[step.type] : null

  if (stepper && stepper.edit) {
    return stepper.edit(project, step, setStepProp)
  }

  return null
}
