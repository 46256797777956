import type { ReactNode } from 'react'
import styled from 'styled-components'

//--------------------------------------------------------------------------------------------------

const JUSTIFY: {[key:string]: string} = { left: 'flex-start', center: 'center', right: 'flex-end' }

interface RowProps {
  children: ReactNode
  spacing?: string
  padding?: string
  justify?: string
  color?: string
  border?: string
  radius?: string
  wrap?: boolean
  seperate?: string
  width?: string
  onClick?: (event:React.MouseEvent<HTMLDivElement,MouseEvent>) => void
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: ${p => p.wrap ? 'wrap' : 'nowrap'};
  justify-content: ${p => JUSTIFY[p.justify || 'left']};
  ${p => p.padding ? `padding: ${p.padding}` : ''};
  ${p => p.color ? `color: ${p.color}` : ''};
  ${p => p.border ? `border: ${p.border}` : ''};
  ${p => p.radius ? `border-radius: ${p.radius}` : ''};
  ${p => p.seperate ? 'justify-content: space-between' : ''};
  ${p => p.width ? `width: ${p.width}` : ''};
  ${p => p.onClick ? 'cursor: pointer' : ''};
`
