// @ts-check

import React from 'react'
import { DataTable, color } from '../UI'
import { entity_instance_hue } from '../Data'

//--------------------------------------------------------------------------------------------------

/**
 * A table showing the result of a query
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {import("./types").Query} props.query
 * @param {import("./types").QueryResult} props.result
 * @param {import("./types").QueryResultItem[]} props.items
 */

export function QueryResultTable({ project, result, items }) {
  const columns = result.cols
    .map((col, i) =>
      col.hidden
        ? null
        : {
            label: col.name,
            cell: instance => {
              return col.render(instance[i]) || '??'
            },
            hue: col.hue,
            style: instance => ({
              background: color(entity_instance_hue(project, instance[i].value), 6, 3)
            })
          }
    )
    .filter(c => !!c)

  return (
    <DataTable
      {...{ project }}
      // type={relation}
      // AddDialog={NewRelationInstanceDialog}
      // addProps={{ project, type: relation, empty }}
      // addTip="Create new relationship"
      // EditDialog={EditRelationInstanceDialog}
      // editProps={{ project, type: relation }}
      // editTip="Edit this relationship"
      // showTip="Show hidden relationship"
      // hideTip="this relationship"
      cols={columns}
      objs={items}
      // isData
    />
  )
}
