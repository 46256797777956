// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import generate from 'nanoid/generate'
import { DialogBody, DialogFooter } from '../Parts'
import { CancelOrCreate, FormInput, FormSwitch } from '../UI'
import { StepTypeUI } from '.'
import { StepType } from './types'
import { edit_step } from './steps'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for creating a query step
 * @param {object} props
 * @param {import("./types").QueryStep[]} props.steps
 * @param {(steps:import("./types").QueryStep[]) => void} props.setSteps
 * @param {(open:boolean) => void} props.setOpen
 * @param {import("../types").Project} props.project
 */

export function NewStepDialog({ steps, setSteps, setOpen, project }) {
  /** @type {[import("./types").QueryStep, any]} */
  const [step, setStep] = useState({
    id: generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4),
    type: StepType.StartEntity
  })

  function setStepProp(key, val) {
    setStep(old_step => ({ ...old_step, [key]: val }))
  }

  return (
    <Dialog title="Create New Step" icon="add" isOpen onClose={close} usePortal={false}>
      <DialogBody>
        <StepTypeUI value={step.type} setValue={type => setStepProp('type', type)} />

        <FormInput
          label="Column Name"
          info="(optional)"
          help="Defaults to Entity name(s)"
          value={step.column_name}
          onChange={e => setStepProp('column_name', e.target.value)}
        />

        <FormSwitch
          label="Hide Column"
          checked={!!step.column_hide}
          onChange={value => setStepProp('column_hide', value)}
        />

        {edit_step(project, step, setStepProp)}
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate onCancel={close} onCreate={create} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function create() {
    setSteps([...steps, step])
    close()
  }
}
