import React from 'react'
import { Col } from '../UI'
import { SignInForm, SignUpLink, PasswordForgetLink } from '.'

//------------------------------------------------------------------------------

export function SignInPage() {
  return (
    <Col maxWidth="20em" centered>
      <h1>Sign In</h1>
      <SignInForm />
      <p>&nbsp;</p>
      <PasswordForgetLink />
      <SignUpLink />
    </Col>
  )
}
