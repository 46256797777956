import React, { useState, useContext, createContext } from 'react'
import styled from 'styled-components'
import { color, gray, font_family, font_size, big_font_size, Hue } from '.'

//--------------------------------------------------------------------------------------------------

interface TabGroupContext {
  tab: string
  setTab: (value: string) => void
  vertical: boolean
  hue: Hue
  big: boolean
  minimal: boolean
}

interface TabGroupProps {
  tab: string
  setTab: (value: string) => void
  initial?: string
  vertical: boolean
  hue: Hue
  big: boolean
  minimal: boolean
  children?: React.ReactNode
}

const TabsContext = createContext<Partial<TabGroupProps>>({})

export function TabGroup({ initial, vertical, hue = 0, big, minimal, children }: TabGroupProps) {
  const [tab, setTab] = useState(initial || '')

  return (
    <TabsContext.Provider value={{ tab, setTab, vertical, hue, big, minimal }}>
      <div style={{ display: vertical ? 'flex' : 'block' }}>{children}</div>
    </TabsContext.Provider>
  )
}

//--------------------------------------------------------------------------------------------------

const TabTabs = styled.div`
  overflow: hidden;
  ${({ minimal, hue }) => !minimal && `border: 1px solid ${color(hue, 3)};`}
  background-color: ${() => gray(5)};
  display: inline-flex;
  margin-bottom: 10px;
  ${({ vertical }) => vertical && 'margin-right: 10px;'}
  ${({ vertical }) => vertical && 'flex-direction: column;'}
`

TabGroup.Tabs = ({ children }) => {
  const context = useContext(TabsContext)

  const tabs = <TabTabs {...context}>{children}</TabTabs>

  if (context.vertical) {
    return <div>{tabs}</div>
  } else {
    return tabs
  }
}

//--------------------------------------------------------------------------------------------------

const TabButton = styled.button`
  color: ${({ tab, id, hue }) => (tab === id ? 'white' : color(hue, 3))};
  background-color: ${({ tab, id, hue }) => (tab === id ? color(hue, 3) : 'white')};
  border: none;
  outline: none;
  cursor: pointer;
  padding: ${({ big }) => (big ? '4px 8px' : '1px 6px')};
  ${({ vertical }) => vertical && 'text-align: left;'}
  transition: 0.3s;
  height: 26px;
  font-family: ${font_family};
  font-size: ${({ big }) => (big ? big_font_size : font_size)};

  &:hover {
    background-color: ${({ tab, id, hue }) => (tab === id ? color(hue, 3) : color(hue, 5))};
  }
`

TabGroup.Tab = ({ id, children }) => {
  const { setTab, ...context } = useContext(TabsContext)

  return (
    <TabButton onClick={() => setTab(id)} {...{ id, ...context }}>
      {children}
    </TabButton>
  )
}

//--------------------------------------------------------------------------------------------------

TabGroup.Content = ({ of, children }) => {
  const { tab } = useContext(TabsContext)

  return <div style={{ display: tab === of ? 'block' : 'none' }}>{children}</div>
}
