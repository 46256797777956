// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import generate from 'nanoid/generate'
import { DialogBody, DialogFooter } from '../Parts'
import { Name, Desc, IsName, IsRequired, IsOrder, IsSorter, Choices, CancelOrCreate } from '../UI'
import { AttributeTypeUI, AttributeStructUI } from '.'
import { AttributeType, AttributeStruct } from '../types'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for creating an attribute
 * @param {object} props
 * @param {import("../types").Attribute[]} props.attrs
 * @param {(attrs:import("../types").Attribute[])=>void} props.setAttrs
 * @param {(open:boolean)=>void} props.setOpen
 * @param {boolean} props.showIsName
 * @param {boolean} props.showIsOrder
 */

export function NewAttributeDialog({ attrs, setAttrs, setOpen, showIsName, showIsOrder }) {
  const [name, setName] = useState('')
  const [is_name, setIsName] = useState(false)
  const [desc, setDesc] = useState(null)
  const [is_order, setIsOrder] = useState(false)
  const [is_sorter, setIsSorter] = useState(false)
  const [type, setType] = useState(AttributeType.String)
  const [struct, setStruct] = useState(AttributeStruct.One)
  const [is_required, setIsRequired] = useState(false)
  const [choices, setChoices] = useState([])

  const isInvalid = name.length == 0

  return (
    <Dialog title="Create New Attribute" icon="add" isOpen onClose={close} usePortal={false}>
      <DialogBody>
        <Name value={name} setValue={setName} />

        <Desc value={desc} setValue={setDesc} />

        <AttributeTypeUI value={type} setValue={setType} />

        <IsRequired value={is_required} setValue={setIsRequired} />

        {(type === AttributeType.String ||
          type === AttributeType.Choice ||
          type === AttributeType.Date) &&
        showIsName ? (
          <IsName value={is_name} setValue={setIsName} />
        ) : null}

        <IsSorter value={is_sorter} setValue={setIsSorter} />

        {(type === AttributeType.String ||
          type === AttributeType.Choice ||
          type === AttributeType.Date) &&
        showIsOrder ? (
          <IsOrder value={is_order} setValue={setIsOrder} />
        ) : null}

        {type === AttributeType.Choice ? (
          <AttributeStructUI value={struct} setValue={setStruct} />
        ) : null}

        {type === AttributeType.Choice ? <Choices value={choices} setValue={setChoices} /> : null}
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={create} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function create() {
    const id = generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4)
    setAttrs([...attrs, { id, name, desc, is_name, is_order, is_sorter, type, struct, choices }])
    close()
  }
}
