//-- @ts-check

import React from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { AttributeStruct, AttributeType } from '../types'
import { FormSwitch, FormSelect, FormInput, FormItem, FormMultiSelect } from '../UI'
import { ChoiceSetFieldPart } from '../Parts'

import './AttributeValueUI.css'

//--------------------------------------------------------------------------------------------------

function StringField({ attr, data, setData }) {
  return (
    <FormInput
      label={attr.name}
      info={attr.is_required ? '(required)' : ''}
      value={data[attr.id] || ''}
      onChange={e => setData(attr.id, e.target.value)}
      type="text"
    />
  )
}

function TextField({ attr, data, setData }) {
  return (
    <FormInput
      label={attr.name}
      info={attr.is_required ? '(required)' : ''}
      value={data[attr.id] || ''}
      onChange={e => setData(attr.id, e.target.value)}
      type="textarea"
    />
  )
}

function DateField({ attr, data, setData }) {
  return (
    <FormItem label={attr.name} info={attr.is_required ? '(required)' : ''}>
      <DatePicker
        className="date"
        dateFormat="yyyy-MM-dd"
        selected={
          data[attr.id] != '' && data[attr.id] != null ? moment(data[attr.id]).toDate() : undefined
        }
        onChange={new_date =>
          setData(attr.id, new_date ? moment(new_date).format('YYYY-MM-DD') : '')
        }
      />
    </FormItem>
  )
}

function BoolField({ attr, data, setData }) {
  return (
    <FormSwitch
      label={attr.name}
      checked={data[attr.id] || false}
      // @ts-ignore
      onChange={e => setData(attr.id, e)}
    />
  )
}

function makeOption(choice) {
  choice = choice.split(':').map(x => x.trim())
  return { value: choice[0], label: choice[1] || choice[0] }
}

function ChoiceField({ attr, data, setData }) {
  const choices = attr.is_required ? attr.choices : ['', ...attr.choices]
  const options = choices.map(makeOption)

  return (
    <FormSelect
      label={attr.name}
      value={data[attr.id]}
      options={options}
      onChange={value => setData(attr.id, value)}
    />
  )
}

function ChoiceSetField({ attr, data, setData }) {
  const choices = attr.is_required ? attr.choices : ['', ...attr.choices]
  const options = choices.map(makeOption)

  return (
    <ChoiceSetFieldPart
      label={attr.name}
      value={data[attr.id] || []}
      options={options}
      setValue={value => setData(attr.id, value)}
    />
  )
}

export function AttributeValueUI({ attr, data, setData }) {
  switch (attr.type || AttributeType.String) {
    case AttributeType.String:
      return <StringField attr={attr} data={data} setData={setData} />
    case AttributeType.Text:
      return <TextField attr={attr} data={data} setData={setData} />
    case AttributeType.Date:
      return <DateField attr={attr} data={data} setData={setData} />
    case AttributeType.Bool:
      return <BoolField attr={attr} data={data} setData={setData} />
    case AttributeType.Choice:
      if (attr.struct === AttributeStruct.Set) {
        return <ChoiceSetField attr={attr} data={data} setData={setData} />
      } else {
        return <ChoiceField attr={attr} data={data} setData={setData} />
      }
  }

  return null
}
