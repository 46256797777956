// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '../Parts'
import { DeleteCancelOrSave, FormInput, FormSwitch } from '../UI'
import { StepTypeUI } from '.'
import { edit_step } from './steps'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for editing a step
 * @param {object} props
 * @param {import("./types").QueryStep} props.object
 * @param {(f:(steps:import("./types").QueryStep[])=>import("./types").QueryStep[])=>void} props.setSteps
 * @param {(open:boolean) => void} props.setOpen
 * @param {import("../types").Project} props.project
 */

export function EditStepDialog({ object: step, setSteps, setOpen, project }) {
  /** @type {[import("./types").QueryStep, any]} */
  const [new_step, setStep] = useState(step)

  function setStepProp(key, val) {
    setStep(old_step => ({ ...old_step, [key]: val }))
  }

  return (
    <Dialog
      title="Edit Step"
      icon="edit"
      isOpen
      onClose={close}
      usePortal={false}
      style={{ textAlign: 'left' }}
    >
      <DialogBody>
        <StepTypeUI value={new_step.type} setValue={type => setStepProp('type', type)} />

        <FormInput
          label="Column Name"
          info="(optional)"
          help="Defaults to Entity name(s)"
          value={new_step.column_name}
          onChange={e => setStepProp('column_name', e.target.value)}
        />

        <FormSwitch
          label="Hide Column"
          checked={!!new_step.column_hide}
          onChange={value => setStepProp('column_hide', value)}
        />

        {edit_step(project, new_step, setStepProp)}
      </DialogBody>

      <DialogFooter>
        <DeleteCancelOrSave
          onCancel={close}
          onSave={save}
          onDelete={remove}
          willDelete="this step"
        />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    setSteps(steps => steps.map(old_step => (old_step.id === new_step.id ? new_step : old_step)))
    close()
  }

  function remove() {
    setSteps(steps => steps.filter(s => s.id !== step.id))
    close()
  }
}
