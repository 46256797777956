// @ts-check

/** @typedef {(obj:{[field:string]:any})=>Promise} Updater */

/** @typedef {string} ID */

//--------------------------------------------------------------------------------------------------

/** @enum {string} */
export const AttributeType = {
  String: 'string',
  Text: 'text',
  Date: 'date',
  Bool: 'bool',
  Choice: 'enum'
}

/** @enum {string} */
export const AttributeStruct = {
  One: 'one',
  Set: 'set',
  List: 'list'
}

/**
 * @typedef {object} Attribute
 * @property {ID} id
 * @property {string} name
 * @property {AttributeType} type
 * @property {AttributeStruct} struct
 * @property {string} [desc]
 * @property {boolean} [is_name]
 * @property {boolean} [is_order]
 * @property {boolean} [is_required]
 * @property {boolean} [is_sorter]
 * @property {string[]} [choices]
 * @property {boolean} [hidden]
 */

/**
 * @typedef {object} Entity
 * @property {ID} id
 * @property {string} name
 * @property {string} [desc]
 * @property {Hue} [hue]
 * @property {Attribute[]} [attrs]
 * @property {ID[]} isa_ids
 * @property {boolean} [hidden]
 */

/**
 * @typedef {object} Relation
 * @property {ID} id
 * @property {string} verb
 * @property {ID} subj_id
 * @property {ID} dobj_id
 * @property {string} [desc]
 * @property {Hue} [hue]
 * @property {Attribute[]} [attrs]
 * @property {boolean} [hidden]
 */

//--------------------------------------------------------------------------------------------------

/**
 * @typedef {object} Project
 * @property {ID} id
 * @property {string} name
 * @property {ID} owner_id
 * @property {string} [desc]
 * @property {ID[]} [collab_ids]
 * @property {Entity[]} [entities]
 * @property {Relation[]} [relations]
 * @property {Query[]} [queries]
 */

//--------------------------------------------------------------------------------------------------

/**
 * @typedef {object} EntityInstance
 * @property {ID} id
 * @property {string} [_name]
 * @property {string} [_sort]
 */

/**
 * @typedef {object} RelationInstance
 * @property {ID} id
 * @property {ID} subj_id
 * @property {ID} dobj_id
 * @property {EntityInstance} [_subj]
 * @property {EntityInstance} [_dobj]
 */
