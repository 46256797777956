import React from 'react'
import sortBy from 'lodash/sortBy'
import { AttributeType } from '../types'
import { NewEntityInstanceDialog, EditEntityInstanceDialog } from '.'
import { entity_attrs } from '../Schema'
import { DataTable } from '../UI'
import { prepAttribute, makeAttributeColumn } from './utils'

//--------------------------------------------------------------------------------------------------

export function EntityTable({ project, entity, data, selection_id, setSelectionID }) {
  const attrs = entity_attrs(project, entity).filter(a => !a.hidden)

  const sorted =
    attrs.length > 0
      ? sortBy(data || [], ...attrs.filter(a => !!a.is_sorter).map(a => a.id))
      : data || []

  const cols = attrs
    .filter(a => a.type !== AttributeType.Text)
    .map(prepAttribute)
    .map(makeAttributeColumn)

  return (
    <>
      <DataTable
        {...{ project, selection_id, setSelectionID }}
        type={entity}
        AddDialog={NewEntityInstanceDialog}
        addTip="Create new entity"
        EditDialog={EditEntityInstanceDialog}
        editProps={{ project, type: entity }}
        editTip="Edit this entity"
        showTip="Show hidden entity"
        hideTip="this entity"
        cols={cols}
        objs={sorted}
      />
      {data ? null : <p>Loading...</p>}
    </>
  )
}
