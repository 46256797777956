// @ts-check

//--------------------------------------------------------------------------------------------------

/**
 * Get an entity by its ID
 * @param {import("../types").Project} project
 * @param {string} entity_id
 */
export function find_entity(project, entity_id) {
  return project.entities.find(e => e.id == entity_id)
}

//--------------------------------------------------------------------------------------------------

/**
 * Construct an entity name
 * @param {import("../types").Project} project
 * @param {string} entity_id
 */
export function entity_name(project, entity_id) {
  const entity = project.entities.find(e => e.id == entity_id)

  return entity ? entity.name : `<entity ${entity_id}>`
}

//--------------------------------------------------------------------------------------------------

/**
 * Get a relation by its ID
 * @param {import("../types").Project} project
 * @param {string} relation_id
 */
export function find_relation(project, relation_id) {
  return project.relations.find(r => r.id == relation_id)
}

//--------------------------------------------------------------------------------------------------

/**
 * Construct a relation name
 * @param {import("../types").Project} project
 * @param {import("../types").Relation} relation
 */
export function relation_name(project, relation) {
  const subj_name = entity_name(project, relation.subj_id)
  const dobj_name = entity_name(project, relation.dobj_id)

  return `${subj_name} ${relation.verb} ${dobj_name}`
}

//--------------------------------------------------------------------------------------------------

/**
 * @param {import("../types").Project} project
 * @param {import("../types").Entity} entity
 */
export function entity_subtypes(project, entity) {
  /** @type {import("../types").Entity[]} */
  let subtypes = []

  for (let e of project.entities || []) {
    if ((e.isa_ids || []).includes(entity.id)) {
      subtypes.push(e)
      subtypes = subtypes.concat(entity_subtypes(project, e))
    }
  }

  return subtypes
}

//--------------------------------------------------------------------------------------------------

export function entity_supertypes(project, entity) {
  if (!entity) {
    console.error('')
  }
  const sups = []

  for (const isa of (entity.isa_ids || []).map(id => find_entity(project, id))) {
    if (isa) {
      sups.push(...entity_supertypes(project, isa))
      sups.push(isa)
    }
  }

  return sups
}

//--------------------------------------------------------------------------------------------------

export function entity_attrs(project, entity) {
  return []
    .concat(...entity_supertypes(project, entity).map(e => e.attrs || []), entity.attrs || [])
    .filter(a => !a.hidden)
}

//--------------------------------------------------------------------------------------------------

export function entity_hue(project, entity) {
  if (entity.hue) {
    return Number(entity.hue)
  }
  const hues = entity_supertypes(project, entity)
    .map(e => entity_hue(project, e))
    .filter(h => h > 0)
  if (hues.lengrh > 0) {
    return hues[0]
  }
  return 0
}

//--------------------------------------------------------------------------------------------------

export function entity_relations(project, entity) {
  const type_ids = [entity.id, ...entity_supertypes(project, entity).map(e => e.id)]

  if (project && project.relations) {
    return project.relations.filter(
      r => (type_ids.includes(r.subj_id) || type_ids.includes(r.dobj_id)) && !r.hidden
    )
  }

  return []
}

//--------------------------------------------------------------------------------------------------

/**
 * Construct collection name for entity/relation instances
 * @param {string} pid Project ID
 * @param {string} eid Entity/Releation ID
 */
export function data_collection_name(pid, eid) {
  return `projects/${pid}/data-${eid}`
}
