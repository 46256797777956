//// @ts-check

import React, { useState } from 'react'
import sortBy from 'lodash/sortBy'
import { Dialog } from '@blueprintjs/core'
import generate from 'nanoid/generate'
import { useFirebase } from '../Firebase'
import { DialogBody, DialogFooter } from '../Parts'
import { PickHue, Verb, Desc, CancelOrCreate, EntityID, FormError } from '../UI'
import { AttributesUI } from '.'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for creating a relation
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {boolean} props.open
 * @param {(open:boolean)=>void} props.setOpen
 */

export function NewRelationDialog({ project, setOpen }) {
  const firebase = useFirebase()
  const [error, setError] = useState(null)

  const [subj_id, setSubjID] = useState('')
  const [verb, setVerb] = useState('')
  const [dobj_id, setDobjID] = useState('')
  const [desc, setDesc] = useState(null)
  const [hue, setHue] = useState(null)
  const [attrs, setAttrs] = useState([])

  const isInvalid = !project || subj_id.length < 1 || verb.length < 1 || dobj_id.length < 1
  const entities = sortBy(project.entities || [], 'name')

  return (
    <Dialog
      title="Create New Type of Relationship"
      icon="add"
      isOpen={true}
      onClose={close}
      canEscapeKeyClose={false}
    >
      <DialogBody>
        <EntityID label="From Entity" id={subj_id} setID={setSubjID} entities={entities} />
        <Verb value={verb} setValue={setVerb} />
        <EntityID label="To Entity" id={dobj_id} setID={setDobjID} entities={entities} />
        <Desc value={desc} setValue={setDesc} />
        <PickHue value={hue} setValue={setHue} />
        <AttributesUI value={attrs} setValue={setAttrs} showIsOrder />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={create} />
        <FormError error={error} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
    setSubjID('')
    setVerb('')
    setDobjID('')
    setAttrs([])
  }

  function create() {
    const relations = project.relations || []
    const id = generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4)
    firebase
      .project(project.id)
      .update({ relations: [...relations, { id, subj_id, verb, dobj_id, desc, hue, attrs }] })
      .then(close)
      .catch(setError)
  }
}
