import React from 'react'
import arrayMove from 'array-move'
import { NewAttributeDialog, EditAttributeDialog } from '.'
import { useAuthUser } from '../Users/Session'
import { Table, FormItem } from '../UI'
import type {PlainAttribute} from './types'

//--------------------------------------------------------------------------------------------------

interface AttributesUIProps {
  value: PlainAttribute[]
  setValue: (attrs: PlainAttribute[]) => void
  showIsName?: boolean
  showIsOrder?: boolean
}

export function AttributesUI({
  value,
  setValue,
  showIsName = false,
  showIsOrder = false
}: AttributesUIProps) {
  const user = useAuthUser()

  const onDragEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setValue(arrayMove(value, oldIndex, newIndex))
  }

  return (
    <FormItem
      label="Attributes"
      info={showIsName ? '(required)' : ''}
      help="Drag a row to change order of attributes."
    >
      <Table
        // ident="entities"
        width="100%"
        {...{ user }}
        // showHue
        AddDialog={NewAttributeDialog}
        addProps={{ attrs: value, setAttrs: setValue, showIsName, showIsOrder }}
        addTip="Create new attribute"
        EditDialog={EditAttributeDialog}
        editProps={{ setAttrs: setValue, showIsName, showIsOrder }}
        editTip="Edit this attribute"
        showTip="Show hidden attributes"
        hideTip="this attribute"
        cols={[{ label: 'Name', cell: (attr: PlainAttribute) => attr.name }]}
        objs={value}
        update={() => setValue(value)}
        isItem
        onDragEnd={onDragEnd}
      />
    </FormItem>
  )
}
