import React, { useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { Dialog } from '@blueprintjs/core'
import { db } from '../Firebase'
import { data_collection_name } from '../Schema'
import { DialogBody, DialogFooter } from '../Parts'
import { CancelOrSave, FormError, FormItem } from '../UI'
import { EntityImportTable } from '.'

export function EntityImportDialog({ project, entity, open, setOpen }) {
  const [error, setError] = useState()
  const [data, setData] = useState(null)
  const [use_id, setUseID] = useState(false)
  const [skip_1st_row, setSkip1stRow] = useState(false)

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => setError({ message: 'file reading was aborted' })
      reader.onerror = () => setError({ message: 'file reading has failed' })
      reader.onload = () => {
        // Do whatever you want with the file contents
        const text = reader.result
        const result = Papa.parse(text.trim())
        // console.table(result.data)
        setData(
          result.data.map(d => {
            const obj = {}

            entity.attrs.forEach((a, i) => {
              const val = d[i].trim()
              if (val.length > 0) {
                obj[a.id] = d[i]
              }
            })

            return obj
          })
        )
      }
      reader.readAsText(file)
    })
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Dialog title="Import Entity Data" isOpen={open} onClose={close}>
      <DialogBody>
        <FormItem
          label="CSV File"
          help="Columns in the CSV file must correspond to the attributes of this type of entity, in correct order."
        >
          <div className="data-dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the file here ...</p>
            ) : (
              <p>Drag 'n' drop a file here, or click to select a file</p>
            )}
          </div>
        </FormItem>

        {data ? <EntityImportTable project={project} entity={entity} data={data} /> : null}

        <FormError error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrSave onCancel={close} onSave={save} isInvalid={!data} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
  }

  function save() {
    const batch = db.batch()
    const collection = db.collection(data_collection_name(project.id, entity.id))

    for (const obj of data) {
      batch.set(collection.doc(), obj)
    }

    batch
      .commit()
      .then(close())
      .catch(setError)
  }
}
