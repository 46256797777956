import React from 'react'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { useObservable } from '../Firebase'
import { QueryChords, QueryDiscs } from '../View'
import { TabGroup, FormSwitch } from '../UI'
import { QueryResultTable, run_query } from '../Query'

export function QueryDataTabs({
  project,
  query,
  instance_id,
  setInstanceID,
  setInstanceEntity,
  fade_orphans,
  setFadeOrphans
}) {
  const result = run_query(project, query)
  const data = useObservable(result.item$, null)

  if (!data) {
    return <p>Loading...</p>
  }

  const discrete = uniqBy(data, instance =>
    result.cols
      .map((col, i) => {
        if (col.hidden) return ''
        if (instance[i]) return instance[i].value.id
        return `[${i}]`
      })
      .join(':')
  )

  const sorted = sortBy(
    discrete,
    result.cols.map((col, i) => instance => (instance[i] ? instance[i].value._sort : `[${i}]`))
  )

  return (
    <TabGroup initial="table">
      <TabGroup.Tabs>
        <TabGroup.Tab id="table">Table</TabGroup.Tab>
        <TabGroup.Tab id="chords">Chords</TabGroup.Tab>
        <TabGroup.Tab id="discs">Discs</TabGroup.Tab>
      </TabGroup.Tabs>

      <h3 style={{ margin: '3px 0 0 10px', display: 'inline' }}>{query.name}</h3>

      <TabGroup.Content of="table">
        <QueryResultTable
          key={query.id}
          project={project}
          query={query}
          result={result}
          items={sorted}
        />
      </TabGroup.Content>

      <TabGroup.Content of="chords">
        <QueryChords
          key={query.id}
          project={project}
          query={query}
          result={result}
          items={sorted}
          selection_id={instance_id}
          setSelectionID={setInstanceID}
          setSelectionEntity={setInstanceEntity}
          caption={
            <div className="data-caption-800">
              Select a circle arc to see more info on that entity
            </div>
          }
        />
      </TabGroup.Content>

      <TabGroup.Content of="discs">
        <QueryDiscs
          key={query.id}
          project={project}
          query={query}
          result={result}
          items={sorted}
          selection_id={instance_id}
          setSelectionID={setInstanceID}
          setSelectionEntity={setInstanceEntity}
          caption={
            <div className="data-caption-800">
              Select a disc to see more info on that entity
              <FormSwitch
                label="Fade Orphans"
                checked={fade_orphans}
                onChange={setFadeOrphans}
                right
              />
            </div>
          }
          fadeOrphans={fade_orphans}
        />
      </TabGroup.Content>
    </TabGroup>
  )
}
