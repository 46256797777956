import React from 'react'
import { Col } from '../UI'
import { SignUpForm } from '.'

//------------------------------------------------------------------------------

export function SignUpPage() {
  return (
    <Col maxWidth="20em" centered>
      <h1>Sign Up</h1>
      <SignUpForm />
    </Col>
  )
}
