import React, { useState } from 'react'
import { useObservable, watch } from '../Firebase'
import { EntityList, RelationList } from '../Schema'
import { data_collection_name } from '../Schema'
import { EntityInstanceInfo } from '../Data'
import { Panel, TabGroup, Col } from '../UI'
import { ProjectEntityData, RelationDataTabs } from '.'

export function DataPanel({ project, update }) {
  const [selection_id, setSelectionID] = useState(null)
  const [fade_orphans, setFadeOrphans] = useState(true)

  const entity = (project.entities || []).find(e => e.id == selection_id)
  const relation = (project.relations || []).find(r => r.id == selection_id)

  return (
    <Panel row>
      <Col>
        <TabGroup initial="entities">
          <TabGroup.Tabs>
            <TabGroup.Tab id="entities">Entities</TabGroup.Tab>
            <TabGroup.Tab id="relations">Relationships</TabGroup.Tab>
          </TabGroup.Tabs>

          <TabGroup.Content of="entities">
            <EntityList
              project={project}
              update={update}
              selection_id={selection_id}
              setSelectionID={setSelectionID}
            />
          </TabGroup.Content>

          <TabGroup.Content of="relations">
            <RelationList
              project={project}
              update={update}
              selection_id={selection_id}
              setSelectionID={setSelectionID}
            />
          </TabGroup.Content>
        </TabGroup>
        Select an entity or relationship type to see its instances
      </Col>

      {selection_id && entity && <EntityColumn2 key={selection_id} {...{ project, entity }} />}
      {selection_id && relation && (
        <RelationColumn2
          key={selection_id}
          {...{ project, relation, fade_orphans, setFadeOrphans }}
        />
      )}
    </Panel>
  )
}

function EntityColumn2({ project, entity }) {
  const [instance_id, setInstanceID] = useState(null)
  const data = useObservable(watch(data_collection_name(project.id, entity.id), null))

  // const objs = data ? data.filter(e => !e.hidden) : []
  const objs = data || []

  return (
    <>
      <Col padding="0 0 0 20px">
        <h3 style={{ margin: '2px 0 8px 0', height: '28px' }}>
          {objs.length} Instances of {entity.name}
        </h3>

        {data ? (
          <ProjectEntityData
            key={entity.id}
            project={project}
            entity={entity}
            instance_id={instance_id}
            setInstanceID={setInstanceID}
            data={objs}
          />
        ) : (
          <p>Loading...</p>
        )}
      </Col>

      {instance_id && (
        <div style={{ padding: '0 0 0 20px' }}>
          <EntityInstanceInfo
            key={instance_id}
            project={project}
            entity={entity}
            id={instance_id}
          />
        </div>
      )}
    </>
  )
}

function RelationColumn2({ project, relation, fade_orphans, setFadeOrphans }) {
  const [instance_id, setInstanceID] = useState(null)
  const [instance_entity, setInstanceEntity] = useState(null)
  // const instance_entity = (project.entities || []).find(e => e.id == instance_id)

  return (
    <>
      <Col padding="0 0 0 20px">
        <RelationDataTabs
          key={relation.id}
          project={project}
          relation={relation}
          instance_id={instance_id}
          setInstanceID={setInstanceID}
          setInstanceEntity={setInstanceEntity}
          fade_orphans={fade_orphans}
          setFadeOrphans={setFadeOrphans}
        />
      </Col>

      {instance_id && instance_entity && (
        <div style={{ padding: '0 0 0 20px' }}>
          <EntityInstanceInfo
            key={instance_id}
            project={project}
            entity={instance_entity}
            id={instance_id}
          />
        </div>
      )}
    </>
  )
}
