import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Button, gray, color, Hue } from '../UI'
import { SignOutButton } from '../Users'
import { useAuthUser } from '../Users/Session'
import * as ROUTES from '../../constants/routes'
import * as ROLES from '../../constants/roles'

// import logo from '../../cropped-Logga-1.png'

import './Navigation.css'

export function Navigation() {
  const user = useAuthUser()

  return (
    <div className="app-navbar" style={{ background: gray(2) }}>
      <Link to={ROUTES.HOME} style={{ paddingTop: '4px' }}>
        <Row>
          {/* <img src={logo} height={20} />{' '} */}
          <span style={{ marginLeft: '10px', color: color(Hue.Cyan, 5, 6), fontSize: '20px' }}>
            {/* ClearVision ß */}
            Knowledge Modeller
          </span>
        </Row>
      </Link>

      <div>
        {/* <NavButton to={ROUTES.HOME} text="Home" visible={!!user} /> */}
        <NavButton to={ROUTES.ACCOUNT} text="Account" visible={!!user} />
        <NavButton
          to={ROUTES.ADMIN}
          text="Admin"
          visible={!!user && user.roles.includes(ROLES.ADMIN)}
        />
        {/* <SignInButton visible={!user} /> */}
        <SignOutButton visible={!!user} />
      </div>
    </div>
  )
}

function NavButton({ to, text, visible }) {
  return visible ? (
    <Link to={to} style={{ marginRight: '5px' }}>
      <Button>{text}</Button>
    </Link>
  ) : null
}

// function SignInButton({ visible }) {
//   return visible ? (
//     <Link to={ROUTES.SIGN_IN}>
//       <Button>Sign In</Button>
//     </Link>
//   ) : null
// }
