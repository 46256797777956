import styled from 'styled-components'
import { color, gray } from '.'

//--------------------------------------------------------------------------------------------------

function shade(hue: number | string | undefined, lum: number, sat?: number): string {
  return hue != null ? color(hue, lum, sat) : gray(lum)
}

interface PanelProps {
  row?: boolean
  col?: boolean
  wrap?: boolean
  outline?: boolean
  hue?: number | string
  round?: boolean
  minimal?: boolean
  width?: string
  height?: string
}

export const Panel = styled.div<PanelProps>`
  padding: 10px;
  ${p => (p.row || p.col || p.wrap ? 'display: flex' : '')};
  ${p => (p.col ? 'flex-direction: column' : '')};
  ${p => (p.wrap ? 'flex-wrap: wrap' : '')};
  ${p => (p.outline ? `border: 1px solid ${shade(p.hue, 3)}` : '')};
  ${p => (p.round ? 'border-radius: 8px' : '')};
  ${p => (!p.minimal ? `background: ${shade(p.hue, 5)}` : '')};
  ${p => (p.width ? `width: ${p.width}` : '')};
  ${p => (p.height ? `height: ${p.height}` : '')};
`
