import React, { useState } from 'react'
import styled from 'styled-components'
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc'
import * as ROLES from '../../constants/roles'
import { gray, color, Tooltip, Icon } from '../UI'

import './Table.css'

//--------------------------------------------------------------------------------------------------

function checkPropsChange(props, nextProps) {
  if (props.selection_id !== nextProps.selection_id || props.objs !== nextProps.objs) {
    // console.log('Table props changed!')
    return false
  }
  return true
}

const AddBtn = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  width: 22px;
  height: 22px;

  &:hover {
    background: white;
    color: ${() => gray(3)};
    transition: 0.2s;
  }
`

const ShowBtn = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  width: 22px;
  height: 22px;
  background: ${({ showHidden }) => (showHidden ? gray(5) : gray(3))};
  color: ${({ showHidden }) => (showHidden ? gray(3) : 'white')};

  &:hover {
    background: white;
    color: ${() => gray(3)};
    transition: 0.2s;
  }
`

const Wrapper = styled.div`
  display: inline-block;
  ${({ isData }) => (isData ? 'overflow-y: scroll' : '')};
  ${({ isData }) => (isData ? 'max-height: 800px' : '')};
  ${({ width }) => (width ? `width: ${width}` : '')};

  table {
    width: 100%;
    background: white;
    border-collapse: collapse;
    ${({ isItem }) => (isItem ? `border-radius: 4px` : '')};
    ${({ isItem }) => (isItem ? `overflow: hidden` : '')};
  }
  th {
    position: sticky;
    top: 0px;
  }
  th {
    color: white;
    background: ${() => gray(3)};
    font-weight: 400;
    text-align: left;
    padding: 4px ${({ isData }) => (isData ? 4 : 2)}px;
    ${({ isData }) => (isData ? `border-right: 1px solid ${gray(5)}` : '')};
  }
  th:first-child {
    padding-left: 4px;
  }
  th:last-child {
    padding-right: 4px;
  }
  .action {
    border: none;
    width: 22px;
  }
`

const SHOW_IDS = false

/**
 * @param {object} props
 * @param {string} [props.width]
 * @param {string} [props.selection_id]
 * @param {any} [props.setSelectionID]
 * @param {any} [props.user]
 * @param {import("../types").Project} [props.project]
 * @param {boolean} [props.showHue]
 * @param {any} [props.AddDialog]
 * @param {any} [props.addProps]
 * @param {string} [props.addTip]
 * @param {any} [props.EditDialog]
 * @param {any} [props.editProps]
 * @param {string} [props.editTip]
 * @param {string} [props.showTip]
 * @param {string} [props.hideTip]
 * @param {any} [props.update]
 * @param {string} [props.ident]
 * @param {any} [props.type]
 * @param {any} [props.cols]
 * @param {any} [props.objs]
 * @param {boolean} [props.isData]
 * @param {boolean} [props.isItem]
 * @param {any} [props.onDragEnd]
 */

export const Table = React.memo(
  ({
    width,
    selection_id,
    setSelectionID,
    user,
    project,
    showHue,
    AddDialog,
    addProps,
    addTip,
    EditDialog,
    editProps,
    editTip,
    showTip,
    hideTip,
    update,
    ident,
    type,
    cols,
    objs,
    isData,
    isItem,
    onDragEnd
  }) => {
    const [addDialogIsOpen, setAddDialogIsOpen] = useState(false)
    const [editDialogIsOpen, setEditDialogIsOpen] = useState(false)
    const [editObject, setEditObject] = useState(null)
    const [showHidden, setShowHidden] = useState(false)

    const data = (objs || []).filter(e => !e.hidden || showHidden)

    const addBtn = (
      <Tooltip content={addTip}>
        <AddBtn onClick={() => setAddDialogIsOpen(true)}>
          <Icon icon="plus-square" />
        </AddBtn>
      </Tooltip>
    )

    const showBtn = showTip ? (
      <Tooltip content={showTip}>
        <ShowBtn onClick={() => setShowHidden(!showHidden)} {...{ showHidden }}>
          <Icon icon="eye" />
        </ShowBtn>
      </Tooltip>
    ) : null

    const handle_style = { textAlign: 'center', cursor: 'ns-resize', color: gray(3) }
    const DragHandle = sortableHandle(() => <td style={handle_style}>: :</td>)
    const Body = ({ children }) => <tbody>{children}</tbody>
    const DragableRow = onDragEnd ? sortableElement(Row) : Row
    const DragableBody = onDragEnd ? sortableContainer(Body) : Body
    const drag_col_style = { width: '22px' }

    return (
      <Wrapper width={width}>
        <table>
          <thead>
            <tr>
              {onDragEnd ? <th style={drag_col_style} /> : null}
              {SHOW_IDS && user && user.roles.includes(ROLES.SUPER) ? <th width="1%">ID</th> : null}
              {showHue && <th />}
              {(cols || []).map(col => (
                <th
                  key={col.label}
                  style={{ background: col.hue ? color(Number(col.hue), 3) : gray(3) }}
                >
                  {col.label}
                </th>
              ))}
              {AddDialog ? <th className="action">{addBtn}</th> : null}
              {isData ? null : showTip ? <th className="action">{showBtn}</th> : null}
            </tr>
          </thead>
          <DragableBody
            onSortEnd={onDragEnd}
            useDragHandle
            lockAxis="y"
            helperClass="sortableHelper"
          >
            {data.map((datum, index) => (
              <DragableRow
                key={datum.id}
                index={index}
                {...{
                  datum,
                  selection_id,
                  onDragEnd,
                  DragHandle,
                  user,
                  showHue,
                  setSelectionID,
                  cols,
                  AddDialog,
                  EditDialog,
                  isData,
                  showTip,
                  editTip,
                  setEditObject,
                  setEditDialogIsOpen,
                  hideTip,
                  update,
                  ident,
                  project
                }}
              />
            ))}
          </DragableBody>
        </table>

        {addDialogIsOpen && (
          <AddDialog setOpen={setAddDialogIsOpen} {...addProps || { project, type }} />
        )}

        {editDialogIsOpen && editObject && (
          <EditDialog
            {...editProps || { project, update }}
            object={editObject}
            setOpen={setEditDialogIsOpen}
          />
        )}
      </Wrapper>
    )
  },
  checkPropsChange
)

//--------------------------------------------------------------------------------------------------

const TblRow = styled.tr`
  border-bottom: 1px solid ${() => gray(5)};
  background: ${({ datum, selection_id }) =>
    datum.id && datum.id === selection_id ? '#aff' : 'none'};

  td {
    padding: 4px ${({ isData }) => (isData ? 4 : 2)}px 0 ${({ isData }) => (isData ? 4 : 2)}px;
    vertical-align: top;
  }
  td:first-child {
    padding-left: 4px;
  }
  td:last-child {
    padding-right: 4px;
  }
`
const Super = styled.td`
  font-size: 12;
  color: #aaa;
  width: 1%;
`
const Hue = styled.td`
  width: 16px;
`
const Action = styled.td`
  text-align: center;
  width: 22px;
  border: none;
  border-bottom: 1px solid ${() => gray(5)};
  padding-top: 2px;
`

function Row({
  index,
  datum,
  selection_id,
  onDragEnd,
  DragHandle,
  user,
  showHue,
  setSelectionID,
  cols,
  AddDialog,
  EditDialog,
  isData,
  showTip,
  editTip,
  setEditObject,
  setEditDialogIsOpen,
  hideTip,
  update,
  ident,
  project
}) {
  return (
    <TblRow {...{ datum, selection_id }} className={datum.hidden ? 'hidden' : ''}>
      {onDragEnd ? <DragHandle /> : null}
      {SHOW_IDS && user && user.roles.includes(ROLES.SUPER) ? <Super>{datum.id}</Super> : null}

      {showHue ? (
        <Hue
          onClick={() =>
            setSelectionID && setSelectionID(datum.id === selection_id ? null : datum.id)
          }
        >
          <svg width={16} height={18}>
            <circle
              cx={8}
              cy={9}
              r={7}
              stroke="black"
              fill={datum.hue ? color(datum.hue | 0, 3, 5) : 'none'}
            />
          </svg>
        </Hue>
      ) : null}

      {(cols || []).map((col, i) => (
        <Cell
          key={col.label}
          style={col.style && col.style(datum, i)}
          {...{ datum, setSelectionID, selection_id, isData }}
        >
          {col.cell(datum, i)}
        </Cell>
      ))}

      {AddDialog ? (
        <Action>
          {EditDialog ? (
            <EditBtn {...{ datum, editTip, setEditObject, setEditDialogIsOpen }} />
          ) : null}
        </Action>
      ) : null}
      {isData ? null : showTip ? (
        <Action>
          <HideBtn {...{ datum, hideTip, update, ident, project }} />
        </Action>
      ) : null}
    </TblRow>
  )
}

//--------------------------------------------------------------------------------------------------

const TblCell = styled.td`
  border-bottom: 1px solid ${() => gray(5)};
  vertical-align: top;
  padding: 4px ${({ isData }) => (isData ? 4 : 2)}px 4px ${({ isData }) => (isData ? 4 : 2)}px;
  ${({ isData }) => (isData ? `border-right: 1px solid ${gray(5)}` : '')};

  &:first-child {
    padding-left: 4px;
  }
  &:last-child {
    padding-right: 4px;
  }
`

function Cell({ children, style, datum, setSelectionID, selection_id, isData }) {
  return (
    <TblCell
      style={style || {}}
      onClick={() => setSelectionID && setSelectionID(datum.id === selection_id ? null : datum.id)}
    >
      {children}
    </TblCell>
  )
}

//--------------------------------------------------------------------------------------------------

const RowBtn = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 2px;
  color: ${() => gray(3)};
  width: 22px;
  height: 22px;

  div:hover {
    color: white;
    background: ${() => gray(3)};
    transition: 0.2s;
  }
`

function EditBtn({ datum, editTip, setEditObject, setEditDialogIsOpen }) {
  return (
    <Tooltip content={editTip}>
      <RowBtn
        onClick={() => {
          setEditObject(datum)
          setEditDialogIsOpen(true)
        }}
      >
        <Icon icon="edit" />
      </RowBtn>
    </Tooltip>
  )
}

//--------------------------------------------------------------------------------------------------

// const Hide = styled.div`
//   cursor: pointer;
//   text-align: center;
//   padding: 2px;
//   color: ${() => gray(3)};
//   width: 22px;
//   height: 22px;

//   &:hover {
//     color: white;
//     background: ${() => gray(3)};
//     transition: 0.2s;
//   }
// `

function HideBtn({ datum, hideTip, update, ident, project }) {
  const [hidden, setHidden] = useState(datum.hidden)

  return (
    <Tooltip content={(hidden ? 'Show ' : 'Hide ') + hideTip}>
      <RowBtn
        onClick={() => {
          datum.hidden = !datum.hidden
          update && update(ident ? { [ident]: project[ident] } : datum)
          setHidden(!hidden)
        }}
      >
        <Icon icon={datum.hidden ? 'eye' : 'eye-slash'} />
      </RowBtn>
    </Tooltip>
  )
}
