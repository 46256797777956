import React from 'react'
import styled from 'styled-components'
import { Icon, color } from '.'

//--------------------------------------------------------------------------------------------------

const Msg = styled.div<{ colour: string }>`
  display: flex;
  color: ${p => p.colour};
  padding: 0.5em;
  border-radius: 4px;
  border: 1px solid ${p => p.colour};

  div span {
    padding-left: 8px;
  }
`

interface MessageProps {
  msg: string
  intent: 'error'
}

export function Message({ msg, intent }: MessageProps) {
  const colour = {
    error: color('red', 3)
  }[intent]

  const icon = {
    error: 'exclamation-triangle'
  }[intent]

  return msg ? (
    <>
      <Msg colour={colour}>
        <Icon icon={icon} color={colour} padding="3px 0 0 0" />
        <span>{msg}</span>
      </Msg>
    </>
  ) : null
}
