// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import generate from 'nanoid/generate'
import { useFirebase } from '../Firebase'
import { Error, DialogBody, DialogFooter } from '../Parts'
import { Name, CancelOrCreate } from '../UI'
import { StepListUI } from '.'

//--------------------------------------------------------------------------------------------------

/**
 * A dialog for creating a query
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {boolean} props.open
 * @param {(open:boolean) => void} props.setOpen
 */

export function NewQueryDialog({ project, setOpen }) {
  const firebase = useFirebase()

  const [error, setError] = useState(null)
  const [name, setName] = useState('')
  const [steps, setSteps] = useState([])

  const isInvalid = !project || name.length == 0 || steps.length == 0

  return (
    <Dialog
      title="Create New Query"
      icon="add"
      isOpen={true}
      onClose={close}
      canEscapeKeyClose={false}
    >
      <DialogBody>
        <Name value={name} setValue={setName} />
        <StepListUI value={steps} setValue={setSteps} project={project} />
        <Error error={error} />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={create} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
    setName('')
    setSteps([])
  }

  function create() {
    const queries = project.queries || []
    const id = generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4)
    firebase
      .project(project.id)
      .update({ queries: [...queries, { id, name, steps }] })
      .then(close)
      .catch(setError)
  }
}
