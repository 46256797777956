import React from 'react'
import { TabGroup } from '../UI'
import { withAuthorization } from '../Users/Session'
import { useDatum } from '../Firebase'
import { AdminPanel, SchemaPanel, DataPanel, QueryPanel } from '.'

export const ProjectPage = withAuthorization(authUser => !!authUser)(({ match: { params } }) => {
  const [project, loading, update] = useDatum('projects', params.id)

  return (
    <div>
      {loading ? (
        <div>Loading ...</div>
      ) : project ? (
        <Project project={project} update={update} />
      ) : null}
    </div>
  )
})

function Project({ project, update }) {
  return (
    <>
      <h1>
        <span style={{ fontWeight: 'normal' }}>Project:</span> {project.name}
      </h1>

      <TabGroup vertical initial="admin" big minimal>
        <TabGroup.Tabs>
          <TabGroup.Tab id="admin">Admin</TabGroup.Tab>
          <TabGroup.Tab id="schema">Model</TabGroup.Tab>
          <TabGroup.Tab id="data">Data</TabGroup.Tab>
          <TabGroup.Tab id="query">Queries</TabGroup.Tab>
        </TabGroup.Tabs>

        <TabGroup.Content of="admin">
          <AdminPanel project={project} update={update} />
        </TabGroup.Content>

        <TabGroup.Content of="schema">
          <SchemaPanel project={project} update={update} />
        </TabGroup.Content>

        <TabGroup.Content of="data">
          <DataPanel project={project} update={update} />
        </TabGroup.Content>

        <TabGroup.Content of="query">
          <QueryPanel project={project} update={update} />
        </TabGroup.Content>
      </TabGroup>
    </>
  )
}
