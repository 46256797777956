import { useEffect } from 'react'
// import styled from 'styled-components'
// import { hsluvToHex, hsluvToRgb } from 'hsluv'
import { Hue } from './types'

function hslToRgb(h: number, s: number, l: number) {
  var r, g, b

  if (s == 0) {
    r = g = b = l // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s
    var p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [r * 255, g * 255, b * 255]
}

function hsluvToHex([h, s, l]: [number, number, number]) {
  const RGB = hslToRgb(h / 360, s / 100, l / 100)
  return `#${RGB.map(x => (x | 0).toString(16).padStart(2, '0')).join('')}`
}

function hsluvToRgb([h, s, l]: [number, number, number]) {
  const RGB = hslToRgb(h / 360, s / 100, l / 100)
  return RGB.map(x => x / 255)
}

export const font_family =
  '-apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", "Helvetica Neue", "Icons16", sans-serif'
export const font_size = '14px'
export const big_font_size = '16px'

//--------------------------------------------------------------------------------------------------

export const iota = (n: number) => [...Array(n).keys()]

//--------------------------------------------------------------------------------------------------

// /** @enum {number} */
// export const Hue = {
//   Gray: 0,
//   // Red: 15,
//   // Orange: 45,
//   // Yellow: 75,
//   // Lime: 105,
//   // Green: 135,
//   // Teal: 165,
//   // Cyan: 195,
//   // Sky: 225,
//   // Blue: 255,
//   // Purple: 285,
//   // Magenta: 315,
//   // Pink: 345
//   Red: 10,
//   Orange: 30,
//   Amber: 45,
//   Yellow: 55,
//   Lime: 73,
//   Green: 95,
//   Opal: 160,
//   Cyan: 185,
//   Azure: 200,
//   Blue: 230,
//   Violet: 270,
//   Purple: 290,
//   Pink: 320
// }

//--------------------------------------------------------------------------------------------------

export const HUE_NAME = [
  { hue: Hue.Gray, val: 0, name: 'gray' },
  // { hue: Hue.Red, val: 15, name: 'red' },
  // { hue: Hue.Orange, val: 45, name: 'orange' },
  // { hue: Hue.Yellow, val: 75, name: 'yellow' },
  // { hue: Hue.Lime, val: 105, name: 'lime' },
  // { hue: Hue.Green, val: 135, name: 'green' },
  // { hue: Hue.Teal, val: 165, name: 'teal' },
  // { hue: Hue.Cyan, val: 195, name: 'cyan' },
  // { hue: Hue.Sky, val: 225, name: 'sky' },
  // { hue: Hue.Blue, val: 255, name: 'blue' },
  // { hue: Hue.Purple, val: 285, name: 'purple' },
  // { hue: Hue.Magenta, val: 315, name: 'magenta' },
  // { hue: Hue.Pink, val: 345, name: 'pink' }
  { hue: Hue.Red, val: 10, name: 'red' }, // 15
  { hue: Hue.Orange, val: 30, name: 'orange' }, // 45
  { hue: Hue.Amber, val: 45, name: 'amber' }, // 45 NEW!
  { hue: Hue.Yellow, val: 55, name: 'yellow' }, // 75
  { hue: Hue.Lime, val: 73, name: 'lime' }, // 105
  { hue: Hue.Green, val: 95, name: 'green' }, // 135
  { hue: Hue.Opal, val: 160, name: 'opal' }, // 165 Teal
  { hue: Hue.Cyan, val: 185, name: 'cyan' }, // 195
  { hue: Hue.Azure, val: 200, name: 'azure' }, // 225 Sky
  { hue: Hue.Blue, val: 230, name: 'blue' }, // 255
  { hue: Hue.Violet, val: 270, name: 'violet' }, // 285 Purple
  { hue: Hue.Purple, val: 290, name: 'purple' }, // 315 Magenta
  { hue: Hue.Pink, val: 320, name: 'pink' } // 345
]

// const HUES = iota(HUE_NAME.length).map(i => HUE_NAME[i].hue)
const SATS = [0, ...iota(5).map(i => 10 + 20 * i), 100]
// const LUMS = [0, ...iota(5).map(i => 10 + 20 * i), 97, 100]
const LUMS = [0, 0.25, 0.4, 0.5, 0.75, 0.9, 1].map(x => x * 100)

export function gray(lum: number) {
  return hsluvToHex([0, 0, LUMS[lum]])
}

export function color(hue: number | string, lum: number, sat: number = 5) {
  if (typeof hue === 'string') {
    const idx = HUE_NAME.findIndex(({ name }) => name === hue)
    if (idx > -1) {
      hue = HUE_NAME[idx].hue
    } else {
      hue = 0
    }
  } else {
    // hue = HUES[hue || 0]
  }

  if (hue === 0) {
    sat = 0
  }

  const hex = hsluvToHex([hue, SATS[sat], LUMS[lum]])
  return hex
}

export function gray3(lum: number) {
  const [r, g, b] = hsluvToRgb([0, 0, LUMS[lum]])
  return (((r * 255) | 0) << 16) + (((g * 255) | 0) << 8) + ((b * 255) | 0)
}

export function color3(hue: number, lum: number = 4, sat: number = 5) {
  if (hue === 0) {
    sat = 0
  }

  const [r, g, b] = hsluvToRgb([hue, SATS[sat], LUMS[lum]])
  return (((r * 255) | 0) << 16) + (((g * 255) | 0) << 8) + ((b * 255) | 0)
}

export function hue_name(h: number) {
  if (h < 15) {
    h = 15 + 30 * h
  }
  const idx = HUE_NAME.findIndex(({ hue }) => hue === h)
  if (idx > -1) {
    return HUE_NAME[idx].name
  }
  return ''
}

export const hue_names = HUE_NAME.map(({ name }) => name)

//--------------------------------------------------------------------------------------------------

// export function MarkDown({ text }) {
//   return <MDReactComponent text={text} />
// }

//--------------------------------------------------------------------------------------------------

// export function useTitle(titles: string[], watch?: any[]) {
//   // let watch = []

//   // if (Array.isArray(titles[titles.length - 1])) {
//   //   watch = titles.pop()
//   // }

//   useEffect(() => {
//     document.title = ['MK1', ...titles].join(' : ')
//   }, watch)
// }
