import React, { useState } from 'react'
import styled from 'styled-components'
import { ChordDiagram, prepChordData, Tooltip } from '.'
import { entity_instance_hue, useEntityInstanceIndex } from '../Data'
import { useObservable, watch } from '../Firebase'
import { data_collection_name, find_entity } from '../Schema'
import { gray, color, Col, Row } from '../UI'

//--------------------------------------------------------------------------------------------------

const Top = styled.div`
  width: ${props => props.width}px;
  padding: 6px;
  color: white;
  height: 30px;
  text-align: center;
`

const LayerName = styled(Top)`
  background: ${props => (props.entity.hue ? color(Number(props.entity.hue), 2, 4) : gray(3))};
`

const Sep = styled.div`
  background: white;
  width: 2px;
`

const Selection = styled(Top)`
  background: ${props => (props.selected ? '#aff' : 'white')};
  color: black;
  text-align: center;
  width: 100%;
  transition: 0.3s;
`

//--------------------------------------------------------------------------------------------------

export function RelationChords({
  project,
  relation,
  selection_id,
  setSelectionID,
  setSelectionEntity,
  caption
}) {
  const subj_entity = find_entity(project, relation.subj_id)
  const subjs_index = useEntityInstanceIndex(project, subj_entity)

  const dobj_entity = find_entity(project, relation.dobj_id)
  const dobjs_index = useEntityInstanceIndex(project, dobj_entity)

  const relation_data = useObservable(watch(data_collection_name(project.id, relation.id)), [])

  const relations = (relation_data || []).filter(r => !r.hidden)

  function instance_name(index, id) {
    const instance = index[id]

    if (instance) {
      return instance._name
    }

    return `<instance ${id.subj_id}>`
  }

  const [hovered, setHovered] = useState(null)
  const [linked_ids, setLinkedIDs] = useState([])
  const [selected_layer_index, setSelectedLayerIndex] = useState(-1)

  if (relation_data == null || subjs_index == null || dobjs_index == null) {
    return <p>Loading...</p>
  }

  if (
    relations.length === 0 ||
    Object.values(subjs_index).length === 0 ||
    Object.values(dobjs_index).length === 0
  ) {
    return <p>Data not available.</p>
  }

  //................................................................................................

  const chords_data = prepChordData(
    Object.values(subjs_index),
    relations,
    Object.values(dobjs_index)
  )

  function hovered_name() {
    if (hovered) {
      if (hovered.id in subjs_index) {
        return instance_name(subjs_index, hovered.id)
      }
      if (hovered.id in dobjs_index) {
        return instance_name(dobjs_index, hovered.id)
      }
    }
    return ''
  }

  function selected_name() {
    if (selection_id) {
      if (selection_id in subjs_index) {
        return `${subj_entity.name}: ${instance_name(subjs_index, selection_id)}`
      }
      if (selection_id in dobjs_index) {
        return `${dobj_entity.name}: ${instance_name(dobjs_index, selection_id)}`
      }
    }
    return ''
  }

  function arcOver(pt, entity_instance_id) {
    setHovered({
      id: entity_instance_id,
      coords: {
        left: pt.x,
        top: pt.y - 5
      }
    })
  }

  function arcLeave() {
    setHovered(null)
  }

  function arcClick(entity_instance_id, layer_index) {
    if (entity_instance_id == null || entity_instance_id === selection_id) {
      setSelectionID(null)
      setSelectionEntity(null)
      setLinkedIDs([])
      setSelectedLayerIndex(-1)
    } else {
      setSelectionID(entity_instance_id)
      setSelectedLayerIndex(layer_index)
      if (/*layer_index === 0 &&*/ entity_instance_id in subjs_index) {
        setSelectionEntity(subj_entity)
        setLinkedIDs(relations.filter(r => r.subj_id === entity_instance_id).map(r => r.dobj_id))
      } else if (/*layer_index === 1 &&*/ entity_instance_id in dobjs_index) {
        setSelectionEntity(dobj_entity)
        setLinkedIDs(relations.filter(r => r.dobj_id === entity_instance_id).map(r => r.subj_id))
      }
    }
  }

  function arcColor(entity_instance_id, layer_index) {
    if (linked_ids.includes(entity_instance_id) /*&& layer_index !== selected_layer_index*/) {
      return '#add'
    }
    if (entity_instance_id === selection_id /*&& layer_index === selected_layer_index*/) {
      return '#aff'
    }

    if (/*layer_index === 0 &&*/ entity_instance_id in subjs_index) {
      return color(entity_instance_hue(project, subjs_index[entity_instance_id]), 3, 4)
    }
    if (/*layer_index === 1 &&*/ entity_instance_id in dobjs_index) {
      return color(entity_instance_hue(project, dobjs_index[entity_instance_id]), 3, 4)
    }
    return gray(3)
  }

  function chordColor(source_id, target_id) {
    // if (source_id === hovered || target_id === hovered) {
    //   return { color: relation.hue ? color(Number(relation.hue), 4, 4) : gray(3), opacity: 0.5 }
    // }

    if (target_id === selection_id) {
      return {
        // color: subj_entity.hue ? color(Number(subj_entity.hue), 4, 6) : gray(3),
        color: '#add',
        opacity: 0.6
      }
    }
    if (source_id === selection_id) {
      return {
        // color: dobj_entity.hue ? color(Number(dobj_entity.hue), 4, 6) : gray(3),
        color: '#add',
        opacity: 0.6
      }
    }

    return { color: relation.hue ? color(Number(relation.hue), 4) : gray(4), opacity: 0.4 }
  }

  const width = 800

  return relation_data == null ? (
    <p>Loading...</p>
  ) : relations.length > 0 ? (
    <Col>
      <Row>
        <LayerName width={width / 2 - 1} entity={subj_entity}>
          {subj_entity.name}
        </LayerName>
        <Sep />
        <LayerName width={width / 2 - 1} entity={dobj_entity}>
          {dobj_entity.name}
        </LayerName>
      </Row>
      <Selection selected={!!selection_id}>{selected_name()}</Selection>

      <ChordDiagram
        style={{ background: 'white' }}
        size={width}
        data={chords_data}
        arcColor={arcColor}
        chordColor={chordColor}
        arcOver={arcOver}
        arcLeave={arcLeave}
        arcClick={arcClick}
      />

      {caption}
      {hovered && <Tooltip coords={hovered.coords} content={hovered_name()} />}
    </Col>
  ) : (
    <p>No instances available.</p>
  )
}
