import React from 'react'
import { useObservable, watch } from '../Firebase'
import { useEntityInstanceIndex } from '../Data'
import { data_collection_name, find_entity } from '../Schema'
import { DiscsView } from '.'

export function RelationDiscs({
  project,
  relation,
  selection_id,
  setSelectionID,
  setSelectionEntity,
  caption,
  fadeOrphans
}) {
  const subj_entity = find_entity(project, relation.subj_id)
  const subjs_index = useEntityInstanceIndex(project, subj_entity)

  const dobj_entity = find_entity(project, relation.dobj_id)
  const dobjs_index = useEntityInstanceIndex(project, dobj_entity)

  const relation_data = useObservable(watch(data_collection_name(project.id, relation.id)), null)

  if (relation_data == null || subjs_index == null || dobjs_index == null) {
    return <p>Loading...</p>
  }

  const relations = relation_data.filter(
    r => !r.hidden && !subjs_index[r.subj_id]?.hidden && !dobjs_index[r.dobj_id]?.hidden
  )

  if (
    relations.length === 0 ||
    Object.values(subjs_index).length === 0 ||
    Object.values(dobjs_index).length === 0
  ) {
    return <p>Data not available.</p>
  }

  const layer_defs = [
    {
      name: subj_entity.name,
      entity: subj_entity,
      instances: Object.values(subjs_index),
      index: subjs_index
    },
    {
      name: dobj_entity.name,
      entity: dobj_entity,
      instances: Object.values(dobjs_index),
      index: dobjs_index
    }
  ]

  const link_defs = [{ relation, instances: relation_data }]

  //................................................................................................

  return (
    <DiscsView
      {...{
        project,
        layer_defs,
        link_defs,
        selection_id,
        setSelectionID,
        setSelectionEntity,
        caption,
        fadeOrphans
      }}
    />
  )
}
