import React, { useState, useEffect } from 'react'
import { Panel, Button, TabGroup } from '../UI'

import { forceSimulation, forceLink, forceManyBody, forceCollide, forceX, forceY } from 'd3-force'

import { EntityList, RelationList, SchemaGraph, DataModal } from '../Schema'
import { Col, Row } from '../UI'

export function SchemaPanel({ project, update }) {
  const [simulation, setSimulation] = useState()
  const [nodes, setNodes] = useState([])
  const [links, setLinks] = useState([])

  const [selection_id, setSelectionID] = useState(null)
  const [scale, setScale] = useState(0.55)
  const [pan, setPan] = useState([0, 0])
  const [instances_id, setInstancesID] = useState(null)
  // const [open, setOpen] = useState(false)

  function setOpen(open) {
    if (!open) {
      setInstancesID(null)
    }
  }

  useEffect(() => {
    const nodes = []
    const links = []

    const entities = (project.entities || []).filter(e => !e.hidden)
    for (let entity of entities) {
      nodes.push({ ...entity, label: 'entity' })
      for (let isa_id of entity.isa_ids || []) {
        links.push({
          source: entity.id,
          target: isa_id,
          id: `${entity.id}:${isa_id}`,
          verb: 'isa'
        })
      }
    }

    for (let relation of (project.relations || []).filter(
      r =>
        !r.hidden &&
        entities.find(e => e.id === r.subj_id) &&
        entities.find(e => e.id === r.dobj_id)
    )) {
      // if (relation.verb === 'isa') {
      //   links.push({
      //     source: relation.subj_id,
      //     target: relation.dobj_id,
      //     id: relation.id,
      //     verb: 'isa'
      //   })
      // } else {
      const link1 = { source: relation.subj_id, target: relation.id, hue: relation.hue }
      const link2 = { source: relation.id, target: relation.dobj_id, hue: relation.hue }
      nodes.push({ ...relation, label: 'relation', link1, link2 })
      links.push(link1)
      links.push(link2)
      // }
    }

    try {
      setNodes(nodes)
      setLinks(links)

      setSimulation(
        forceSimulation(nodes)
          .force('x', forceX().strength(0.05))
          .force('y', forceY().strength(0.05))
          .force(
            'link',
            forceLink(links)
              .id(d => d.id)
              .distance(80)
          )
          .force('charge', forceManyBody().strength(-400))
          .force('collide', forceCollide(d => (d.label === 'entity' ? 60 : 20)))
      )
    } catch {}

    return () => {
      if (simulation) {
        simulation.stop()
      }
      // update({entities, relations})
    }
  }, [project.entities, project.relations])

  return (
    <Panel>
      {instances_id ? (
        <DataModal project={project} instances_id={instances_id} setOpen={setOpen} />
      ) : null}
      <Row>
        <TabGroup initial="entities">
          <TabGroup.Tabs>
            <TabGroup.Tab id="entities">Entities</TabGroup.Tab>
            <TabGroup.Tab id="relations">Relationships</TabGroup.Tab>
          </TabGroup.Tabs>

          <TabGroup.Content of="entities">
            <EntityList
              project={project}
              update={update}
              selection_id={selection_id}
              setSelectionID={setSelectionID}
            />
          </TabGroup.Content>

          <TabGroup.Content of="relations">
            <RelationList
              project={project}
              update={update}
              selection_id={selection_id}
              setSelectionID={setSelectionID}
            />
          </TabGroup.Content>
        </TabGroup>

        <Col padding="0 0 0 20px">
          <SchemaGraph
            simulation={simulation}
            nodes={nodes}
            links={links}
            project={project}
            update={update}
            // entities={project.entities}
            // relations={project.relations}
            selection_id={selection_id}
            setSelectionID={setSelectionID}
            instances_id={instances_id}
            setInstancesID={setInstancesID}
            width={800}
            height={800}
            scale={scale}
            setScale={setScale}
            pan={pan}
            setPan={setPan}
          />
          <Row width="800px" padding="10px 0 0 0">
            <Button
              margin="0 5px 0 0"
              wrap
              onClick={() => update({ entities: project.entities, relations: project.relations })}
            >
              Save Layout
            </Button>

            <Button
              margin="0 5px 0 0"
              wrap
              onClick={() => {
                nodes.forEach(node => {
                  node.fx = null
                  node.fy = null
                })

                simulation
                  .alpha(0.3)
                  .alphaTarget(0)
                  .restart()

                for (let entity of project.entities || []) {
                  entity.fx = null
                  entity.fy = null
                }

                for (let relation of project.relations || []) {
                  relation.fx = null
                  relation.fy = null
                }
              }}
            >
              Un-fix Layout
            </Button>

            <Button
              margin="0 5px 0 0"
              wrap
              onClick={() => {
                nodes.forEach(node => {
                  node.fx = node.x
                  node.fy = node.y
                })
              }}
            >
              Fix Layout
            </Button>

            <Button
              margin="0 5px 0 0"
              wrap
              onClick={() =>
                simulation
                  .alpha(0.3)
                  .alphaTarget(0)
                  .restart()
              }
            >
              "Reheat" Layout
            </Button>

            <div>
              The layout can be adjusted by dragging objects to a desired, fixed position.
              Shift-clicking an object will toggle it between fixed and floating. Saving the layout
              will save the fixed positions only.
            </div>
          </Row>
        </Col>
      </Row>
    </Panel>
  )
}
