import React, { useState } from 'react'
import useRouter from 'use-react-router'
import { Button, FormInput, FormButtons, FormError, FormPassword, Panel } from '../UI'

import { useFirebase } from '../Firebase'
import * as ROUTES from '../../constants/routes'

//------------------------------------------------------------------------------

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use'

// const ERROR_MSG_ACCOUNT_EXISTS = `
//   An account with this E-Mail address already exists.
//   Try to login with this account instead. If you think the
//   account is already used from one of the social logins, try
//   to sign in with one of them. Afterward, associate your accounts
//   on your personal account page.
// `
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with that account instead.
`

export function SignUpForm() {
  const firebase = useFirebase()
  const { history } = useRouter()

  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  // const [isAdmin, setIsAdmin] = useState(false)
  const [error, setError] = useState(null)

  const isInvalid =
    passwordOne !== passwordTwo || passwordOne === '' || email === '' || username === ''

  return (
    <Panel>
      <FormInput
        label="Full Name"
        info="(required)"
        value={username}
        onChange={e => setUsername(e.target.value)}
        type="text"
      />

      <FormInput
        label="Email Address"
        info="(required)"
        value={email}
        onChange={e => setEmail(e.target.value)}
        type="text"
      />

      <FormPassword value={passwordOne} onChange={e => setPasswordOne(e)} />

      <FormPassword
        label="Confirm Password"
        value={passwordTwo}
        onChange={e => setPasswordTwo(e)}
      />

      {/* <Switch label="Admin" checked={isAdmin} onChange={e => setIsAdmin(e.target.checked)} /> */}

      <FormButtons>
        <Button disabled={isInvalid} onClick={submit}>
          Sign Up
        </Button>
      </FormButtons>

      <FormError error={error ? error.message : null} />
    </Panel>
  )

  function clear() {
    setUsername('')
    setEmail('')
    setPasswordOne('')
    setPasswordTwo('')
    // setIsAdmin(false)
    setError(null)
  }

  function submit(event) {
    const roles = []

    // if (isAdmin) {
    //   roles.push(ROLES.ADMIN)
    // }

    firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return firebase.user(authUser.user.uid).set({ username, email, roles }, { merge: true })
      })
      .then(() => {
        return firebase.doSendEmailVerification()
      })
      .then(() => {
        clear()
        history.push(ROUTES.HOME)
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS
        }

        setError({ error })
      })

    event.preventDefault()
  }
}
