import React from 'react'
import styled from 'styled-components'
import { Icon } from './Icon'
import { color, gray, font_family, font_size, Hue } from '.'

//--------------------------------------------------------------------------------------------------

interface ButtonProps {
  children?: JSX.Element[] | string
  disabled?: boolean
  type?: string
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  icon?: string
  outline?: boolean
  minimal?: boolean
  hue?: string | Hue
  wrap?: boolean
  active?: boolean
  alignRight?: boolean
  margin?: string
  bright?: boolean
}

interface BtnProps extends ButtonProps {
  fg: string
  bg: string
  active_fg: string
  active_bg: string
  hover_fg: string
  hover_bg: string
  iconic: boolean
}

const Btn = styled.button<BtnProps>`
  font-family: ${font_family};
  font-size: ${font_size};
  color: ${p => (p.active ? p.active_fg : p.fg)};
  background: ${p => (p.active ? p.active_bg : p.bg)};
  border: ${p =>
    p.outline ? `1px solid ${p.fg}` : p.minimal || p.bright ? 'none' : `1px solid ${p.bg}`};
  ${p => p.iconic && p.minimal && `padding: 0 3px`};
  white-space: ${p => (p.wrap ? 'normal' : 'nowrap')};
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: ${p => (p.disabled ? 'default' : 'pointer')};
  ${p => (p.wrap ? '' : 'height: 28px')};
  ${p => (p.alignRight ? `float: right` : '')};
  transition: 0.3s;
  ${p => p.margin && `margin: ${p.margin};`}

  button:hover {
    color: ${p => p.hover_fg};
    background: ${p => p.hover_bg};
    border: ${p =>
      p.outline
        ? `1px solid ${p.hover_bg}`
        : p.minimal || p.bright
        ? 'none'
        : `1px solid ${p.hover_fg}`};
  }
`

export function Button({
  children,
  disabled,
  type,
  onClick,
  icon,
  outline,
  minimal,
  hue,
  wrap,
  active,
  alignRight,
  margin,
  bright
}: ButtonProps) {
  function shade(lum: number, sat?: number): string {
    return hue != null ? color(hue, lum, sat) : gray(lum)
  }
  let fg: string,
    bg: string,
    active_fg: string,
    active_bg: string,
    hover_fg: string,
    hover_bg: string
  const iconic = icon && !children

  if (minimal || outline) {
    fg = shade(disabled ? 4 : 3, 6)
    active_fg = shade(2, 6)
    hover_fg = gray(6)
    bg = 'none'
    active_bg = shade(5)
    hover_bg = shade(2)
  } else {
    fg = gray(disabled ? 4 : 6)
    active_fg = bright ? shade(3) : gray(3)
    hover_fg = bright ? shade(3) : gray(3)
    bg = shade(bright ? 4 : 2)
    active_bg = shade(bright ? 7 : 4)
    hover_bg = shade(bright ? 7 : 5)
  }

  let width

  if (iconic) {
    // Icon only button
    width = '28px'
  }
  if (disabled) {
    hover_bg = bg
    hover_fg = fg
  }

  const icn = icon ? <Icon icon={icon} /> : null

  return (
    <Btn
      {...{
        fg,
        bg,
        active,
        active_fg,
        active_bg,
        hover_fg,
        hover_bg,
        minimal,
        outline,
        bright,
        width,
        iconic,
        wrap: wrap ? 'true' : undefined,
        alignRight,
        margin,
        type,
        onClick
      }}
    >
      {alignRight ? null : icn} {children} {alignRight ? icn : null}
    </Btn>
  )
}
