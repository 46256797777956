import React from 'react'
import { AttributeStruct } from './types'
import { FormSelect } from '../UI/FormSelect'

//--------------------------------------------------------------------------------------------------

const structs = [
  { label: 'Single Value', value: AttributeStruct.One },
  { label: 'Set of Unique Values', value: AttributeStruct.Set }
  // { label: 'List of Values', value: AttributeStruct.List }
]

interface AttributeStructUIProps {
  value: AttributeStruct
  setValue: (value: AttributeStruct) => void
}

export function AttributeStructUI({ value, setValue }: AttributeStructUIProps) {
  return <FormSelect label="Structure" value={value} options={structs} onChange={setValue} />
}
