import type { ReactNode } from 'react'
import styled from 'styled-components'

//--------------------------------------------------------------------------------------------------

interface ColProps {
   children: ReactNode
   spacing?: string
   padding?: string
   width?: string
   maxWidth?: string
   centered?: boolean
}

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: column;
  ${p => (p.padding ? `padding: ${p.padding}` : '')};
  ${p => (p.width ? `width: ${p.width}` : '')};
  ${p => (p.maxWidth ? `max-width: ${p.maxWidth};` : '')};
  ${p => (p.centered ? 'margin: 0 auto;' : '')};
`
