import React from 'react'
import { entity_name } from '../Schema'
import { RelationTable } from '../Data'
import { RelationChords, RelationDiscs } from '../View'
import { TabGroup, FormSwitch } from '../UI'

export function RelationDataTabs({
  project,
  relation,
  instance_id,
  setInstanceID,
  setInstanceEntity,
  fade_orphans,
  setFadeOrphans
}) {
  return (
    <TabGroup initial="table">
      <TabGroup.Tabs>
        <TabGroup.Tab id="table">Table</TabGroup.Tab>
        <TabGroup.Tab id="chords">Chords</TabGroup.Tab>
        <TabGroup.Tab id="discs">Discs</TabGroup.Tab>
      </TabGroup.Tabs>

      <h3 style={{ margin: '3px 0 0 10px', display: 'inline' }}>
        {`${entity_name(project, relation.subj_id)} ${relation.verb} ${entity_name(
          project,
          relation.dobj_id
        )}`}
      </h3>

      <TabGroup.Content of="table">
        <RelationTable key={relation.id} project={project} relation={relation} />
      </TabGroup.Content>

      <TabGroup.Content of="chords">
        <RelationChords
          key={relation.id}
          project={project}
          relation={relation}
          selection_id={instance_id}
          setSelectionID={setInstanceID}
          setSelectionEntity={setInstanceEntity}
          caption={
            <div className="data-caption-800">
              Select a circle arc to see more info on that entity
            </div>
          }
        />
      </TabGroup.Content>

      <TabGroup.Content of="discs">
        <RelationDiscs
          key={relation.id}
          project={project}
          relation={relation}
          selection_id={instance_id}
          setSelectionID={setInstanceID}
          setSelectionEntity={setInstanceEntity}
          caption={
            <div className="data-caption-800">
              Select a disc to see more info on that entity
              <FormSwitch
                label="Fade Orphans"
                checked={fade_orphans}
                onChange={setFadeOrphans}
                right
              />
            </div>
          }
          fadeOrphans={fade_orphans}
        />
      </TabGroup.Content>
    </TabGroup>
  )
}
