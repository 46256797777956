import React, { useState } from 'react'
import { Panel, TabGroup, Col } from '../UI'
import { EntityInstanceInfo } from '../Data'
import { QueryList } from '../Query'
import { QueryDataTabs } from '.'

export function QueryPanel({ project, update }) {
  const [selection_id, setSelectionID] = useState(null)
  const [fade_orphans, setFadeOrphans] = useState(true)

  const query = (project.queries || []).find(q => q.id == selection_id)

  return (
    <Panel row>
      <Col>
        <TabGroup initial="queries">
          <TabGroup.Tabs>
            <TabGroup.Tab id="queries">Queries</TabGroup.Tab>
          </TabGroup.Tabs>

          <TabGroup.Content of="queries">
            <QueryList
              project={project}
              update={update}
              selection_id={selection_id}
              setSelectionID={setSelectionID}
            />
          </TabGroup.Content>
        </TabGroup>
        Select an entity or relationship type to see its instances
      </Col>

      {selection_id && query && (
        <QueryColumn2 key={selection_id} {...{ project, query, fade_orphans, setFadeOrphans }} />
      )}
    </Panel>
  )
}

function QueryColumn2({ project, query, fade_orphans, setFadeOrphans }) {
  const [instance_id, setInstanceID] = useState(null)
  const [instance_entity, setInstanceEntity] = useState(null)
  // const instance_entity = (project.entities || []).find(e => e.id == instance_id)

  return (
    <>
      <Col padding="0 0 0 20px">
        <QueryDataTabs
          key={query.id}
          project={project}
          query={query}
          instance_id={instance_id}
          setInstanceID={setInstanceID}
          setInstanceEntity={setInstanceEntity}
          fade_orphans={fade_orphans}
          setFadeOrphans={setFadeOrphans}
        />
      </Col>

      {instance_id && instance_entity && (
        <div style={{ padding: '0 0 0 20px' }}>
          <EntityInstanceInfo
            key={instance_id}
            project={project}
            entity={instance_entity}
            id={instance_id}
          />
        </div>
      )}
    </>
  )
}
