import React, { useState } from 'react'
import styled from 'styled-components'
import { EntityTable, EntityImportDialog } from '../Data'
import { Button } from '../UI'

const DataCaption = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    margin-right: 5px;
  }
`

export function ProjectEntityData({ project, entity, instance_id, setInstanceID, data }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <EntityTable
        project={project}
        entity={entity}
        data={data}
        selection_id={instance_id}
        setSelectionID={setInstanceID}
      />
      <DataCaption>
        <p>Select a row to see more info on that entity</p>
        <Button onClick={() => setOpen(true)}>Import...</Button>
      </DataCaption>

      <EntityImportDialog {...{ project, entity, open, setOpen }} />
    </>
  )
}
