import React from 'react'
import { Col } from '../UI'
import { PasswordForgetForm } from './PasswordForgetForm'

//------------------------------------------------------------------------------

export function PasswordForgetPage() {
  return (
    <Col maxWidth="20em" centered>
      <h1>Forgot Password</h1>
      <PasswordForgetForm />
    </Col>
  )
}
