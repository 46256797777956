// @ts - check

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { Portal } from '.'

//--------------------------------------------------------------------------------------------------

let timer_id

const Tip = styled.div`
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border: 0px solid white;
  border-radius: 8px;
  position: absolute;
  z-index: 1;
  left: ${({ coords }) => coords.left}px;
  top: ${({ coords }) => coords.top - 5}px;
  transform: translate(-60px, -100%);

  div::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`

export function Tooltip({ children, content }) {
  // return <>{children}</> //<TooltipLite content={content}>{children}</TooltipLite>
  const [coords, setCoords] = useState({})
  const [open, setOpen] = useState(false)
  const ref = React.createRef()

  return (
    <>
      <div ref={ref} onMouseEnter={onEnter} onMouseLeave={onLeave}>
        {children}
      </div>

      {open && (
        <Portal>
          <Popover>{content}</Popover>
        </Portal>
      )}
    </>
  )

  function updateCoords() {
    if (ref.current) {
      const rect = ref.current.firstChild.getBoundingClientRect()
      // console.log(rect)
      setCoords({
        left: rect.x + window.scrollX + rect.width / 2, // add half the width of the target for centering
        top: rect.y + window.scrollY // add scrollY offset, as soon as getBountingClientRect takes on screen coords
      })
    }
  }

  function onEnter() {
    updateCoords()
    timer_id = window.setTimeout(() => setOpen(true), 500)
  }

  function onLeave() {
    window.clearTimeout(timer_id)
    setOpen(false)
  }

  function Popover({ children }) {
    const onResize = debounce(updateCoords, 100)

    useEffect(() => {
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }, [])

    // console.log(coords)
    return <Tip coords={coords}>{children}</Tip>
  }
}
