// @ts-check

import React, { useState } from 'react'
import { Dialog } from '@blueprintjs/core'
import generate from 'nanoid/generate'
import { useFirebase } from '../Firebase'
import { AttributeType, AttributeStruct } from '../types'
import { DialogBody, DialogFooter, IsAs } from '../Parts'
import { PickHue, Name, Desc, CancelOrCreate, FormError } from '../UI'
import { AttributesUI } from '.'

//--------------------------------------------------------------------------------------------------

/**
 * @returns {import("../types").Attribute[]}
 */
function name_attribute() {
  return [
    {
      id: generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4),
      name: 'Name',
      type: AttributeType.String,
      struct: AttributeStruct.One,
      is_name: true,
      is_required: true
    }
  ]
}

/**
 * A dialog for creating an entity
 * @param {object} props
 * @param {import("../types").Project} props.project
 * @param {boolean} props.open
 * @param {(open:boolean)=>void} props.setOpen
 */

export function NewEntityDialog({ project, setOpen }) {
  const firebase = useFirebase()

  const [error, setError] = useState(null)
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [hue, setHue] = useState(null)
  const [attrs, setAttrs] = useState(name_attribute())
  const [isa_ids, setIsaIds] = useState([])

  const isInvalid =
    !project || name.length == 0 || attrs.length == 0 || attrs.filter(a => a.is_name).length < 1

  return (
    <Dialog
      title="Create New Type of Entity"
      icon="add"
      isOpen={true}
      onClose={close}
      canEscapeKeyClose={false}
    >
      <DialogBody>
        <Name value={name} setValue={setName} />
        <Desc value={desc} setValue={setDesc} />
        <PickHue value={hue} setValue={setHue} />
        <IsAs value={isa_ids} setValue={setIsaIds} entities={project.entities} />
        <AttributesUI value={attrs} setValue={setAttrs} showIsName={true} />
      </DialogBody>

      <DialogFooter>
        <CancelOrCreate isInvalid={isInvalid} onCancel={close} onCreate={create} />
        <FormError error={error} />
      </DialogFooter>
    </Dialog>
  )

  function close() {
    setOpen(false)
    setName('')
    setAttrs(name_attribute())
  }

  function create() {
    const entities = project.entities || []
    const id = generate('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 4)
    firebase
      .project(project.id)
      .update({ entities: [...entities, { id, name, desc, hue, attrs }] }) //, isa_ids }] })
      .then(close)
      .catch(setError)
  }
}
