import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { Navigation } from './Navigation'
// import { LandingPage } from './Landing'
import { SignUpPage, SignInPage, PasswordForgetPage, HomePage, AccountPage } from '../Users'
import { AdminPage } from './AdminPage'
import { ProjectPage } from '../Projects'

import * as ROUTES from '../../constants/routes'
import { withAuthentication } from '../Users/Session'

export default withAuthentication(() => (
  <Router>
    <>
      <Navigation />
      <div style={{ margin: '1em' }}>
        {/* <Route exact path={ROUTES.LANDING} component={LandingPage} /> */}
        <Route exact path={ROUTES.HOME} component={HomePage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
        <Route path={ROUTES.ACCOUNT} component={AccountPage} />
        <Route path={ROUTES.ADMIN} component={AdminPage} />
        <Route path={ROUTES.PROJECT_INFO} component={ProjectPage} />
      </div>
    </>
  </Router>
))
