import React from 'react'
import { Col, Row } from '../UI'

import { PasswordForgetForm, PasswordChangeForm } from '.'
import { useAuthUser, withAuthorization } from './Session'

export const AccountPage = withAuthorization(user => !!user)(() => {
  const user = useAuthUser()

  return (
    <Col width="620px" centered>
      <h1>
        <span style={{ fontWeight: 'normal' }}>Account:</span> {user.username}
      </h1>

      <Row>
        <Col width="300px" padding="0 10px 0 0">
          <h2>Forgot Password</h2>
          <PasswordForgetForm email={user.email} />
        </Col>

        <Col width="300px" padding="0 0 0 10px">
          <h2>Change Password</h2>
          <PasswordChangeForm />
        </Col>
      </Row>
    </Col>
  )
})
